  <hr class="featurette-divider">

  <div class="container toptitle">
  <div class="row featurette">
  <div class="col-12">
  <div id="myCarousel" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#myCarousel" data-slide-to="1"></li>
      <li data-target="#myCarousel" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner" role="listbox">
      <div class="carousel-item active">
        <img class="third-slide" src="assets/bloom-blossom-close-up.jpg" alt="モナコインの(イラスト)クラウドファンディングへようこそ" width="1600" height="400">
        <div class="container">
          <div class="carousel-caption d-none d-block text-right">
            <h1 translate>モナファンディングへようこそ</h1>
            <p translate>当サイトはモナコインによるイラストファンディングサイトです。イラストにみんなで支援しましょう</p>
            <h6 translate>お試しでイラストや写真を投稿してもらえるとうれしいです、私からもモナ投げます</h6>            
            <h6 translate>※サイトの試験も兼ねていますので、明確に悪意がないもの以外はOKです</h6>            
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img class="third-slide" src="assets/burning-dark-fire.jpg" alt="モナコインの(イラスト)クラウドファンディング、段階的ファンディングの解説" width="1600" height="400">
        <div class="container">
          <div class="carousel-caption d-none d-block text-right">
            <h3 translate>曇ってしまっている画像は、たくさんの支援で吹き飛ばしてしまおう</h3>
            <p translate>当サイトの特徴として、段階的ファンディング方式があります、支援が集まるほど画像が鮮明になっていきます</p>
            <p><a translate class="btn btn-lg btn-primary" [routerLink]="['/monafunding/help']" role="button">もっと知りたい</a></p>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img class="third-slide" src="assets/black-coffee-blue-brewed.jpg" alt="モナコインの(イラスト)クラウドファンディング、モナ富豪の優遇について" width="1600" height="400">
        <div class="container">
          <div class="carousel-caption d-none d-block text-right">
            <h1 translate>たくさんモナを持っていると優遇される場合があります</h1>
            <p translate>例えばモナ富豪だけに公開されているコンテンツがあるかもしれません.</p>
          </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
  </div>
  </div>
  </div>
  <hr class="featurette-divider">

  <!-- Marketing messaging and featurettes
  ================================================== -->
  <!-- Wrap the rest of the page in another container to center all the content. -->


  <div class="container marketing">

    <!-- Three columns of text below the carousel -->
    <div class="row">
      <div class="col-lg-4">    
        <img class="rounded-circle" src="assets/close-up-dark-equipment.jpg" alt="モナコインの(イラスト)クラウドファンディング、登録方法" width="140" height="140">
        <h2>Login / Profile</h2>
        <p translate>ここからGoogleアカウントと連携したログインと、ユーザー情報の追加/更新ができます.</p>
        <p><a class="btn btn-secondary" href="/monafunding/authenticate" role="button">GO &raquo;</a></p>
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">
        <img class="rounded-circle" src="assets/albums-antique-audio.jpg" alt="モナコインの(イラスト)クラウドファンディング、イラストの投稿" width="140" height="140">
        <h2>Your Album</h2>
        <p translate>ログイン後、こちらからアートワーク（画像）の登録（アップロード）ができます.</p>
        <p><a class="btn btn-secondary" href="/monafunding/register-album" role="button">GO &raquo;</a></p>
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">
        <img class="rounded-circle" src="assets/ask-blackboard.jpg" alt="モナコインの(イラスト)クラウドファンディング、使い方について" width="140" height="140">
        <h2>Help / Rules</h2>
        <p translate>使い方の説明があります.</p>
        <p><a class="btn btn-secondary" [routerLink]="['/monafunding/help']" role="button">GO &raquo;</a></p>
      </div><!-- /.col-lg-4 -->
    </div><!-- /.row -->

  <!-- Marketing messaging and featurettes
  ================================================== -->
  <!-- Wrap the rest of the page in another container to center all the content. -->
    <!-- START THE FEATURETTES -->
    <hr class="featurette-divider">
    <hr class="featurette-divider">
    <h5 translate>タイムライン</h5>
    <hr class="featurette-divider">
    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-12">

        <div id="carouselExampleIndicatorsMiniTimeline" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <ng-container *ngFor="let album_page of timeline_album_pages; let i = index">       
            <ng-container *ngIf="0 == i">
            <div class="carousel-item active">
              <div class="container">
                <div class="row featurette">
                  <ng-container *ngFor="let album of album_page.albums">
                      <div class="col-4 d-none d-md-block">
                        <div class="container">
                          <img class="d-block w-100" (click)="onClickTimeline(album)" src="https://back.monafunding.com/imaged?filename={{album.filename}}&uu_session_id={{uu_session_id}}" width=320 height=180 alt="モナコインの(イラスト)クラウドファンディング タイムライン（アート、イラスト、キャラクター)">
                          <div class="container">
                            <div class="carousel-caption d-none d-block text-right">
                              <p><a class="btn btn-sm btn-primary" href="/monafunding/donation/{{album.filename}}" role="button">Donate!</a></p>
                            </div>
                          </div>        
                        </div>        
                      </div>
                      <div class="col-12 d-block d-md-none">
                        <div class="container">
                          <img class="d-block w-100" (click)="onClickTimeline(album)" src="https://back.monafunding.com/imaged?filename={{album.filename}}&uu_session_id={{uu_session_id}}" width=320 height=180 alt="モナコインの(イラスト)クラウドファンディング タイムライン（アート、イラスト、キャラクター)">
                          <div class="container">
                            <div class="carousel-caption d-none d-block text-right">
                              <p><a class="btn btn-sm btn-primary" href="/monafunding/donation/{{album.filename}}" role="button">Donate!</a></p>
                            </div>
                          </div>        
                        </div>        
                      </div>
                  </ng-container>
                </div>
              </div>
            </div>
            </ng-container>
            <ng-container *ngIf="0 != i">                    
                <div class="carousel-item">
              <div class="container">
                <div class="row featurette">
                  <ng-container *ngFor="let album of album_page.albums">       
                      <div class="col-4 d-none d-md-block ">
                        <div class="container">
                          <img class="d-block w-100" (click)="onClick(album)" src="https://back.monafunding.com/imaged?filename={{album.filename}}&uu_session_id={{uu_session_id}}" width=320 height=180 alt="モナコインの(イラスト)クラウドファンディング 投稿作品（アート、イラスト、キャラクター)">
                          <div class="container">
                            <div class="carousel-caption d-none d-block text-right">
                              <p><a class="btn btn-lg btn-primary" href="/monafunding/donation/{{album.filename}}" role="button">Donate!</a></p>
                            </div>
                          </div>        
                        </div>        
                      </div>
                      <div class="col-12 d-block d-md-none">
                        <div class="container">
                          <img class="d-block w-100" (click)="onClick(album)" src="https://back.monafunding.com/imaged?filename={{album.filename}}&uu_session_id={{uu_session_id}}" width=320 height=180 alt="モナコインの(イラスト)クラウドファンディング 投稿作品（アート、イラスト、キャラクター)">
                          <div class="container">
                            <div class="carousel-caption d-none d-block text-right">
                              <p><a class="btn btn-lg btn-primary" href="/monafunding/donation/{{album.filename}}" role="button">Donate!</a></p>
                            </div>
                          </div>        
                        </div>        
                      </div>
                  </ng-container>
                </div>
              </div>
            </div>
            </ng-container>
            </ng-container>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleIndicatorsMiniTimeline" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleIndicatorsMiniTimeline" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
          </a>
        </div>     
      </div>     
     
    </div>
  
    <hr class="featurette-divider">
    <hr class="featurette-divider">
    <h5 translate>アートギャラリー</h5>
    <hr class="featurette-divider">
    <hr class="featurette-divider">


    <ng-container *ngFor="let portfolio of portfolios">
    <div class="row featurette">
      <div class="col-3 d-none d-md-block">
        <div class="card mb-4 box-shadow">
          <img class="card-img-top" src="https://back.monafunding.com/imaged?filename=USER{{portfolio.user.id}}&uu_session_id={{uu_session_id}}" alt="モナコインの(イラスト)クラウドファンディング カード">
          <div class="card-body">
            <h5 class="card-title">{{portfolio.user.name}}</h5>
            <ng-container *ngIf="select_lang == 'ja'">
            <p class="card-text">{{portfolio.user.profile_message}}.</p>
            </ng-container>
            <ng-container *ngIf="select_lang == 'en'">
            <p class="card-text">{{portfolio.user.profile_message_en}}.</p>
            </ng-container>
            <div class="d-flex justify-content-between align-items-center">
              <!-- <div class="btn-group"> -->
              <!--   <button type="button" class="btn btn-sm btn-outline-secondary">Jump</button> -->
              <!-- </div> -->
              <small class="text-muted">9 mins</small>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-block d-md-none">
        <div class="card mb-4 box-shadow">
          <img class="card-img-top" src="https://back.monafunding.com/imaged?filename=USER{{portfolio.user.id}}&uu_session_id={{uu_session_id}}" alt="モナコインの(イラスト)クラウドファンディング カード">
          <div class="card-body">
            <h5 class="card-title">{{portfolio.user.name}}</h5>
            <ng-container *ngIf="select_lang == 'ja'">
            <p class="card-text">{{portfolio.user.profile_message}}.</p>
            </ng-container>
            <ng-container *ngIf="select_lang == 'en'">
            <p class="card-text">{{portfolio.user.profile_message_en}}.</p>
            </ng-container>
            <div class="d-flex justify-content-between align-items-center">
              <!-- <div class="btn-group"> -->
              <!--   <button type="button" class="btn btn-sm btn-outline-secondary">Jump</button> -->
              <!-- </div> -->
              <small class="text-muted">9 mins</small>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 d-none d-md-block">
        <div id="carouselExampleIndicators{{portfolio.user.id}}" class="carousel slide carousel-thumbnails" data-ride="carousel">
          <ol class="carousel-indicators">
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="d-block w-100" src="assets/book-book-bindings-books.jpg" width=640 height=360 alt="モナコインの(イラスト)クラウドファンディング 表紙ページの画像">
            </div>
            <ng-container *ngFor="let album of portfolio.albums">
            <div class="carousel-item">
              <img class="mx-auto d-block" (click)="onClick(album)" src="https://back.monafunding.com/imaged?filename={{album.filename}}&uu_session_id={{uu_session_id}}" width=640 height=360 alt="First slide">
              <!-- 
              <img (click)="onClick(album)" src="https://back.monafunding.com/imaged?filename={{album.filename}}&uu_session_id={{uu_session_id}}" width=640 height=360 alt="First slide">
              -->
              <div class="container">
                 <div class="carousel-caption d-none d-block text-right">
                  <p><a class="btn btn-lg btn-primary" href="/monafunding/donation/{{album.filename}}" role="button">Donate!</a></p>
                </div>
              </div>        
            </div>
            </ng-container>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleIndicators{{portfolio.user.id}}" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleIndicators{{portfolio.user.id}}" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
      <div class="col-12 d-block d-md-none">
        <div id="carouselExampleIndicators{{portfolio.user.id}}" class="carousel slide carousel-thumbnails" data-ride="carousel">
          <ol class="carousel-indicators">
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="d-block w-100" src="assets/book-book-bindings-books.jpg" width=640 height=360 alt="モナコインの(イラスト)クラウドファンディング 最初のギャラリー">
            </div>
            <ng-container *ngFor="let album of portfolio.albums">
            <div class="carousel-item">
              <img class="mx-auto d-block p-auto" (click)="onClick(album)" src="https://back.monafunding.com/imaged?filename={{album.filename}}&uu_session_id={{uu_session_id}}" width=640 height=360 alt="First slide">
              <div class="container">
                 <div class="carousel-caption d-none d-block text-right">
                  <p><a class="btn btn-lg btn-primary" href="/monafunding/donation/{{album.filename}}" role="button">Donate!</a></p>
                </div>
              </div>        
            </div>
            </ng-container>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleIndicators{{portfolio.user.id}}" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleIndicators{{portfolio.user.id}}" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>



    <div class="row featurette">
      <div class="col-12">

        <div id="carouselExampleIndicatorsMini{{portfolio.user.id}}" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <ng-container *ngFor="let album_page of portfolio.album_pages; let i = index">       
            <ng-container *ngIf="0 == i">                    
            <div class="carousel-item active">
              <div class="container">
                <div class="row featurette">
                  <ng-container *ngFor="let album of album_page.albums">       
                  <div class="col-2"><img (click)="onClick(album)" src="https://back.monafunding.com/imaged?filename={{album.filename}}-thumbnail&uu_session_id={{uu_session_id}}" alt="モナコインの(イラスト)クラウドファンディング ギャラリー"></div>
                  </ng-container>
                </div>
              </div>
            </div>
            </ng-container>
            <ng-container *ngIf="0 != i">                    
                <div class="carousel-item">
              <div class="container">
                <div class="row featurette">
                  <ng-container *ngFor="let album of album_page.albums">       
                  <div class="col-2"><img (click)="onClick(album)" src="https://back.monafunding.com/imaged?filename={{album.filename}}-thumbnail&uu_session_id={{uu_session_id}}" alt="モナコインの(イラスト)クラウドファンディング サムネイル"></div>
                  </ng-container>
                </div>
              </div>
            </div>
            </ng-container>
            </ng-container>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleIndicatorsMini{{portfolio.user.id}}" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleIndicatorsMini{{portfolio.user.id}}" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
          </a>
        </div>     
      </div>     
    </div>
    <hr class="featurette-divider">
    </ng-container>

    <hr class="featurette-divider">
    <h5 translate>みなさま</h5>
    <hr class="featurette-divider">
    <hr class="featurette-divider">

    <div class="row featurette">
      <ng-container *ngFor="let user of users">
        <ng-container *ngIf="user.name != 'Blank'">
          <div class="col-2 d-none d-md-block">
            <div class="card mb-2 box-shadow">
              <img class="card-img-top" src="https://back.monafunding.com/imaged?filename=USER{{user.id}}&uu_session_id={{uu_session_id}}" alt="モナコインの(イラスト)クラウドファンディング カード">
              <div class="card-body">
                <h6 class="card-title">{{user.name}}</h6>
                <!-- 
                <ng-container *ngIf="select_lang == 'ja'">
                <p class="card-text">{{portfolio.user.profile_message}}.</p>
                </ng-container>
                <ng-container *ngIf="select_lang == 'en'">
                <p class="card-text">{{portfolio.user.profile_message_en}}.</p>
                </ng-container>
                <div class="d-flex justify-content-between align-items-center">
                  <small class="text-muted">9 mins</small>
                </div>
                -->
              </div>
            </div>
          </div>
          <div class="col-6 d-block d-md-none">
            <div class="card mb-6 box-shadow">
              <img class="card-img-top" src="https://back.monafunding.com/imaged?filename=USER{{user.id}}&uu_session_id={{uu_session_id}}" alt="モナコインの(イラスト)クラウドファンディング カード">
              <div class="card-body">
                <h6 class="card-title">{{user.name}}</h6>
                <!-- 
                <ng-container *ngIf="select_lang == 'ja'">
                <p class="card-text">{{portfolio.user.profile_message}}.</p>
                </ng-container>
                <ng-container *ngIf="select_lang == 'en'">
                <p class="card-text">{{portfolio.user.profile_message_en}}.</p>
                </ng-container>
                <div class="d-flex justify-content-between align-items-center">
                  <small class="text-muted">9 mins</small>
                </div>
                -->
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <!-- /END THE FEATURETTES -->

    <hr class="featurette-divider">
    <hr class="featurette-divider">
    <h5 translate>フィルタ</h5>
    <hr class="featurette-divider">
    <hr class="featurette-divider">

    <div class="row featurette">
    <div class="form-group" name="formgroup"> <!-- Album Name -->
        <label translate class="btn btn-info">
          <input translate type="checkbox" id='rating_art' name="rating_art" [(ngModel)]="rating_art" autocomplete="off"> 一般（アート）
        </label>
        <label translate class="btn btn-info">
            <input translate type="checkbox" id='rating_moe' name="rating_moe" [(ngModel)]="rating_moe" autocomplete="off"> 萌え表現あり
        </label>  
        <label translate class="btn btn-info">
          <input translate type="checkbox" id='rating_moe_ero' name="rating_moe_ero" [(ngModel)]="rating_moe_ero" autocomplete="off"> 裸体表現あり
        </label>
        <label translate class="btn btn-info">
          <input translate type="checkbox" id='rating_moe_r18' name="rating_moe_r18" [(ngModel)]="rating_moe_r18" autocomplete="off"> R-18
        </label>
        <label translate class="btn btn-info">
          <input translate type="checkbox" [(ngModel)]="rating_pic" autocomplete="off"> 写真(萌え表現あり)
        </label>
        <label translate class="btn btn-info">
          <input translate type="checkbox" [(ngModel)]="rating_pic_ero" autocomplete="off"> 写真(裸体表現あり)
        </label>
        <label translate class="btn btn-info">
          <input translate type="checkbox" [(ngModel)]="rating_pic_r18" autocomplete="off"> 写真(R-18)
        </label>
    </div>
    </div>
    <div class="row featurette">
        <button (click)="ngOnInit()" type="button" class = "btn-primary">Update!!</button>
    </div>  

    <hr class="featurette-divider">
    <hr class="featurette-divider">

    <!-- FOOTER -->
    <footer>
      <p><a translate href="https://twitter.com/RCloverforest">ご意見などはこちらまで(Twitter)</a></p>
      <p><a translate href="https://github.com/rurieru/monafunding">バグレポートはこちらまで(GitHub)</a></p>
      <p><a [routerLink]="['/']" role="button">&raquo; ENTRANCE</a></p>
    </footer>

  </div><!-- /.container -->


  <!-- Bootstrap core JavaScript
  ================================================== -->
  <!-- Placed at the end of the document so the pages load faster -->
  <script src="https://code.jquery.com/jquery-3.1.1.slim.min.js" integrity="sha384-A7FZj7v+d/sdmMqp/nOQwliLvUsJfDHW+k9Omg/a/EheAdgtzNs3hpfag6Ed950n" crossorigin="anonymous"></script>
  <script>window.jQuery || document.write('<script src="../../assets/js/vendor/jquery.min.js"><\/script>')</script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/tether/1.4.0/js/tether.min.js" integrity="sha384-DztdAPBWPRXSA/3eYEEUWrWCy7G5KFbe8fFjk5JAIxUYHKkDx6Qin1DkWx51bBrb" crossorigin="anonymous"></script>
  <script src="../../dist/js/bootstrap.min.js"></script>
  <!-- Just to make our placeholder images work. Don't actually copy the next line! -->
  <script src="../../assets/js/vendor/holder.min.js"></script>
  <!-- IE10 viewport hack for Surface/desktop Windows 8 bug -->
  <script src="../../assets/js/ie10-viewport-bug-workaround.js"></script>