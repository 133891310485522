
<hr class="featurette-divider">

<hr class="featurette-divider">
<h5 translate>ランキング</h5>
<hr class="featurette-divider">

<ng-container *ngFor="let asset_info of ranking_asset_info; let i = index"> 
<div class="row featurette">
<div class="col-1">
</div>

<div class="card border-dark col-10">
  <div class="card-header">第 {{i+1}} 位</div>
  <div class="card-body text-dark">
    <h4 class="card-title">
    {{asset_info.nick_name}}さん 
    </h4>
    <!--
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    -->
    <table class="table table-bordered">
        <thead>
            <tr>
              <th scope="col">Address</th>
              <th scope="col">評価額</th>
              <th scope="col">採掘量</th>
              <th scope="col">採掘カード</th>
              <th scope="col">NARFカード</th>
            </tr>
          </thead>
          <tbody>
          <tr>
          <!--
          <th scope="row">1</th>
          -->
            <td>{{asset_info.coin_address}}</td>
            <td>{{asset_info.asset_total_value}}</td>
            <td>{{asset_info.asset_total}}</td>
            <td>
              {{asset_info.card_info_1}}<br>
              {{asset_info.card_info_2}}<br>
              {{asset_info.card_info_3}}<br>
              {{asset_info.card_info_4}}<br>
              {{asset_info.card_info_5}}<br>
              {{asset_info.card_info_6}}
            </td>
            <td>
              {{asset_info.nurf_card_1}}<br>
              {{asset_info.nurf_card_2}}<br>
              {{asset_info.nurf_card_3}}              
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</div>
</div>
<hr class="featurette-divider">
</ng-container>
