import { Component, OnInit } from '@angular/core';

import { Title }     from '@angular/platform-browser'; 

@Component({
  selector: 'app-monafunding-help-electrum',
  templateUrl: './monafunding-help-electrum.component.html',
  styleUrls: ['./monafunding-help-electrum.component.css'],
  providers: [ Title ]  // ［1］Titleサービスを登録 
})
export class MonafundingHelpElectrumComponent implements OnInit {

  constructor(private title: Title) {
    this.title.setTitle('モナファンディング モナコインで(イラスト)クラウドファンディング Electrum Monaのインストール/使い方 '); 
  }

  ngOnInit() {
  }

}
