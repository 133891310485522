<div class="container toptitl">
    <hr class="featurette-divider">
    <hr class="featurette-divider">
    <h3 translate>モナコインのチップサービスを使いやすく</h3>
    <h5 translate>※ボタンを押すとtwitterが開きます</h5>
    <h5 translate>※取引はtwitter上で行われるため安心です</h5>
    <h5 translate>※<a href="https://twitter.com/tipmona">@tipmona</a>はリプライで、<a href="https://twitter.com/monatipbot">@monatipbot(ももな)</a>はダイレクトメッセージで処理されます</h5>
    <hr class="featurette-divider">
    <hr class="featurette-divider">
    
    <div class="row">
        <div class="col-8">    
          <h3 translate>残高照会</h3>
          <ng-container *ngIf="os_kind != 'Android'">
          <a translate href="https://twitter.com/intent/tweet?screen_name=tipmona&ref_src=twsrc%5Etfw&text=balance" class="btn btn-md btn-primary" data-related="" data-show-count="false">@tipmonaの残高照会</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          <a translate href="https://twitter.com/messages/compose?recipient_id=949241552437616640&ref_src=twsrc%5Etfw&text=balance" class="btn btn-md btn-primary" data-screen-name="@monatipbot" data-show-count="false">@monatipbotの残高照会</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          </ng-container>
          <ng-container *ngIf="os_kind == 'Android'">
          <a translate href="https://twitter.com/intent/tweet?ref_src=twsrc%5Etfw&text=%40tipmona%20balance" class="btn btn-md btn-primary" data-related="" data-show-count="false">@tipmonaの残高照会</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          <a translate href="https://twitter.com/intent/tweet?ref_src=twsrc%5Etfw&text=%40monatipbot%20balance" class="btn btn-md btn-primary" data-screen-name="@monatipbot" data-show-count="false">@monatipbotの残高照会</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          <h5 translate>※Androidではアプリで開くため@monatipbotでもDMでなくリプライになります</h5>
          </ng-container>
        </div><!-- /.col-4 -->
    </div>

    <hr class="featurette-divider">
    <div class="row">
      <div class="col-8">    
        <h3 translate>入金</h3>
        <ng-container *ngIf="os_kind != 'Android'">
        <a translate href="https://twitter.com/intent/tweet?screen_name=tipmona&ref_src=twsrc%5Etfw&text=deposit" class="btn btn-md btn-primary" data-related="" data-show-count="false">@tipmonaの入金</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        <a translate href="https://twitter.com/messages/compose?recipient_id=949241552437616640&ref_src=twsrc%5Etfw&text=deposit" class="btn btn-md btn-primary" data-screen-name="@monatipbot" data-show-count="false">@monatipbotの入金</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        <h5 translate>※入金に関しては、入金アドレスが表示されますので、外部ウォレットから送金します</h5>
        </ng-container>
        <ng-container *ngIf="os_kind == 'Android'">
          <a translate href="https://twitter.com/intent/tweet?ref_src=twsrc%5Etfw&text=%40tipmona%20deposit" class="btn btn-md btn-primary" data-related="" data-show-count="false">@tipmonaの残高照会</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          <a translate href="https://twitter.com/intent/tweet?ref_src=twsrc%5Etfw&text=%40monatipbot%20deposit" class="btn btn-md btn-primary" data-screen-name="@monatipbot" data-show-count="false">@monatipbotの残高照会</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          <h5 translate>※入金に関しては、入金アドレスが表示されますので、外部ウォレットから送金します</h5>
          <h5 translate>※Androidではアプリで開くため@monatipbotでもDMでなくリプライになります</h5>
        </ng-container>
      </div><!-- /.col-4 -->
    </div>

    <hr class="featurette-divider">
    <div class="row">
      <div class="col-md-12 col-lg-8">    
        <h3 translate>送金</h3>
        <h4 translate>twitterアカウントへ</h4>
        <div class="form-group"> <!-- Monacoin for Supporter -->
          <form #account_form_="ngForm" class="account_form">
          <div class="input-group-prepend">
          <span translate class="input-group-text" id="basic-textarea">twitterアカウント名</span>
          <input required maxlength="34" minlength="34" type="text" class="form-control" id="city_id" name="account_for_withdraw" [(ngModel)]="account_for_withdraw" #account_for_withdraw_="ngModel" placeholder="@RCloverforest">
          </div>
          <span translate *ngIf="account_for_withdraw_.errors?.required" style="color:red;">アカウント名は必須です</span>

          <div class="input-group-prepend">
          <span translate class="input-group-text" id="basic-textarea">送金額</span>
          <input required maxlength="8" type="text" class="form-control" id="city_id" name="mona_for_withdraw" [(ngModel)]="mona_for_withdraw" #mona_for_withdraw_="ngModel" placeholder="10">
          </div>
          <div class="btn-group">
              <button (click)="monaCaliculate(0.1)" type="button" class="btn btn-sm btn-outline-secondary">+0.1</button>
              <button (click)="monaCaliculate(-0.1)" type="button" class="btn btn-sm btn-outline-secondary">-0.1</button>
              <button (click)="monaCaliculate(1)" type="button" class="btn btn-sm btn-outline-secondary">+1</button>
              <button (click)="monaCaliculate(-1)" type="button" class="btn btn-sm btn-outline-secondary">-1</button>
              <button (click)="monaCaliculate(+10)" type="button" class="btn btn-sm btn-outline-secondary">+10</button>
              <button (click)="monaCaliculate(-10)" type="button" class="btn btn-sm btn-outline-secondary">-10</button>
              <button (click)="monaRest(0.39)" type="button" class="btn btn-sm btn-outline-secondary">0.39</button>
              <button (click)="monaRest(3.9)" type="button" class="btn btn-sm btn-outline-secondary">3.9</button>
              <button (click)="monaRest(39)" type="button" class="btn btn-sm btn-outline-secondary">39</button>
              <button (click)="monaRest(1.14)" type="button" class="btn btn-sm btn-outline-secondary">1.14</button>
              <button (click)="monaRest(11.4)" type="button" class="btn btn-sm btn-outline-secondary">11.4</button>
              <button (click)="monaRest(114)" type="button" class="btn btn-sm btn-outline-secondary">114</button>
          </div>
          </form>
        </div>
        <span translate *ngIf="mona_for_withdraw_.errors?.required" style="color:red;">送金額は必須です</span>
        <ng-container *ngIf="os_kind != 'Android'">
        <a translate href="https://twitter.com/intent/tweet?screen_name=tipmona&ref_src=twsrc%5Etfw&text=tip {{account_for_withdraw}} {{mona_for_withdraw}}" class="btn btn-md btn-primary" data-related="" data-show-count="false">@tipmonaの送金(twitterアカウントへ)</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        <a translate href="https://twitter.com/messages/compose?recipient_id=949241552437616640&ref_src=twsrc%5Etfw&text=tip {{account_for_withdraw}} {{mona_for_withdraw}}" class="btn btn-md btn-primary" data-screen-name="@monatipbot" data-show-count="false">@monatipbotの送金(twitterアカウントへ)</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        </ng-container>
        <ng-container *ngIf="os_kind == 'Android'">
        <a translate href="https://twitter.com/intent/tweet?ref_src=twsrc%5Etfw&text=%40tipmona%20tip {{account_for_withdraw}} {{mona_for_withdraw}}" class="btn btn-md btn-primary" data-related="" data-show-count="false">@tipmonaの送金(twitterアカウントへ)</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        <a translate href="https://twitter.com/intent/tweet?ref_src=twsrc%5Etfw&text=%40monatipbot%20tip {{account_for_withdraw}} {{mona_for_withdraw}}" class="btn btn-md btn-primary" data-screen-name="@monatipbot" data-show-count="false">@monatipbotの送金(twitterアカウントへ)</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        <h5 translate>※Androidではアプリで開くため@monatipbotでもDMでなくリプライになります</h5>
        </ng-container>
      </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-8">    
          <h4 translate>モナコインアドレスへ</h4>
          <div class="form-group"> <!-- Monacoin Address -->
            <div class="input-group-prepend">
            <span translate class="input-group-text" id="basic-textarea">モナコインアドレス</span>
            <input required maxlength="34" minlength="34" type="text" class="form-control" id="city_id" name="mona_adder_for_withdraw" [(ngModel)]="mona_adder_for_withdraw" #mona_adder_for_withdraw_="ngModel" placeholder="Monacoin Address">
            </div>
            <span translate *ngIf="mona_adder_for_withdraw_.errors?.required" style="color:red;">モナコインアドレスは必須です</span>
            <span translate *ngIf="mona_adder_for_withdraw_.errors?.minlength" style="color:red;">モナコインアドレスは34文字です</span>
          </div>
          <div class="form-group"> <!-- Monacoin for Supporter -->
            <div class="input-group-prepend">
            <span translate class="input-group-text" id="basic-textarea">送金額</span>
            <input required maxlength="8" type="text" class="form-control" id="city_id" name="mona_for_withdraw" [(ngModel)]="mona_for_withdraw" placeholder="10">
            </div>
            <div class="btn-group">
                <button (click)="monaCaliculate(0.1)" type="button" class="btn btn-sm btn-outline-secondary">+0.1</button>
                <button (click)="monaCaliculate(-0.1)" type="button" class="btn btn-sm btn-outline-secondary">-0.1</button>
                <button (click)="monaCaliculate(1)" type="button" class="btn btn-sm btn-outline-secondary">+1</button>
                <button (click)="monaCaliculate(-1)" type="button" class="btn btn-sm btn-outline-secondary">-1</button>
                <button (click)="monaCaliculate(+10)" type="button" class="btn btn-sm btn-outline-secondary">+10</button>
                <button (click)="monaCaliculate(-10)" type="button" class="btn btn-sm btn-outline-secondary">-10</button>
                <button (click)="monaRest(0.39)" type="button" class="btn btn-sm btn-outline-secondary">0.39</button>
                <button (click)="monaRest(3.9)" type="button" class="btn btn-sm btn-outline-secondary">3.9</button>
                <button (click)="monaRest(39)" type="button" class="btn btn-sm btn-outline-secondary">39</button>
                <button (click)="monaRest(1.14)" type="button" class="btn btn-sm btn-outline-secondary">1.14</button>
                <button (click)="monaRest(11.4)" type="button" class="btn btn-sm btn-outline-secondary">11.4</button>
                <button (click)="monaRest(114)" type="button" class="btn btn-sm btn-outline-secondary">114</button>
            </div>
          </div>
          <span translate *ngIf="mona_for_withdraw_.errors?.required" style="color:red;">送金額は必須です</span>
          <ng-container *ngIf="os_kind != 'Android'">
          <a translate href="https://twitter.com/intent/tweet?screen_name=tipmona&ref_src=twsrc%5Etfw&text=withdraw {{mona_for_withdraw}} {{mona_adder_for_withdraw}}" class="btn btn-md btn-primary" data-related="" data-show-count="false">@tipmonaで送金(モナコインアドレスへ)</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          <a translate href="https://twitter.com/messages/compose?recipient_id=949241552437616640&ref_src=twsrc%5Etfw&text=withdraw {{mona_adder_for_withdraw}} {{mona_for_withdraw}}" class="btn btn-md btn-primary" data-screen-name="@monatipbot" data-show-count="false">@monatipbotで送金(モナコインアドレスへ)</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          </ng-container>
          <ng-container *ngIf="os_kind == 'Android'">
          <a translate href="https://twitter.com/intent/tweet?ref_src=twsrc%5Etfw&text=%40tipmona%20withdraw {{mona_for_withdraw}} {{mona_adder_for_withdraw}}" class="btn btn-md btn-primary" data-related="" data-show-count="false">@tipmonaで送金(モナコインアドレスへ)</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          <a translate href="https://twitter.com/intent/tweet?ref_src=twsrc%5Etfw&text=%40monatipbot%20withdraw {{mona_adder_for_withdraw}} {{mona_for_withdraw}}" class="btn btn-md btn-primary" data-screen-name="@monatipbot" data-show-count="false">@monatipbotで送金(モナコインアドレスへ)</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          </ng-container>
        </div><!-- /.col-4 -->
    </div>

    <hr class="featurette-divider">
    <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">TipService</li>
    </ol>
    </nav>
    <footer>
            <p>&copy; 2018 Monacity Project.</p>
    </footer>
    </div>
    