<div class="container toptitl">

    <hr class="featurette-divider">
    <h3 translate>あなただけのキャンペーンを開始しましょう</h3>
    
    <hr class="featurette-divider">
        
    <div class="row">
    <div class="col-md-1">
    </div>
    <div class="col-md-10">
    <form name="album_form" #album_form_="ngForm">
        <h3 translate>お仕事 {{selected_task.id}}</h3>
        <div class="form-group" name="formgroup"> <!-- Album Name -->
        <div class="input-group-prepend">
          <span translate class="input-group-text" id="basic-textarea">キャンペーン名称</span>
          <input required minlength="4" maxlength="30" type="text" class="form-control" id="full_name_id" name="selected_task.subject" [(ngModel)]="selected_task.subject" #album_name_="ngModel" placeholder="Your Art Work Name">
        </div>
        <span translate *ngIf="album_name_.errors?.required" style="color:red;">名前は必須です</span>
        <span translate *ngIf="album_name_.errors?.minlength" style="color:red;">名前は4文字以上で入力してください</span>
        <span translate *ngIf="album_name_.errors?.maxlength" style="color:red;">名前は30文字以内で入力してください</span>
        </div>
        <h3 translate>作業状況</h3>                   
        <h4 translate>{{selected_task.state}}</h4>
        
        <h3 translate>作業内容</h3>                   
        <div class="form-group"> <!-- Comment 2 -->
          <label translate for="street2_id" class="control-label">ヘッドライン</label>
          <textarea class="form-control" maxlength="100" id="street2_id" rows="1" name="selected_task.headline" [(ngModel)]="selected_task.headline"></textarea>
        </div>	
        <div class="form-group"> <!-- Comment 2 -->
          <label translate for="street2_id" class="control-label">作業内容</label>
          <textarea class="form-control" maxlength="100" id="street2_id" rows="12" name="selected_task.content" [(ngModel)]="selected_task.content"></textarea>
        </div>	
        <div class="form-group"> <!-- Comment 2 -->
          <label translate for="street2_id" class="control-label">報酬 (MONA)</label>
          <textarea class="form-control" maxlength="100" id="street2_id" rows="1" name="selected_task.mona_amount" [(ngModel)]="selected_task.mona_amount"></textarea>
        </div>	
        <h3 translate>納品</h3>                   
        <div class="form-group"> <!-- Comment 2 -->
          <label translate for="street2_id" class="control-label">納品物一覧</label>
          <textarea class="form-control" maxlength="100" id="street2_id" rows="3" name="subject" [(ngModel)]="subject"></textarea>
        </div>
        <h3 translate>コマンド</h3>
        <div class="form-group"> <!-- Submit Button -->
          <button (click)="onEdit()" type="button" class = "btn-primary">更新</button>
          <ng-container *ngIf="selected_task.command_disposed_task == true">
          <button (click)="onExecuteCommand('DisposeTask')" type="button" class = "btn-primary">DisposeTask</button>
          </ng-container>
          <ng-container *ngIf="selected_task.command_open_as_asker == true">
          <button (click)="onExecuteCommand('OpenAsAsker')" type="button" class = "btn-primary">OpenAsAsker</button>
          </ng-container>
          <ng-container *ngIf="selected_task.command_open_as_worker == true">
          <button (click)="onExecuteCommand('OpenAsWorker')" type="button" class = "btn-primary">OpenAsWorker</button>
          </ng-container>
          <ng-container *ngIf="selected_task.command_paticipate_as_worker == true">
          <button (click)="onExecuteCommand('PaticipateAsWorker')" type="button" class = "btn-primary">PaticipateAsWorker</button>
          </ng-container>
          <ng-container *ngIf="selected_task.command_paticipate_as_asker == true">
          <button (click)="onExecuteCommand('PaticipateAsAsker')" type="button" class = "btn-primary">PaticipateAsAsker</button>
          </ng-container>
          <ng-container *ngIf="selected_task.command_admit_paticipants == true">
          <button (click)="onExecuteCommand('AdmitParticipants')" type="button" class = "btn-primary">AdmitParticipants</button>
          </ng-container>
          <ng-container *ngIf="selected_task.command_work_complete == true">
          <button (click)="onExecuteCommand('WorkComplete')" type="button" class = "btn-primary">WorkComplete</button>
          </ng-container>
          <ng-container *ngIf="selected_task.command_payment_complete == true">
          <button (click)="onExecuteCommand('PaymentComplete')" type="button" class = "btn-primary">PaymentComplete</button>
          </ng-container>
          <ng-container *ngIf="selected_task.command_verify_complete == true">
          <button (click)="onExecuteCommand('VerifyComplete')" type="button" class = "btn-primary">VerifyComplete</button>
          </ng-container>

          
        </div>
      </form>
      <hr class="featurette-divider">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/monafunding']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Register</li>
        </ol>
      </nav>     
      <footer>
          <p>&copy; 2018 Monacity Project.</p>
      </footer>
      </div>
      <div class="col-md-1">
      </div>
      </div>
      </div>
    