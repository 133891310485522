<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-127388885-1"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-127388885-1');
</script>



<div style="text-align:center">
  <h1>
    Welcome to Monacity !!
  </h1>
</div>
<div style="text-align:center">
  <h2> Yet Another MONA Coin Economy </h2>
</div>
<div class="row">
  <div class="col-md-7 col-4">
  </div>
  <div class="col-md-2 col-7">
      <a href="https://marshmallow-qa.com/rcloverforest?utm_medium=twitter&utm_source=promotion" class="btn btn-lg btn-primary" data-show-count="false">マシュマロ</a>
  </div>
</div>
<div class="row">
  <div class="col-md-7 col-4">
  </div>
  <div class="col-md-2 col-7">
      <a href="https://twitter.com/RCloverforest?ref_src=twsrc%5Etfw" class="btn btn-lg btn-primary" data-show-count="false">Follow @RCloverforest</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  </div>
</div>
<div class="row">
  <div class="col-md-7 col-4">
  </div>
  <div class="col-md-2 col-7">
  <select class="form-control" id="select_lang" name="select_lang" [(ngModel)]="select_lang" (change)="changeLang()">
  <ng-container *ngIf="select_lang == 'ja'">
  <option value="en">English</option>
  </ng-container>
  <ng-container *ngIf="select_lang == 'en'">
  <option selected value="en">English</option>
  </ng-container>
  <ng-container *ngIf="select_lang == 'ja'">
  <option selected value="ja">日本語</option>
  </ng-container>
  <ng-container *ngIf="select_lang == 'en'">
  <option value="ja">日本語</option>
  </ng-container>
  </select>
  </div>
</div>

<router-outlet></router-outlet>

<!-- Twitter universal website tag code -->
<script>
!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
// Insert Twitter Pixel ID and Standard Event data below
twq('init','o0dfd');
twq('track','PageView');
</script>
<!-- End Twitter universal website tag code -->


