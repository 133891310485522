<div class="modal-header">
  <h4 class="modal-title">Information</h4>
  <button type="button" class="close" aria-label="Close"
   (click)="activeModal.dismiss('Cross click')">
  </button>
</div>
<div class="modal-body">
  <p>{{message}}</p>
</div>
<div class="modal-footer">
  <button (click)="activeModal.dismiss('Close clicked')">
    Close Clicked
  </button>
</div>