import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { ActivatedRoute } from '@angular/router';
import { ParamMap } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core'

import {MonafundingAlbumInfo} from '../monafunding.service';

import { Router } from '@angular/router';
import { Title }     from '@angular/platform-browser'; 

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormMessageModalComponent } from '../form-message-modal/form-message-modal.component';

class Asker {
  id: string;
  name: string;
  uu_session_id: string;
  profile_message: string;
}

class Askers{
  askers : Array<Asker>;
}

class Worker {
  album_name: string;
  filename: string;
  id: string;
  src: string;
  thumb : string;
}

class Workers {
  workers : Array<Worker>;
}

class Task {
  _id : string;
  id : string;
  subject : string;
  headline : string;
  content : string;
  mona_amount : number;
  worker_state : string;
  asker_state : string;
  state : string;
  askers : Array<Asker>;
  workers : Array<Worker>;
  permitted_operations : Array<string>;

  command_disposed_task : boolean;
  command_open_as_asker : boolean;
  command_open_as_worker : boolean;
  command_paticipate_as_worker : boolean;
  command_paticipate_as_asker : boolean;
  command_admit_paticipants : boolean;
  command_work_complete : boolean;
  command_payment_complete : boolean;
  command_verify_complete : boolean;
}

@Component({
  selector: 'app-monafunding-ask-detail',
  templateUrl: './monafunding-ask-detail.component.html',
  styleUrls: ['./monafunding-ask-detail.component.css']
})
export class MonafundingAskDetailComponent implements OnInit {
  selectedFile: File;
  albumInfo: MonafundingAlbumInfo;
  
  album_name : string;

  tasks : Array<Task>;
  selected_task : Task;
  
  selected_ask_id : string;
  
  // RequestData begin.
  comment : string;
  subject : string;
  format : string;
  due_date_register : string;
  reward : string;
  due_date_reward : string;
  other_comment : string;
  // RequestData end.

  validation_result : string;

  constructor(private modalService: NgbModal, private http: HttpClient, private translate: TranslateService, private cookieService : CookieService, private router: Router, private title: Title, private activatedRoute: ActivatedRoute) {
    this.title.setTitle('モナファンディング モナコインで(イラスト)クラウドファンディング'); 
    
    console.log('InitLang in Album 1');
    console.log(this.cookieService.get('select_lang'));
    this.translate.use(this.cookieService.get('select_lang'));
    this.translate.setDefaultLang(this.cookieService.get('select_lang'));
   }

  ngOnInit() {
    console.log('InitLang in Album 2');
    console.log(this.cookieService.get('select_lang'));
    this.translate.use(this.cookieService.get('select_lang'));
    this.translate.setDefaultLang(this.cookieService.get('select_lang'));

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      // ルーティングモジュールの「:id」部分の定義により、'id' で取得できる
      this.selected_ask_id = params.get('id');
    });

    console.log(this.selected_ask_id);

    const uploadData = new FormData();

    var uu_session_id = this.cookieService.get('uu_session_id');
    console.log(uu_session_id);
    uploadData.append('uu_session_id', uu_session_id);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');

    this.http.post('https://back.monafunding.com/dbd/ask', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response : any = res;
          console.log(response);
          this.tasks = response;
          console.log("POST Request is successful");

          for(let index in this.tasks) {
            if(this.selected_ask_id == this.tasks[index]._id){
              this.selected_task = this.tasks[index];

              this.selected_task.command_open_as_asker = false;
              this.selected_task.command_open_as_worker = false;
              this.selected_task.command_paticipate_as_worker = false;
              this.selected_task.command_paticipate_as_asker = false;
              this.selected_task.command_admit_paticipants = false;
              this.selected_task.command_work_complete = false;
              this.selected_task.command_payment_complete = false;
              this.selected_task.command_verify_complete = false;

              for(let permitted_operation of this.selected_task.permitted_operations){
                console.log("Permitted Operation", permitted_operation);
                if("DisposeTask" == permitted_operation) this.selected_task.command_disposed_task = true;
                if("OpenAsAsker" == permitted_operation) this.selected_task.command_open_as_asker = true;
                if("OpenAsWorker" == permitted_operation) this.selected_task.command_open_as_worker = true;
                if("PaticipateAsWorker" == permitted_operation) this.selected_task.command_paticipate_as_worker = true;
                if("PaticipateAsAsker" == permitted_operation) this.selected_task.command_paticipate_as_asker = true;
                if("AdmitParticipants" == permitted_operation) this.selected_task.command_admit_paticipants = true;
                if("WorkComplete" == permitted_operation) this.selected_task.command_work_complete = true;
                if("PaymentComplete" == permitted_operation) this.selected_task.command_payment_complete = true;
                if("VerifyComplete" == permitted_operation) this.selected_task.command_verify_complete = true;
              }

              console.log("Task FOUND", index);
              console.log(this.selected_task);

              break;
            }
          }
        },
        err => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
        }
    )
  }

  onFileChanged(event) {
    console.log("onFileChanged");
    this.selectedFile = event.target.files[0]
    console.log(this.selectedFile.name);
  }

  onChangeChecks(event) {
    console.log("onChangeChecks");
    this.onUpload('Edit');
  }

  onExecuteCommand(command) {
    console.log('onUpload');
    console.log(command);
    this.onUpload(command);
    this.ngOnInit();
  }

  onEdit() {
    console.log('onUpload');
    this.onUpload('Edit');
  }

  onOpenAsAsker() {
    console.log('onUpload');
    this.onUpload('OpenAsAsker');
  }

  onOpenAsWorker() {
    console.log('onUpload');
    this.onUpload('OpenAsWorker');
  }
      
  onUpload(command) {
    console.log('onUpload');
    console.log(command);
  
    const uploadData = new FormData();
    // AlbumData begin.
    uploadData.append('subject', this.subject);
    uploadData.append('format', this.format);
    uploadData.append('due_date_register', this.due_date_register);
    uploadData.append('reward', this.reward);
    uploadData.append('due_date_reward', this.due_date_reward);
    uploadData.append('other_comment', this.other_comment);

    // AlbumData end.
    var uu_session_id = this.cookieService.get('uu_session_id');
    console.log(uu_session_id);
    uploadData.append('uu_session_id', uu_session_id);
    uploadData.append('method', 'update');
    uploadData.append('command', command);
    uploadData.append('task', JSON.stringify(this.selected_task));
    uploadData.append('select_lang', this.cookieService.get('select_lang'));

    const headers = new HttpHeaders();
    // headers.append('Content-Type', 'text/plain');
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Content-Type', 'multipart/form-data');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'multipart/form-data');
    this.http.post('https://back.monafunding.com/formd/ask', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response:any = res;
          console.log("POST Request is successful ", res);
        },
        err => {
          console.log("POST Request has a error ");
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);

          const modalRef = this.modalService.open(FormMessageModalComponent);
          modalRef.componentInstance.message = err.error;

          modalRef.result.then((result) => {
            console.log(result);
          }).catch((error) => {
            console.log(error);
          });
        }
    )
  }
}
