<div class="container toptitl">

    <hr class="featurette-divider">
    <h3 translate>聞きたいことはありませんか。</h3>
    <hr class="featurette-divider">
        
    
    <div class="row">
    <div class="col-md-1">
    </div>
    <div class="col-md-10">
    <form name="album_form" #album_form_="ngForm">
        <h3 translate>サービスについて</h3>
        <pre>
        <span translate>モナファンディングでは画像投稿者と支援者の間の支援（寄付）を促進するサイトです、支援方式は下記の３つが選べます。</span>
        <span translate>段階的ファンディング方式が本サイトの特徴なのでお勧めです。</span>

        <span translate>[段階的ファンディング方式]</span>
        <span translate>ブラー（ぼかし）のかかった画像を公開して、目標額に近づくにつれてブラー（ぼかし）が弱くなっていきます。</span>

        <span translate>[ファンディング方式]</span>
        <span translate>ブラー（ぼかし）のかかった画像を公開して、目標額に達するとブラー（ぼかし）が解除されます。</span>

        <span translate>[寄付方式]</span>
        <span translate>画像をそのまま（未加工）で公開して、自由に寄付を募ります。</span>

        </pre>
                    
        <h3 translate>ウォレットについて</h3>
        <pre>
        <span translate>近年のセキュリティ事故多発の状況を鑑みて、本サイト自体にウォレット機能はありません。</span>
        <span translate>支援（寄付）する場合は下記のようなウォレットソフトを使用ください。</span>

        <span translate>[画像投稿者]</span>
        <a href="https://electrum-mona.org/">electrum-mona</a>
        <span translate>が推奨です。</span>
        <a translate [routerLink]="['/monafunding/help-electrum']">インストール方法はこちら</a>
        
        <span translate>１つのウォレットにデフォルトで複数のモナコインアドレスを生成しますので、</span>
        <span translate>投稿画像ごとにアドレスを準備する必要がある本サービスに適しています。</span>

        <span translate>モナコインウォレット = 複数のモナコインアドレスの集まり</span>
        <span translate>※１つのモナコインアドレスだけでモナコイン送受信できます。</span>

        <span translate>秘密鍵をQRコードで表示できるのでペーパーウォレットなども作りやすいです。</span>
        <span translate>※モナコインは<秘密鍵>さえあれば、ウォレットアドレスも含めてすべて復元できます。</span>
        <span translate>(この操作もelectrum-monaで可能です)</span>

        <span translate>[支援者]</span>
        <span translate>electrum-monaを推奨しますが、</span>
        <span translate>スマートフォンやブラウザで少額を管理する場合は、</span><a translate href="https://monya-wallet.github.io/">もにゃ</a><span translate>などをご利用いただければと思います。</span>

        </pre>

        <hr class="featurette-divider">
        <h3 translate>Ｑ＆Ａ</h3>
        <h5 translate>準備中</h5>
    
        <hr class="featurette-divider">
        <h3 translate>禁止事項</h3>
        <h5 translate>準備中</h5>
    
        <hr class="featurette-divider">
        <h3 translate>利用規約</h3>
        <pre>
        <span translate>[会員申込]</span>
        <span translate>当Webサービスに対してテキスト情報や画像などの何らかの情報をアップロードした時点で、</span>
        <span translate>該当操作を行った本人が本利用規約を遵守することに同意したものとします。</span>
        
        <span translate>[退会]</span>
        <span translate>当サイトに登録された情報は、当サイトから操作できる範囲以外では原則として登録を抹消することはできません。</span>
        <span translate>※サイトの性質上、すでに支援を受け付けた画像データ（イラスト）を削除してしまうと、支援者に対して誠実な対応とはいえないため。</span>
        <span translate>※支援を受け付ける前でしたら、削除リスクエストをコメントに記載いただければ削除対応いたします。</span>
        
        <span translate>[本サービスの変更、追加または廃止]</span>
        <span translate>運営者はいつでも本サービスの内容を変更、追加、廃止することができるものとします。</span>
        <span translate>このとき利用者に生じた損害には一切責任を負いません。</span>
        
        <span translate>[禁止行為]</span>
        <span translate>知的財産権、肖像権、パブリシティ権その他の権利を侵害する行為</span>
        <span translate>名誉もしくは信用を毀損し、またはプライバシーを侵害する行為</span>
        
        <span translate>[権利関係]</span>
        <span translate>本Webサービスは画像投稿者と支援者の間の寄付/支援（贈与）を促進することを目的とします。</span>
        <span translate>寄付/支援は支援者と画像投稿者の間で行われるものとします。</span>
        
        <span translate>支援者は画像投稿者に寄付（贈与）を行う者として、明示的に画像投稿者から付与された場合を除き、</span>
        <span translate>画像投稿者は一切の権利を支援者に譲り渡していないものと理解しているものとします。</span>
        
        <span translate>当サイトでは投稿された画像データに対して、寄付の状況により画像の表示方法を変える機能を提供します。</span>
        <span translate>投稿者は当サイトに画像をアップロードした時点で、当サイトを通じて画像を未加工または加工して公開（表示）</span>
        <span translate>することを許可したものとします。</span>
        
        <span translate><アクター></span>
        <span translate>- 画像投稿者</span>
        <span translate>本サービスに対して画像を投稿するもの。</span>
        
        <span translate>- 支援者</span>
        <span translate>画像投稿者に寄付（贈与）した者。</span>
        
        <span translate>- 閲覧者</span>
        <span translate>本サービスの閲覧を行う物</span>
        
        <span translate>- 運営者</span>
        <span translate>本サービスの運営にかかわる者</span>
        
        <span translate><権利関係></span>
        <span translate>- 画像投稿者</span>
        
        <span translate>下記以外の投稿画像についての権利はすべて、画像投稿者に保持される（権利の移動/付与が発生しない）ものとします。</span>
        <span translate>・閲覧者/支援者が画像を本Webサービスを通して、加工済み(条件によっては未加工の場合を含む)の投稿画像を閲覧する権利。</span>
        <span translate>・運営者が本Webサービスを通じて、投稿画像をそのまま公開（表示）する権利、加工して公開（表示）する権利。</span>
        <span translate>・支援者に対して本Webサービスを通じて、明示的に付与された権利。</span>
        
        <span translate>- 支援者</span>
        <span translate>・加工済みの投稿画像を閲覧する権利。</span>
        <span translate>・画像投稿者から一定額以上の寄付（贈与）を条件に明示的に付与された権利</span>
        
        <span translate>- 閲覧者</span>
        <span translate>・加工済みの投稿画像を閲覧する権利。</span>
        
        <span translate>- 運営者</span>
        <span translate>・運営者が本Webサービスを通じて、投稿画像をそのまま公開（表示）する権利、加工して公開（表示）する権利。</span>
        
        <span translate>[免責]</span>
        <span translate>本Webサービスは、会員と画像投稿者の寄付（贈与）を促進する場であり、</span>
        <span translate>寄付（贈与）が発生している場合を含め、いかなる場合でも支援者/閲覧者に投稿画像の閲覧を保証するものではありません。</span>
        <span translate>また、画像投稿者に一定額の寄付（贈与）の発生を保証したり、投稿画像を保管(記録)することを保証するものではありません。</span>
        
        <span translate>本Webサービスを通じて、会員と画像投稿者の間で生じたトラブルに関しては、運営者は一切責任を負わないものとします。</span>
        <span translate>運営者はコンピュータウイルスなど有害なプログラム等による損害を受けないことを保証しないものとします。</span>
        <span translate>運営者はいかなる機器、ソフトウェアについても、その動作保証を一切しないものとします。</span>
        <span translate>運営者は本サービスを利用する際に発生する通信費用について、一切負担しないものとします。</span>
        <span translate>運営者は本サービスを利用した損害について一切の賠償しないものとします。</span>
        <span translate>運営者は課税に関し一切関与しないものとします。</span>
        
        <span translate>[利用規約の変更]</span>
        <span translate>本利用規約を変更することができるものとします。</span>
        <span translate>本利用規約を変更した場合にはウェブサイトにて公表するものとし、</span>
        <span translate>当該変更内容の公表の後、本サービスを利用した会員は、かかる変更に同意したとみなされるものとします。</span>
        </pre>
      </form>
      <hr class="featurette-divider">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/monafunding']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Help</li>
        </ol>
      </nav>
      <footer>
          <p>&copy; 2018 Monacity Project.</p>
      </footer>   
      </div>
      <div class="col-md-1">
      </div>
      </div>
      </div>
    