<div class="container toptitl">
<hr class="featurette-divider">
<h3 translate>Google/Twitterアカウントで認証。</h3>
<hr class="featurette-divider">

<div class="row">
    <div class="col-lg-8">    
      <h3 translate>ログイン認証</h3>
      <button (click)="btnAuthenticate()" type="button" class="btn btn-sm btn-outline-secondary">Google</button>
      <h6></h6>
      <button (click)="btnAuthenticateTwitter()" type="button" class="btn btn-sm btn-outline-secondary">Twitter</button>
      <h6></h6>
      <h6 translate>同一ユーザーであることの確認のため、IDとユーザー名しか取得しません</h6>
      <h5>{{authenticate_error}}</h5>
    </div><!-- /.col-lg-4 -->
</div>
<hr class="featurette-divider">
<div class="row">
    <div class="col-lg-8">    
      <h3 translate><span translate>ログイン情報</span> : {{current_user.name}}</h3>
      <h5 translate>※認証が正常に完了すると、ユーザー名が表示されます</h5>
    </div><!-- /.col-lg-4 -->
</div>

<hr class="featurette-divider">
<hr class="featurette-divider">
<h3 translate>以下は、オプションです。お好みでどうぞ</h3>
<hr class="featurette-divider">
<hr class="featurette-divider">

<h3 translate>モナコインアドレス登録</h3>
<h5 translate>※セキュリティ確保のため、モナコインアドレス生成機能はありませんので、外部ウォレットで生成してください</h5>
<h5 translate>※画像投稿用(寄付用)のアドレスとは別の保有モナ数を証明するためだけのアドレスです(オプション)</h5>
<div class="row">
<div class="col-md-1">
</div>
<div class="col-md-10">
    <form>
        <div class="form-group"> <!-- Full Name -->
          <label for="monacoin_id" class="control-label">Monacoin Address</label>
          <input type="text" class="form-control" id="monacoin_id" name="monacoin" [(ngModel)]="mona_adder" placeholder="{{mona_adder}}">
        </div>
        <div class="form-group"> <!-- Submit Button -->
          <button (click)="onSubmitForUpdateMona()" type="button" class = "btn-primary">Submit !</button>
        </div>	
    </form>
</div>
</div>
<hr class="featurette-divider">
<div class="row">
<div class="col-lg-8">    
    <h3><span translate>モナコインアドレス</span> : {{current_user.monacoin_adder}}</h3>
    <h3><span translate>モナコイン残高</span> : {{current_user.monacoin_adder_amount}}</h3>
    <h3><span translate>モナコイン口座状態</span> : {{current_user.monacoin_adder_status}}</h3>
</div><!-- /.col-lg-4 -->
</div>

<hr class="featurette-divider">
<h3 translate>モナコインアドレスの検証</h3>
<h5 translate>※検証が完了すると、モナコインアドレスの状態がVERIFIEDになり、MONA保有量が確定されます</h5>
<h5 translate>※下記のメッセージの電子署名を作成して入力してください</h5>
<h5>"{{current_user.monacoin_adder}}{{uu_session_id}}"</h5>
<div class="row">
<div class="col-md-1">
</div>
<div class="col-md-10">
    <form>
        <div class="form-group"> <!-- Signed Message -->
          <label for="monacoin_id" class="control-label">Signed Message</label>
          <input type="text" class="form-control" id="signed_message_id" name="signed_message" [(ngModel)]="signed_message" placeholder="signed_message">
        </div>
        <div class="form-group"> <!-- Submit Button -->
          <button (click)="onSubmitForSignedMessage()" type="button" class = "btn-primary">Submit !</button>
        </div>	
      </form>
</div>
</div>

<hr class="featurette-divider">
<h3 translate>プロフィール画像登録</h3>
<div class="row">
<div class="col-md-1">
</div>
<div class="col-md-10">
    <form>
        <div class="form-group"> <!-- Full Name -->
          <!-- <label for="first_image" class="control-label" style="display:none">First Image</label> -->
          <label for="first_image" class="control-label">Would you please image file to upload ({{selectedFile.name}}) →→→</label>
          <input 
          id="first_image"
          type="file" (change)="onFileChanged($event)"
          style="display:none"
          #fileInput>
          <button (click)="fileInput.click()" type="button" class = "btn-primary">Select File</button>
        </div>		
    
        <div class="form-group"> <!-- Submit Button -->
          <button (click)="onUploadUserImage()" type="button" class = "btn-primary">Upload!</button>
        </div>     
    </form>
</div>
</div>

<hr class="featurette-divider">
<h3 translate>プロフィールメッセージ登録</h3>
<div class="row">
<div class="col-md-1">
</div>
<div class="col-md-10">
    <form>
        <div class="form-group">
            <label translate for="exampleFormControlTextarea1">メッセージ</label>
            <textarea maxlength="100" class="form-control" id="exampleFormControlTextarea1" rows="3" name="profile_message" [(ngModel)]="profile_message" #profile_message_="ngModel"></textarea>
            <span  translate *ngIf="profile_message_.errors?.maxlength">名前は100文字以内で入力してください。</span>
        </div>
        <div class="form-group"> <!-- Submit Button -->
          <button (click)="onSubmitForUpdateUserProfile()" type="button" class = "btn-primary">Submit !</button>
        </div>
    </form>
</div>
</div>

<hr class="featurette-divider">
<nav aria-label="breadcrumb">
<ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="['/monafunding']">Home</a></li>
    <li class="breadcrumb-item active" aria-current="page">Register</li>
</ol>
</nav>
<footer>
        <p>&copy; 2018 Monacity Project.</p>
</footer>
</div>
