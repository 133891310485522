<div class="container toptitl">

    <hr class="featurette-divider">
    <h3 translate>Electrum-monaについて</h3>
    <hr class="featurette-divider">
        
    
    <div class="row">
    <div class="col-md-1">
    </div>
    <div class="col-md-10">
    <form name="album_form" #album_form_="ngForm">
        <h3 translate>インストール</h3>
        <pre>
        <a href="https://electrum-mona.org/">https://electrum-mona.org/</a>
        <span translate>上記アドレスから本体をダウンロードします</span>
        <span translate>Windowsの場合は"Windows installer(Japanease)"</span>
        <span translate>インストール自体はインストーラーに従ってください</span>
        </pre>
        <h3 translate>初期設定</h3>
        <pre>
        <img src="assets/electrum-mona-install-1.png" alt="1 slide">
        <span translate>スタートメニューから"Electrum-Mona"を起動します</span>
        <span translate>サーバーの接続方式は自動接続を選択します</span>

        <img src="assets/electrum-mona-install-2.png" alt="2 slide">
        <span translate>好きなウォレット名称を入力して、次へをクリックしてください</span>

        <img src="assets/electrum-mona-install-3.png" alt="3 slide">
        <span translate>ウォレットの種類を選びます、Standard walletを選択してください</span>

        <img src="assets/electrum-mona-install-4.png" alt="4 slide">
        <span translate>暗号鍵のシードは新規作成(Create a new seed)を選択</span>

        <img src="assets/electrum-mona-install-5.png" alt="5 slide">
        <span translate>ウォレットのタイプはStandardを選択してください</span>

        <img src="assets/electrum-mona-install-6.png" alt="6 slide">
        <span translate>PCやウォレットが故障したときに復旧に必要なパスフレーズが表示されます</span>
        <span translate>資金を失っては困る場合は、可能な限り紙に書き写してください</span>

        <img src="assets/electrum-mona-install-7.png" alt="7 slide">
        <span translate>確認のためパスフレーズを入力します</span>

        <img src="assets/electrum-mona-install-8.png" alt="8 slide">
        <span translate>ウォレットを保護するためのパスワードを設定します</span>

        <img src="assets/electrum-mona-install-9.png" alt="9 slide">
        <span translate>モナコインアドレスを表示させます</span>

        <img src="assets/electrum-mona-install-10.png" alt="10 slide">
        <span translate>１つのウォレットに複数のアドレスが作成されています</span>
        <span translate>１つ１つのアドレスで入出金できますので、１つの投稿に対して必ず１つのアドレスを割り当ててください</span>
        <span translate>※一度でも入金を受け付けたことのあるアドレスを利用すると、累積入金額が寄付された金額としてカウントされてしまいます</span>
        </pre>
      </form>
      <hr class="featurette-divider">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/monafunding']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Install Electrum-Mona</li>
        </ol>
 　   </nav>     
        
      <footer>
          <p>&copy; 2018 Monacity Project.</p>
      </footer>   
      </div>
      <div class="col-md-1">
      </div>
      </div>
      </div>
    