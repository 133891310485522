import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';


import {MonaclickerRankingComponent} from './monaclicker-ranking/monaclicker-ranking.component';
import {MonaclickerComponent} from './monaclicker/monaclicker.component';
import {EntranceComponent} from './entrance/entrance.component';
import {GreatpaintersComponent} from './greatpainters/greatpainters.component'; 
import {MonaoceanComponent} from './monaocean/monaocean.component'; 
import {MonabankingComponent} from './monabanking/monabanking.component';
import {MonafundingTopComponent} from './monafunding-top/monafunding-top.component';
import {MonafundingAskComponent} from './monafunding-ask/monafunding-ask.component';
import {MonafundingAskDetailComponent} from './monafunding-ask-detail/monafunding-ask-detail.component';
import {MonafundingRegisterAlbumComponent} from './monafunding-register-album/monafunding-register-album.component';
import {MonafundingAuthenticateComponent} from './monafunding-authenticate/monafunding-authenticate.component';
import {MonafundingHelpComponent} from './monafunding-help/monafunding-help.component';
import {MonafundingHelpElectrumComponent} from './monafunding-help-electrum/monafunding-help-electrum.component';
import {MonafundingDonationComponent} from './monafunding-donation/monafunding-donation.component';
import {MonafundingRequestComponent} from './monafunding-request/monafunding-request.component';


const routes: Routes = [
	{path: 'ja', component: EntranceComponent},
	{path: 'en', component: EntranceComponent},
	{path: '', component: EntranceComponent},
	{path: 'monaclickerranking', component: MonaclickerRankingComponent},
	{path: 'monaclicker', component: MonaclickerComponent},
	{path: 'greatpainters', component: GreatpaintersComponent},
	{path: 'monaocean', component: MonaoceanComponent},
	{path: 'monabanking', component: MonabankingComponent},
  {path: 'monafunding-ask', component: MonafundingAskComponent},
  {path: 'monafunding-ask-detail/:id', component: MonafundingAskDetailComponent},
  {path: 'monafunding-ja/register-album', component: MonafundingRegisterAlbumComponent},
  {path: 'monafunding-en/register-album', component: MonafundingRegisterAlbumComponent},
  {path: 'monafunding/register-album', component: MonafundingRegisterAlbumComponent},
  {path: 'monafunding-ja/authenticate', component: MonafundingAuthenticateComponent},
  {path: 'monafunding-en/authenticate', component: MonafundingAuthenticateComponent},
  {path: 'monafunding/authenticate', component: MonafundingAuthenticateComponent},
  {path: 'monafunding-ja/help', component: MonafundingHelpComponent},
  {path: 'monafunding-en/help', component: MonafundingHelpComponent},
  {path: 'monafunding/help', component: MonafundingHelpComponent},
  {path: 'monafunding-ja/help-electrum', component: MonafundingHelpElectrumComponent},
  {path: 'monafunding-en/help-electrum', component: MonafundingHelpElectrumComponent},
  {path: 'monafunding/help-electrum', component: MonafundingHelpElectrumComponent},
  {path: 'monafunding-ja/donation/:id', component: MonafundingDonationComponent},
  {path: 'monafunding-en/donation/:id', component: MonafundingDonationComponent},
  {path: 'monafunding/donation/:id', component: MonafundingDonationComponent},
  {path: 'monafunding/request', component: MonafundingRequestComponent},
  {path: 'monafunding/ja', component: MonafundingTopComponent},
  {path: 'monafunding/en', component: MonafundingTopComponent},
  {path: 'monafunding', component: MonafundingTopComponent},
];

@NgModule({
//  imports: [
//    CommonModule
//  ],
//  declarations: []
    imports: [
      RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
