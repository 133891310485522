<div class="container toptitl">

    <hr class="featurette-divider">
    <h3 translate>偉大なる絵師様たち</h3>

    <hr class="featurette-divider">
    <div class="row">
        <div class="col-lg-8">    
          <h3 translate>ログイン認証</h3>
          <button (click)="btnAuthenticateTwitter()" type="button" class="btn btn-sm btn-outline-secondary">Twitter</button>
        </div><!-- /.col-lg-4 -->
    </div>

    <hr class="featurette-divider">
    <div class="row">
        <div class="col-lg-8">    
          <h3 translate>クエリ実行</h3>
          <button (click)="btnQueryTwitter()" type="button" class="btn btn-sm btn-outline-secondary">Twitter</button>
        </div><!-- /.col-lg-4 -->
    </div>

    <hr class="featurette-divider">
        
    
    <div class="row">
    <div class="col-md-1">
    </div>
    <div class="col-md-10">
    <form name="album_form" #album_form_="ngForm">
        <h3 translate>絵師様リンク</h3>
        <pre>
        <span translate>個人用の絵師様のリンク集です、twitterのタイムラインから画像付きのtwitterを検索することもできます。</span>
        </pre>
                  
        <h5>twitterのタイムラインに投稿された画像(直近1000件から抽出)</h5>
        <div class="album py-5 bg-light">
          <div class="container">
            <div class="row">
              <ng-container *ngFor="let tweetinfo of tweetinfos">
                <div class="col-md-4">
                  <div class="card mb-4 box-shadow">
                    <img class="card-img-top" src="{{tweetinfo.media_url}}" alt="モナ米">
                    <div class="card-body">
                      <p class="card-text"><a translate href="https://twitter.com/{{tweetinfo.screen_name}}/status/{{tweetinfo.tweet_id}}">{{tweetinfo.screen_name}}</a></p>
                      <p translate class="card-text">{{tweetinfo.date}}</p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <h5>仮想通貨でコミッションを受け付けている</h5>
        <div class="album py-5 bg-light">
          <div class="container">
            <div class="row">

      
            </div>
          </div>
        </div>
        
        <h5>コミッションを受け付けている</h5>
        <div class="album py-5 bg-light">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <div class="card mb-4 box-shadow">
                  <img class="card-img-top" src="http://capture.heartrails.com/400x325/border/delay=3?https://twitter.com/Dai_dai_iro" alt="Dai_dai_iro">
                  <div class="card-body">
                    <p class="card-text"><a translate href="https://twitter.com/Dai_dai_iro">Dai_dai_iro さま</a></p>
                  </div>
                </div>
              </div>
              
           </div>
          </div>
        </div>
        
        <h5>お仕事を受け付けている</h5>
        <div class="album py-5 bg-light">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <div class="card mb-4 box-shadow">
                  <img class="card-img-top" src="http://capture.heartrails.com/400x325/cool?https://twitter.com/y_k_sme" alt="ゆきさめ">
                  <div class="card-body">
                    <p class="card-text"><a translate href="https://twitter.com/y_k_sme">ゆきさめ さま</a></p>
                  </div>
                </div>
              </div>
      
              <div class="col-md-4">
                <div class="card mb-4 box-shadow">
                  <img class="card-img-top" src="http://capture.heartrails.com/400x325/cool?https://twitter.com/mizumizutorisan" alt="水溜鳥">
                  <div class="card-body">
                    <p class="card-text"><a translate href="https://twitter.com/mizumizutorisan">水溜鳥 さま</a></p>
                 </div>
                </div>
              </div>
              
           </div>
          </div>
        </div>

        <h5>コミッションOKか不明</h5>
        <div class="album py-5 bg-light">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <div class="card mb-4 box-shadow">
                  <img class="card-img-top" src="http://capture.heartrails.com/400x325/cool?https://twitter.com/ruarua13329135" alt="Orizz">
                  <div class="card-body">
                    <p class="card-text"><a translate href="https://twitter.com/ruarua13329135">Orizz さま</a></p>
                  </div>
                </div>
              </div>
      
              <div class="col-md-4">
                <div class="card mb-4 box-shadow">
                  <img class="card-img-top" src="http://capture.heartrails.com/400x325/cool?https://twitter.com/rurudo_" alt="rurudo">
                  <div class="card-body">
                    <p class="card-text"><a translate href="https://twitcasting.tv/">rurudo さま</a></p>
                  </div>
                </div>
              </div>
      
              <div class="col-md-4">
                <div class="card mb-4 box-shadow">
                  <img class="card-img-top" src="http://capture.heartrails.com/400x325/cool?https://twitter.com/monamona_0101" alt="mona">
                  <div class="card-body">
                    <p class="card-text"><a translate href="https://twitter.com/monamona_0101">mona さま</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <hr class="featurette-divider">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Mona Ocean</li>
        </ol>
      </nav>
      <footer>
          <p>※Webサイトの画像は引用のため自動取得/掲載しております</p>
          <p>&copy; 2018 Monacity Project.</p>
      </footer>   
      </div>
      <div class="col-md-1">
      </div>
      </div>
      </div>
    