import { Component, OnInit } from '@angular/core';
import { v4 as uuid } from 'uuid';

import { MonafundingService } from '../monafunding.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core'

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';
import { Title }     from '@angular/platform-browser';

class AUser {
  id: string;
  name: string;
  uu_session_id: string;
  monacoin_adder: string;
  monacoin_adder_status: string;
  monacoin_adder_amount: number;
  profile_message: string;
}

@Component({
  selector: 'app-monafunding-authenticate',
  templateUrl: './monafunding-authenticate.component.html',
  styleUrls: ['./monafunding-authenticate.component.css'],
  providers: [ Title ]  // ［1］Titleサービスを登録 
})

export class MonafundingAuthenticateComponent implements OnInit {
  public uu_session_id : string
  public mona_adder : string
  public current_user : AUser = new AUser();
  selectedFile: File;
  authenticate_error: string;
  profile_message: string;
  signed_message: string;
  
  constructor(private translate: TranslateService, private cookieService : CookieService, private http: HttpClient, private router: Router, private title: Title) {
    this.title.setTitle('モナファンディング モナコインで(イラスト)クラウドファンディング OAuth'); 

    console.log('ConstLang ');
    console.log(this.cookieService.get('select_lang'));
    translate.setDefaultLang(this.cookieService.get('select_lang'));
    translate.use(this.cookieService.get('select_lang'));
  }

  ngOnInit() {
    console.log('InitLang ');
    console.log(this.cookieService.get('select_lang'));
    this.translate.setDefaultLang(this.cookieService.get('select_lang'));
    this.translate.use(this.cookieService.get('select_lang'));

    const uploadData = new FormData();

    this.current_user.id = "";
    this.current_user.name = "Blank";
    this.current_user.monacoin_adder = "";
    this.current_user.monacoin_adder_status = "INITIAL";
    this.current_user.monacoin_adder_amount = 0;

    this.uu_session_id = this.cookieService.get('uu_session_id');

    var uu_session_id = this.cookieService.get('uu_session_id');
    console.log(uu_session_id);
    uploadData.append('uu_session_id', uu_session_id);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');

    this.authenticate_error = "";
    this.http.post('https://back.monafunding.com/dbd/currentuser', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response : any = res;

          if(response.length > 0) this.current_user = response[0];
    
          console.log("POST Request is successful (user) ", response);
          console.log("POST Request is successful (user) ", this.current_user);
        },
        err => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
        }
    )
  }

  btnAuthenticate = function () {
    this.uu_session_id = uuid();
    console.log(this.cookieService.get('uu_session_id'));
    console.log(this.uu_session_id);
    this.cookieService.set('uu_session_id', this.uu_session_id, 1, '/');
    console.log(this.cookieService.get('uu_session_id'));

    const uploadData = new FormData();
    uploadData.append('uu_session_id', this.uu_session_id);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');

    this.authenticate_error = "";
    this.http.post('https://back.monafunding.com/formd/uusessionid', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
           console.log("POST Request is successful ");
           location.href = 'https://back.monafunding.com/auth/google?uu_session_id=' + this.uu_session_id;
          },
        err => {
          this.authenticate_error = "Authenticate Server is busy... Would you please try after a few minutes";
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);
        }
    )
  };

  btnAuthenticateTwitter = function () {
    this.uu_session_id = uuid();
    console.log(this.cookieService.get('uu_session_id'));
    console.log(this.uu_session_id);
    this.cookieService.set('uu_session_id', this.uu_session_id, 1, '/');
    console.log(this.cookieService.get('uu_session_id'));

    const uploadData = new FormData();
    uploadData.append('uu_session_id', this.uu_session_id);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');

    this.authenticate_error = "";
    this.http.post('https://back.monafunding.com/formd/uusessionid', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
           console.log("POST Request is successful ");
           location.href = 'https://back.monafunding.com/auth/twitter?uu_session_id=' + this.uu_session_id;
          },
        err => {
          this.authenticate_error = "Authenticate Server is busy... Would you please try after a few minutes";
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);
        }
    )
  };

  onSubmitForUpdateMona = function () {
    console.log('onMonacoinAdderSubmit');

    var uu_session_id = this.cookieService.get('uu_session_id');
    console.log(this.current_user.id);
    console.log(uu_session_id);
    
    const uploadData = new FormData();
    uploadData.append('uu_session_id', uu_session_id);
    uploadData.append('monacoin_adder', this.mona_adder);

    uploadData.append('profile_message', this.current_user.profile_message);
    uploadData.append('id', this.current_user.id);
    uploadData.append('name', this.current_user.name);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');

    this.http.post('https://back.monafunding.com/formd/currentuser_update', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
           console.log("POST Request is successful ");
           this.ngOnInit();
          },
        err => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
        }
    )
  };

  onSubmitForSignedMessage = function () {
    console.log('onSubmitForSignedMessage');

    var uu_session_id = this.cookieService.get('uu_session_id');
    console.log(uu_session_id);

    console.log(this.uu_session_id);
    console.log(this.current_user.monacoin_adder);
    console.log(this.signed_message);

    const uploadData = new FormData();
    uploadData.append('uu_session_id', this.uu_session_id);
    uploadData.append('monacoin_adder', this.current_user.monacoin_adder);
    uploadData.append('signed_message', this.signed_message);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');

    this.http.post('https://back.monafunding.com/formd/verify_signed_message', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
           console.log("POST Request is successful ");
           this.ngOnInit();           
          },
        err => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
        }
    )
  };

  onSubmitForUpdateUserProfile = function () {
    console.log('onMonacoinAdderSubmit');

    var uu_session_id = this.cookieService.get('uu_session_id');
    console.log(this.current_user.id);
    console.log(uu_session_id);
    
    const uploadData = new FormData();
    uploadData.append('uu_session_id', uu_session_id);
    uploadData.append('select_lang', this.cookieService.get('select_lang'));
    uploadData.append('profile_message', this.profile_message);

    uploadData.append('monacoin_adder', this.current_user.monacoin_adder);
    uploadData.append('id', this.current_user.id);
    uploadData.append('name', this.current_user.name);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');

    this.http.post('https://back.monafunding.com/formd/currentuser_update', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
           console.log("POST Request is successful ");
           this.router.navigate(["/monafunding"]);
          },
        err => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
        }
    )
  };

  onFileChanged(event) {
    console.log("onFileChanged");
    this.selectedFile = event.target.files[0]
    console.log(this.selectedFile.name);
  }
  
  onUploadUserImage() {
    console.log('onUploadUserImage');

    const uploadData = new FormData();
    uploadData.append('myFile', this.selectedFile, this.selectedFile.name);
    uploadData.append('user_id', this.current_user.id);

    var uu_session_id = this.cookieService.get('uu_session_id');
    console.log(this.current_user.id);
    console.log(uu_session_id);
    uploadData.append('uu_session_id', uu_session_id);

    const headers = new HttpHeaders();
    // headers.append('Content-Type', 'text/plain');
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Content-Type', 'multipart/form-data');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'multipart/form-data');
    this.http.post('https://back.monafunding.com/formd/userimage', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
           let response:any = res;
           console.log("POST Request is successful ", res);
           this.router.navigate(["/monafunding"]);
        },
        err => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
        }
    )
  }
}
