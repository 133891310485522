import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monabanking',
  templateUrl: './monabanking.component.html',
  styleUrls: ['./monabanking.component.css']
})
export class MonabankingComponent implements OnInit {
  public account_for_withdraw;
  public mona_adder_for_withdraw;

  public mona_for_withdraw = 1;
  public os_kind: string;

  constructor() {
    if (navigator.userAgent.indexOf("Android")>0){
      this.os_kind = "Android"
      console.log("Android");
    }
    else{
      this.os_kind = "Other"
    }
  }

  ngOnInit() {
  }
  monaRest(num){
    this.mona_for_withdraw = num;
  }
  monaCaliculate(num){
    this.mona_for_withdraw += num;
    if(this.mona_for_withdraw < 0) this.mona_for_withdraw = 0;
    this.mona_for_withdraw = Math.round(this.mona_for_withdraw * 1000) / 1000;
  }
}
