import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core'

import { Router } from '@angular/router';
import { Title }     from '@angular/platform-browser'; 

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



class RankingAssetInfo {
  coin_address: string;
  total: number; 
  total_delta: number; 
  total_value: number;
  total_rank: number;
  fire: number;
  water: number;
  light: number;
  dark: number;
  gold: number;
  fantom: number;
  asset_total_value: number;
}

@Component({
  selector: 'app-monaclicker-ranking',
  templateUrl: './monaclicker-ranking.component.html',
  styleUrls: ['./monaclicker-ranking.component.css']
})
export class MonaclickerRankingComponent implements OnInit {
  selectedFile: File;
  
  album_name : string;

  // RequestData begin.
  comment : string;
  subject : string;
  format : string;
  due_date_register : string;
  reward : string;
  due_date_reward : string;
  other_comment : string;
  // RequestData end.

  validation_result : string;

  ranking_asset_info : Array<RankingAssetInfo> = new Array();

  constructor(private modalService: NgbModal, private http: HttpClient, private translate: TranslateService, private cookieService : CookieService, private router: Router, private title: Title) {
  }

  ngOnInit() {
    const uploadData = new FormData();

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');

    this.http.post('https://back.monafunding.com/monaclick/rankdata', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response : any = res;
          console.log(response);

          var ranking_asset : Array<RankingAssetInfo> = new Array();

          var max_asset_total_value : number;
          var max_asset_total_index : number;
          var length : number;

          // somehow default sort function has not been worked.
          length = response.length;
          for(var i = 0; i < length; i++){
            max_asset_total_value = 0;
            max_asset_total_index = -1;

            for(var j = 0; j < response.length; j++){
              if(undefined != response[j].asset_total_value) {
                if(Number(response[j].asset_total_value) > Number(max_asset_total_value)){
                  max_asset_total_value = response[j].asset_total_value;
                  max_asset_total_index = j;
                };
              }
            }
            
            console.log("choice", i);
            console.log("choice", max_asset_total_index);
            console.log("choice", max_asset_total_value);
            if(max_asset_total_index >= 0){
              ranking_asset.push(response[max_asset_total_index]);
              response.splice(max_asset_total_index, 1);
            }
          }

          this.ranking_asset_info = ranking_asset;

          console.log("POST Request is successful");
        },
        err => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
        }
    )
  }

  onFileChanged(event) {
    console.log("onFileChanged");
  }

  onChangeChecks(event) {
    console.log("onChangeChecks");
  }

  onUpload() {
    console.log('onUpload');
  }
}
