import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ParamMap } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core'

import { Router } from '@angular/router';

import { Title }     from '@angular/platform-browser'; 

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormMessageModalComponent } from '../form-message-modal/form-message-modal.component';

class AUser {
  id: string;
  name: string;
  uu_session_id: string;
  profile_message: string;
  monacoin_adder_rank: string;
}

class AUserDonation {
  id: string;
  name: string;
  uu_session_id: string;
  profile_message: string;
  monacoin_adder_rank: string;
  amount_donation : string;
}

class Users{
  users : Array<AUser>;
}

class AAlbum {
  filename: string;
  id: string;
  src: string;
  thumb : string;
  mona_adder_status : string;
  mona_adder_amount : string;

  mona_adder_for_fee : string;
  mona_adder_for_fee_amount : string;
  mona_adder_for_fee_rate : number;

  album_name : string;
  comment : string;
  sub_comment : string;
  mona_adder : string;
  coiner_only : string;
  image_rating : string;
  support_type : string;
  mona_for_supporter : string;
  mona_for_1step : string;
  media_publicity : string;
  permission_personal_copy : boolean;
  permission_supporter : boolean;
  permission_comercial_use : boolean;

  extention_donate_to_web_master : boolean;
}

class AMessage {
  filename: string;
  id: string;
  name: string;
  title : string;
  message : string;
  monacoin_adder_rank: string;  
}


@Component({
  selector: 'app-monafunding-donation',
  templateUrl: './monafunding-donation.component.html',
  styleUrls: ['./monafunding-donation.component.css'],
  providers: [ Title ]  // ［1］Titleサービスを登録 
})
export class MonafundingDonationComponent implements OnInit {
  albums : Array<AAlbum>;
  users : Array<AUser>;
  users_donation : Array<AUserDonation>;

  current_album : AAlbum = new AAlbum();
  current_user : AUser = new AUser();

  album_filename : string;
  album_id : string;
  step : number;
  progress : number;
  progress_cap : number;

  extention_demo_anime : boolean;
  extention_release_limit : number;

  title : string;
  message :string;

  select_lang = 'ja';

  messages : Array<AMessage>;
  uu_session_id : string;

  constructor(private modalService: NgbModal, private activatedRoute: ActivatedRoute, private http: HttpClient, private translate: TranslateService, private cookieService : CookieService, private title_: Title) {
    this.title_.setTitle('モナファンディング モナコインで(イラスト)クラウドファンディング 寄付/支援の受付'); 
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      // ルーティングモジュールの「:id」部分の定義により、'id' で取得できる
      this.album_filename = params.get('id');
      this.album_id = "";
    });

    console.log('InitLang ');
    console.log(this.cookieService.get('select_lang'));
    this.select_lang = this.cookieService.get('select_lang');
    this.translate.use(this.cookieService.get('select_lang'));
    this.translate.setDefaultLang(this.cookieService.get('select_lang'));

    console.log('onUpload');
  
    const uploadData = new FormData();

    var uu_session_id = this.cookieService.get('uu_session_id');
    console.log(uu_session_id);
    this.uu_session_id = uu_session_id;

    uploadData.append('uu_session_id', uu_session_id);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');

    this.http.post('https://back.monafunding.com/dbd/album', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response : any = res;
          this.albums = response;
    
          console.log("POST Request is successful (album) ", this.albums);

          for(let index in this.albums) {
            if(this.album_filename == this.albums[index].filename){
             this.album_id = this.albums[index].id;
             console.log("Album FOUND", index);
             this.current_album = this.albums[index];
             this.step = Math.floor(parseFloat(this.current_album.mona_adder_amount)  / parseFloat(this.current_album.mona_for_1step));
             if(this.step > 12) this.step = 12;
             this.step = 12 - this.step;
             this.progress = Math.floor((parseFloat(this.current_album.mona_adder_amount) / parseFloat(this.current_album.mona_for_1step))*100)/100;
             this.progress_cap = Math.floor(( (parseFloat(this.current_album.mona_adder_for_fee_amount) / (parseFloat(this.current_album.mona_for_1step) * (this.current_album.mona_adder_for_fee_rate / 100))) ) *100)/100;
             if(this.progress > this.progress_cap) this.progress = this.progress_cap;

             break;
            }
          }

          console.log(this.album_filename);
          uploadData.append('filename', this.album_filename);
      
          this.http.post('https://back.monafunding.com/dbd/message', uploadData, {headers, withCredentials: true}).subscribe(
            (res : Response) => {
              let response : any = res;
              this.messages = response;
        
              console.log("POST Request is successful (message) ", this.messages);

              this.http.get('https://back.monafunding.com/dbd/userlist', {headers, withCredentials: true}).subscribe(
                  (res : Response) => {
                    let response : any = res;
                    this.users = response;
              
                    console.log("POST Request is successful (user) ", this.users);

                    for(let index in this.users) {
                      if(this.album_id == this.users[index].id){
                       console.log("Owner User FOUND", index);
                       this.current_user = this.users[index];
          
                       break;
                      }
                    }
          
                  },
                  err => {
                      console.log(err.error);
                      console.log(err.name);
                      console.log(err.message);
                      console.log(err.status);
                  }
              )

            },
            err => {
              console.log(err.error);
              console.log(err.name);
              console.log(err.message);
              console.log(err.status);
            }
          )

          console.log(this.current_album.mona_adder);
          uploadData.append('mona_adder', this.current_album.mona_adder);

          this.http.post('https://back.monafunding.com/dbd/userlist_donating', uploadData, {headers, withCredentials: true}).subscribe(
              (res : Response) => {
                let response : any = res;
                this.users_donation = response;
          
                console.log("POST Request is successful (users_donation) ", this.users_donation);
              },
              err => {
                  console.log(err.error);
                  console.log(err.name);
                  console.log(err.message);
                  console.log(err.status);
              }
          )
      
        },
        err => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
        }
    )

  }

  onDemoCheckChanged(event) {
    console.log(this.extention_demo_anime);

    if(true == this.extention_demo_anime){
      if(undefined == this.extention_release_limit){
        setInterval(() => { this.updateImage(); }, 1000);
      }
      this.extention_release_limit = 12;
    }
  }

  updateImage() {
    if(true == this.extention_demo_anime){
      console.log(this.extention_release_limit);

      this.extention_release_limit = this.extention_release_limit - 1;
      if(this.extention_release_limit < 0){
        this.extention_release_limit = 12;
      }
    }
  }

  onSubmit() {
    console.log('onSubmit');
  
    const uploadData = new FormData();

    // AlbumData begin.
    uploadData.append('title', this.title);
    uploadData.append('message', this.message);
    console.log(this.message);
    // AlbumData end.

    var uu_session_id = this.cookieService.get('uu_session_id');
    console.log(uu_session_id);
    uploadData.append('uu_session_id', uu_session_id);
    uploadData.append('select_lang', this.cookieService.get('select_lang'));
    uploadData.append('filename', this.album_filename);

    const headers = new HttpHeaders();
    // headers.append('Content-Type', 'text/plain');
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');
    console.log("POST Request ");
    this.http.post('https://back.monafunding.com/formd/message', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response:any = res;
          console.log("POST Request is successful ", res);
          this.ngOnInit();
        },
        err => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);

            const modalRef = this.modalService.open(FormMessageModalComponent);
            modalRef.componentInstance.message = "メッセージを送信できませんでした、ログインが必要です";
  
            modalRef.result.then((result) => {
              console.log(result);
            }).catch((error) => {
              console.log(error);
            });
        }
    )
  }
}
