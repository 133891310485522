import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core'

import {MonafundingAlbumInfo} from '../monafunding.service';

import { Router } from '@angular/router';
import { Title }     from '@angular/platform-browser'; 

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormMessageModalComponent } from '../form-message-modal/form-message-modal.component';

@Component({
  selector: 'app-monafunding-register-album',
  templateUrl: './monafunding-register-album.component.html',
  styleUrls: ['./monafunding-register-album.component.css'],
  providers: [ Title ]  // ［1］Titleサービスを登録 
})

export class MonafundingRegisterAlbumComponent implements OnInit {
  selectedFile: File;
  albumInfo: MonafundingAlbumInfo;
  
  // AlbumData begin.
  album_name : string;
  comment : string;
  sub_comment : string;
  mona_adder : string;
  support_type : string = "段階的ファンディング方式";
  mona_for_supporter : string = "10";
  mona_for_1step : number = 100;
  media_publicity : string = "never-free";
  image_rating : string = "art";
  coiner_only : string = "r0";
  permission_personal_copy : boolean;
  permission_supporter : boolean;
  permission_comercial_use : boolean;
  extention_keep_out : boolean;
  extention_donate_to_web_master : boolean;
  // AlbumData end.

  validation_result : string;

  constructor(private modalService: NgbModal, private http: HttpClient, private translate: TranslateService, private cookieService : CookieService, private router: Router, private title: Title) {
    this.title.setTitle('モナファンディング モナコインで(イラスト)クラウドファンディング'); 
    
    console.log('InitLang in Album 1');
    console.log(this.cookieService.get('select_lang'));
    this.translate.use(this.cookieService.get('select_lang'));
    this.translate.setDefaultLang(this.cookieService.get('select_lang'));
   }

  ngOnInit() {
    console.log('InitLang in Album 2');
    console.log(this.cookieService.get('select_lang'));
    this.translate.use(this.cookieService.get('select_lang'));
    this.translate.setDefaultLang(this.cookieService.get('select_lang'));
  }

  onFileChanged(event) {
    console.log("onFileChanged");
    this.selectedFile = event.target.files[0]
    console.log(this.selectedFile.name);
  }

  onChangeChecks(event) {
    console.log("onChangeChecks");

    if(true == this.extention_donate_to_web_master){
      this.mona_adder = "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
    }
  }

  onUpload() {
    console.log('onUpload');
  
    // Validation.
    this.validation_result = "";
    if(true == isNaN(Number(String(this.mona_for_supporter)))){
      this.validation_result += " [エラー] 数値ではないみたいです：支援者と認定されるために必要なMONA";
    }
    if(true == isNaN(Number(String(this.mona_for_1step)))){
      this.validation_result += " [エラー] 数値ではないみたいです：公開段階を１上げるために必要なMONA";
    }
    if(undefined == this.selectedFile){
      this.validation_result += " [エラー] アップロードする画像が選択されていないみたいです";
    }
    

    if("" == this.validation_result){
      const uploadData = new FormData();
      uploadData.append('myFile', this.selectedFile, this.selectedFile.name);

      // AlbumData begin.
      uploadData.append('album_name', this.album_name);
      uploadData.append('comment', this.comment);
      uploadData.append('sub_comment', this.sub_comment);
      uploadData.append('mona_adder', this.mona_adder);
      uploadData.append('support_type', this.support_type);
      uploadData.append('mona_for_supporter', this.mona_for_supporter);
      uploadData.append('mona_for_1step', "" + this.mona_for_1step);
      uploadData.append('media_publicity', this.media_publicity);
      uploadData.append('image_rating', this.image_rating);
      uploadData.append('coiner_only', this.coiner_only);
      uploadData.append('permission_personal_copy', String(this.permission_personal_copy));
      uploadData.append('permission_supporter', String(this.permission_supporter));   
      uploadData.append('permission_comercial_use', String(this.permission_comercial_use));
      uploadData.append('extention_keep_out', String(this.extention_keep_out));
      uploadData.append('extention_donate_to_web_master', String(this.extention_donate_to_web_master));
      // AlbumData end.

      var uu_session_id = this.cookieService.get('uu_session_id');
      console.log(uu_session_id);
      uploadData.append('uu_session_id', uu_session_id);
      uploadData.append('select_lang', this.cookieService.get('select_lang'));

      const headers = new HttpHeaders();
      // headers.append('Content-Type', 'text/plain');
      // headers.append('Content-Type', 'application/x-www-form-urlencoded');
      headers.append('Content-Type', 'multipart/form-data');
      headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
      const params = new HttpParams().set('Content-Type', 'multipart/form-data');
      this.http.post('https://back.monafunding.com/formd/album', uploadData, {headers, withCredentials: true}).subscribe(
          (res : Response) => {
            let response:any = res;
            console.log("POST Request is successful ", res);
            this.router.navigate(["/monafunding"]);
          },
          err => {
              console.log(err.error);
              console.log(err.name);
              console.log(err.message);
              console.log(err.status);

              const modalRef = this.modalService.open(FormMessageModalComponent);
              modalRef.componentInstance.message = err.error;
    
              modalRef.result.then((result) => {
                console.log(result);
              }).catch((error) => {
                console.log(error);
              });
          }
      )
    }
  }
}
