
<hr class="featurette-divider">
<div class="row featurette">
<div class="col-10">
</div>
<div class="col-1">
<p><button class="btn btn-sm btn-primary" (click)="onUpload()" role="button">新規出稿</button></p>
</div>
</div>

<hr class="featurette-divider">
<h5 translate>お仕事リスト</h5>
<hr class="featurette-divider">

<ng-container *ngFor="let task of tasks; let i = index"> 
<div class="row featurette">
<div class="col-1">
</div>

<div class="card border-dark col-10">
  <div class="card-header">現行のリライトをお願いします。緊急度は高めです</div>
  <div class="card-body text-dark">
    <h4 class="card-title">
    <a href="/monafunding-ask-detail/{{task._id}}">
    仕事の依頼 "{{task.subject}}" 
    id={{task._id}}</a>
    (作成者 <a href='https://twitter.com/{{task.twitter_id}}'>{{task.twitter_id}}</a>)
    </h4>
    <!--
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    -->
    <table class="table table-bordered">
        <thead>
            <tr>
              <th scope="col">ASKER</th>
              <th scope="col">Status</th>
              <th scope="col">報酬の合計</th>
              <th scope="col">Created Time</th>
              <th scope="col">WORKER</th>
            </tr>
          </thead>
          <tbody>
          <tr>
          <!--
          <th scope="row">1</th>
          -->
            <td><a href='https://twitter.com/{{task.asker}}'>{{task.asker}}</a></td>
            <td>{{task.state}}</td>
            <td>3000MONA</td>
            <td>{{task.created_time}}</td>
            <td><a href='https://twitter.com/{{task.worker}}'>{{task.worker}}</a></td>
          </tr>
        </tbody>
      </table>
  </div>
</div>
</div>
<hr class="featurette-divider">
</ng-container>
