<header>
  <div class="collapse bg-dark" id="navbarHeader">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 col-md-7 py-4">
          <h4 class="text-white" translate>このサイトについて</h4>
          <p class="text-white" translate>モナコイン界隈を少しでも盛り上げるためにサイトを立ち上げました。個人運営ですがモナコイン経済圏確立まではあきらめません。みんなで一緒にモナコインを盛り上げましょう</p>
          <p class="text-white" translate>現在、イラストファンディング　モナコイン のキーワードでしたら何とかGoogleからたどり着けるようです</p>
        </div>
        <div class="col-sm-4 offset-md-1 py-4">
          <h4 class="text-white">Contact</h4>
          <ul class="list-unstyled">
            <li><a href="https://twitter.com/RCloverforest" class="text-white">Follow on Twitter</a></li>
            <li translate>(DMなど特に制限はありません)</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="navbar navbar-dark bg-dark box-shadow">
    <div class="container d-flex justify-content-between">
      <a href="#" class="navbar-brand d-flex align-items-center">
        <strong translate>このサイトについて</strong>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </div>
</header>

<main role="main">
<!--
  <div class="container">
    <div class="row">
      <div class="col-10 mx-auto">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="d-block w-100" src="https://via.placeholder.com/800x400" alt="First slide">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="https://via.placeholder.com/800x400" alt="Second slide">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="https://via.placeholder.com/800x400" alt="Third slide">
        </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
        </a>
        </div>
      </div
    </div>
  </div>

  <section class="jumbotron text-center">
    <div class="container">
      <h1 class="jumbotron-heading">Album example</h1>
      <p class="lead text-muted">Something short and leading about the collection below?its contents, the creator, etc. Make it short and sweet, but not too short so folks don't simply skip over it entirely.</p>
      <p>
        <a href="#" class="btn btn-primary my-2">Main call to action</a>
        <a href="#" class="btn btn-secondary my-2">Secondary action</a>
      </p>
    </div>
  </section>
-->
  <div class="album py-5 bg-light">
    <div class="container">
      <div class="row">
        
        <div class="col-md-4">
          <div class="card mb-4 box-shadow">
            <img class="card-img-top" src="/assets/bloom-blossom-close-up-center.jpg" alt="モナコインによるクラウドファンディング">
            <div class="card-body">
              <p class="card-text"><a translate href="/monafunding">モナファンディング</a></p>
              <p translate class="card-text">モナコインによるイラストファンディングサイトです. お気に入りの画像にモナコインを投げましょう</p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button (click)="btnMonaFundingClick()" type="button" class="btn btn-sm btn-outline-secondary">Jump</button>
                </div>
                <small class="text-muted">9 mins</small>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card mb-4 box-shadow">
            <img class="card-img-top" src="/assets/bloom-blossom-close-up-center.jpg" alt="モナコインがもらえるクレーンゲームです">
            <div class="card-body">
              <p class="card-text"><a translate href="https://monafunding.com/wordpress/WebGL/index.html">モナクレーン</a></p>
              <p translate class="card-text">モナコインがもらえるクレーンゲームです</p>
              <p translate class="card-text">MQZo8zqpYLANuZjA9GzS4xpjMCfazZogGi</p>
              <p translate class="card-text">に寄付していただけると、景品残高が増えます</p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <a type="button" class="btn btn-sm btn-outline-secondary" href="https://monafunding.com/wordpress/WebGL/index.html">Jump</a>
                </div>
                <small class="text-muted">9 mins</small>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card mb-4 box-shadow">
            <img class="card-img-top" src="/assets/bloom-blossom-close-up-center.jpg" alt="モナコインがもらえるクレーンゲームです">
            <div class="card-body">
              <p class="card-text"><a translate href="/monaclicker">モナクリッカー</a></p>
              <p translate class="card-text">モナカードで遊べるクリッカーゲームです</p>
              <p translate class="card-text">現状はあまりクリックせず、放置系の味付けにしています</p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button (click)="btnMonaClickerClick()" type="button" class="btn btn-sm btn-outline-secondary">Jump</button>
                </div>
                <small class="text-muted">9 mins</small>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card mb-4 box-shadow">
            <img class="card-img-top" src="/assets/bloom-blossom-close-up-center.jpg" alt="モナコインチップサービス(tipmona)連携">
            <div class="card-body">
              <p class="card-text"><a translate href="/monabanking">モナチップサービス(tipmona連携)</a></p>
              <p translate class="card-text">twitter上のモナコインチップサービスを便利に使えるサービス。tipmona用のコマンドを生成します</p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button (click)="btnMonaBankingClick()" type="button" class="btn btn-sm btn-outline-secondary">Jump</button>
                </div>
                <small class="text-muted">9 mins</small>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card mb-4 box-shadow">
            <img class="card-img-top" src="/assets/bloom-blossom-close-up-center.jpg" alt="モナコインチップサービス(tipmona)連携">
            <div class="card-body">
              <p class="card-text"><a translate href="/monaocean">モナオーシャン(モナコイン界隈リンク集)</a></p>
              <p translate class="card-text">モナコイン情報は数多ありますが、検索するとブログ情報を中心に色々な情報が新旧まじっていますので、私の捉えているモナコイン界隈を表現してみました</p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button (click)="btnMonaOceanClick()" type="button" class="btn btn-sm btn-outline-secondary">Jump</button>
                </div>
                <small class="text-muted">9 mins</small>
              </div>
            </div>
          </div>
        </div>
      
        <div class="col-md-4">
          <div class="card mb-4 box-shadow">
            <img class="card-img-top" src="/assets/bloom-blossom-close-up-center.jpg" alt="モナコインのブログ">
            <div class="card-body">
              <p class="card-text"><a translate href="/wordpress">ルリエルのブログ</a></p>
              <p translate class="card-text">ひとまずブログ作成しましたので、置いておきます。まずは本サイトの理念などを掲載しています。</p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <a type="button" class="btn btn-sm btn-outline-secondary" href="https://monafunding.com/wordpress/">Jump</a>
                </div>
                <small class="text-muted">9 mins</small>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</main>
