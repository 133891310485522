import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core'
import { environment } from '../environments/environment'
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';

import { Title }     from '@angular/platform-browser'; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  select_lang = '';

  // 日本語の場合のみ日本語に翻訳する
  getBrowserLanguage(){
    // ブラウザの言語を取得
    var browserLanguage: string = (() => {
      if (navigator.languages.length > 0) {
        return navigator.languages[0];
      }
      if (navigator.language) {
        return navigator.language
      }
      return "en";
    })();

    console.log('browser language (RAW) ' + browserLanguage);

    // 日本語だったら日本語のみ.
    if (browserLanguage.match(/^ja$|^ja-/)) {
      browserLanguage = 'ja';
    }

    return browserLanguage;
  };

  constructor(private route: ActivatedRoute, private translate: TranslateService, private cookieService : CookieService, private title: Title) {
    console.log('Constructor');
    console.log('CN B InitLang ' + this.select_lang);
    console.log('CN C select_lang ' + this.cookieService.get('select_lang'));
    this.select_lang = this.cookieService.get('select_lang');

    if(this.select_lang != 'ja' && this.select_lang != 'en'){
      var browserLanguage = this.getBrowserLanguage();
      console.log('browser language ' + browserLanguage);

      this.select_lang = browserLanguage;
      this.cookieService.set('select_lang', this.select_lang, 1, '/');

      console.log('UD B InitLang ' + this.select_lang);
      console.log('UD C select_lang ' + this.cookieService.get('select_lang'));
    }

    this.translate.setDefaultLang(this.select_lang);
    this.translate.use(this.select_lang);
  }

  changeLang(){
    console.log('CA B ChangeLang ' + this.select_lang);
    console.log('CA C select_lang ' + this.cookieService.get('select_lang'));

    this.cookieService.set('select_lang', this.select_lang, 1, '/');
    console.log('C select_lang ' + this.cookieService.get('select_lang'));

    this.translate.setDefaultLang(this.select_lang);
    this.translate.use(this.select_lang);
  }

  ngOnInit() {
    console.log('ngOnInit');

    console.log('IN B InitLang ' + this.select_lang);
    console.log('IN C select_lang ' + this.cookieService.get('select_lang'));
    this.select_lang = this.cookieService.get('select_lang');

    this.translate.setDefaultLang(this.select_lang);
    this.translate.use(this.select_lang);
  }

}
