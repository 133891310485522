import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title }     from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-entrance',
  templateUrl: './entrance.component.html',
  styleUrls: ['./entrance.component.css']
})
export class EntranceComponent implements OnInit {
  select_lang = 'ja';

  constructor(private route: ActivatedRoute, private router: Router, private meta: Meta, private title: Title, private cookieService : CookieService, private translate: TranslateService) {
    console.log('Actual URL');
    var paths = this.router.url.split('/');
    console.log(this.router.url);
    console.log(paths);
  
    if(paths.length > 1 && paths[1].length > 0){
      console.log(paths[1]);
      this.cookieService.set('select_lang', paths[1], 1, '/');
    }

    console.log('Constructor ');
    console.log(this.cookieService.get('select_lang'));
    this.select_lang = this.cookieService.get('select_lang');
    this.translate.use(this.cookieService.get('select_lang'));

    if('ja' == this.select_lang){
      this.title.setTitle('モナシティ モナコイン経済圏樹立のためのWebサービス'); 

      var description = 'モナシティ モナコイン経済圏樹立のためのWebサービス。';
      description += 'ルリエルが運営するモナコイン関連のWebサービスポータルです';
      description += '天界も最近は不況のためルリエルは堕天して働くことになりました';
      description += 'モナファンディングではモナコインを利用して、イラストファンディングができます';
      description += 'イラストファンディングとは投稿者が登録した作品（アート/イラスト）に対してモナコインを寄付/支援することにより、アート/イラストが開示されていくWebサービス';
      description += '寄付方式：アート/イラストを公開してモナコインを寄付してもらう方式';
      description += '段階的ファンディング：不明瞭な状態でアート/イラストを投稿して、モナコインを受け取るごとに画像が鮮明になっていく方式';
      description += '通常のファンディング：不明瞭な状態でアート/イラストを投稿して、モナコインが一定の目標額に達すると、アート/イラストが公開される方式';
      description += '他にも短い漫画や小説などでしたら、少しずつ限定公開条件で登録していけると思います';
      description += 'ルリエルともどもイラストファンディングをよろしくお願いします';
  
      this.meta.addTag({name: 'description', content: description}, true); 
    }
    else{
      this.title.setTitle('Monacity A web service to establish Monacoin economy'); 

      var description = 'Monacity Monacoin Web service for establishing the economic zone.';
      description += 'Web service owner is rurieru(Rurieru).';
      description += 'The heavenly world has recently been depressed due to the depression, So Rurieru need to work';
      description += 'Illustfunding(Illustration Funding) is a web service in which art / illustration is disclosed by donating / supporting monacoin against works (art / illustration) registered by a contributor';
      description += 'Donation method: A method of having art / illustration released and donating Monacoin';
      description += 'Step-by-step funding: A method in which art / illustration is submitted in an unclear state and the image becomes clearer as each monacoin is received';
      description += 'Normal Funding: Posting art / illustration in an unclear state, when artist / illustration is disclosed when monacoin reaches a certain target amount';
      description += 'If you have other short comic books or novels, I think that you can register it on a limited basis on a gradual basis';
      description += 'I would like to thank Ruriel and Illustration Funding';
  
      this.meta.addTag({name: 'description', content: description}, true); 
    }

  }
  onEvents() {
  }
  ngOnInit() {

  }

  btnMonaFundingClick = function () {
    this.router.navigateByUrl('/monafunding');
  };

  btnMonaBankingClick = function () {
    this.router.navigateByUrl('/monabanking');
  };

  btnMonaOceanClick = function () {
    this.router.navigateByUrl('/monaocean');
  };

  btnMonaClaneClick = function () {
    this.document.location.href = 'https://monafunding.com/wordpress/WebGL/index.html';
  };

  btnMonaClickerClick = function () {
    this.router.navigateByUrl('/monaclicker');
  };
}
