import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { EntranceComponent } from './entrance/entrance.component';

import { MonafundingTopComponent } from './monafunding-top/monafunding-top.component';
import { MonafundingRegisterAlbumComponent } from './monafunding-register-album/monafunding-register-album.component';
import { MonafundingAuthenticateComponent } from './monafunding-authenticate/monafunding-authenticate.component';
import { MonafundingDonationComponent } from './monafunding-donation/monafunding-donation.component';
import { MonafundingHelpComponent } from './monafunding-help/monafunding-help.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';


import { FormsModule }   from '@angular/forms';

import {MonafundingService} from './monafunding.service';
import { CookieService } from 'ngx-cookie-service';

import { LightboxModule } from 'ngx-lightbox';
import { MonafundingHelpElectrumComponent } from './monafunding-help-electrum/monafunding-help-electrum.component';
import { MonabankingComponent } from './monabanking/monabanking.component';
import { MonaoceanComponent } from './monaocean/monaocean.component';
import { GreatpaintersComponent } from './greatpainters/greatpainters.component';
import { FormMessageModalComponent } from './form-message-modal/form-message-modal.component';
import { TweettoolComponent } from './tweettool/tweettool.component';
import { MonafundingRequestComponent } from './monafunding-request/monafunding-request.component';
import { MonafundingAskComponent } from './monafunding-ask/monafunding-ask.component';
import { MonafundingAskDetailComponent } from './monafunding-ask-detail/monafunding-ask-detail.component';
import { MonaclickerComponent } from './monaclicker/monaclicker.component';
import { MonaclickerRankingComponent } from './monaclicker-ranking/monaclicker-ranking.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    EntranceComponent,
    MonafundingTopComponent,
    MonafundingRegisterAlbumComponent,
    MonafundingAuthenticateComponent,
    MonafundingHelpComponent,
    MonafundingDonationComponent,
    MonafundingHelpElectrumComponent,
    MonabankingComponent,
    MonaoceanComponent,
    GreatpaintersComponent,
    FormMessageModalComponent,
    TweettoolComponent,
    MonafundingRequestComponent,
    MonafundingAskComponent,
    MonafundingAskDetailComponent,
    MonaclickerComponent,
    MonaclickerRankingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    LightboxModule,
    NgbModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide : TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [MonafundingService,CookieService,Title,Meta],
  bootstrap: [AppComponent],
  entryComponents: [
    FormMessageModalComponent
  ]
})

export class AppModule {
}
