import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core'

import { Title }     from '@angular/platform-browser'; 

@Component({
  selector: 'app-monafunding-help',
  templateUrl: './monafunding-help.component.html',
  styleUrls: ['./monafunding-help.component.css'],
  providers: [ Title ]  // ［1］Titleサービスを登録 
})
export class MonafundingHelpComponent implements OnInit {

  constructor(private translate: TranslateService, private cookieService : CookieService, private title: Title) {
    this.title.setTitle('モナファンディング モナコインで(イラスト)クラウドファンディング 規約/ウォレットの取り扱い/利用方法/モナコインについて一般'); 
  }

  ngOnInit() {
    console.log('InitLang ');
    console.log(this.cookieService.get('select_lang'));
    this.translate.use(this.cookieService.get('select_lang'));
  }

}
