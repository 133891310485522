import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';



//declare function getAddress(): any;
//import * as mpurse from 'src/assets/mpurse.js';
//import * as mt from 'src/assets/mt.js';
declare function getBalance();
declare function getAddress();
declare function getRandomValue(seed, count);

class AssetInfo {
  coin_address: string;
  nick_name: string;
  total: number; 
  total_delta: number; 
  total_value: number;
  total_rank: number;
  fire: number;
  water: number;
  light: number;
  dark: number;
  gold: number;
  fantom: number;
}

class MarketInfo {
  fire_ratio: number;
  water_ratio: number;
  light_ratio: number;
  dark_ratio: number;
  gold_ratio: number;
  fantom_ratio: number;
}

class CardInfo {
  card_identifer: string;
  fire: number;
  water: number;
  light: number;
  dark: number;
  gold: number;
  fantom: number;
}

@Component({
  selector: 'app-monaclicker',
  templateUrl: './monaclicker.component.html',
  styleUrls: ['./monaclicker.component.css']
})
export class MonaclickerComponent implements OnInit {
  initialized : boolean;

  tickcount : number = 0;

  total_asset_info : AssetInfo;
  selected_card : number;
  selected_cards_image : string;
  selected_cards_info : CardInfo;

  market_info : MarketInfo;

  stored_cards : Array<string> = Array(6);
  stored_cards_image : Array<string> = Array(6);
  stored_cards_info : Array<CardInfo> = Array(6);

  stored_cards_lucky : Array<number> = Array(3);
  stored_cards_nerf : Array<number> = Array(3);

  country : any;
  
  nurf_card_1 : any;
  nurf_card_2 : any;
  nurf_card_3 : any;
  nurf_info : any

  assets : Array<string> = new Array(0);
  assets_list : Array<string> = new Array(0);

  limit_date : Date;
  limit_date_string : string;
  limit_hour : number;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.initialized = false;

    this.stored_cards_image[0] = "https://placehold.jp/560x800.png";
    this.stored_cards_image[1] = "https://placehold.jp/560x800.png";
    this.stored_cards_image[2] = "https://placehold.jp/560x800.png";
    this.stored_cards_image[3] = "https://placehold.jp/560x800.png";
    this.stored_cards_image[4] = "https://placehold.jp/560x800.png";
    this.stored_cards_image[5] = "https://placehold.jp/560x800.png";

    this.selected_cards_image = "https://placehold.jp/560x800.png";

    this.total_asset_info = new AssetInfo();
    this.selected_cards_info = new CardInfo();
    this.market_info = new MarketInfo();

    this.stored_cards_info[0] = new CardInfo();
    this.stored_cards_info[1] = new CardInfo();
    this.stored_cards_info[2] = new CardInfo();
    this.stored_cards_info[3] = new CardInfo();
    this.stored_cards_info[4] = new CardInfo();
    this.stored_cards_info[5] = new CardInfo();
    
    this.stored_cards_lucky[0] = 0;
    this.stored_cards_lucky[1] = 0;
    this.stored_cards_lucky[2] = 0;

    this.stored_cards_nerf[0] = 1;
    this.stored_cards_nerf[1] = 1;
    this.stored_cards_nerf[2] = 1;

    this.total_asset_info.nick_name = "";
    this.total_asset_info.total = 0;
    this.total_asset_info.total_delta = 0;
    this.total_asset_info.total_value = 0;
    this.total_asset_info.total_rank = 1;

    this.total_asset_info.fire = 0;
    this.total_asset_info.water = 0;
    this.total_asset_info.light = 0;
    this.total_asset_info.dark = 0;
    this.total_asset_info.gold = 0;
    this.total_asset_info.fantom = 0;

    this.market_info.fire_ratio = 1;
    this.market_info.water_ratio = 1;
    this.market_info.light_ratio = 1;
    this.market_info.dark_ratio = 1;
    this.market_info.gold_ratio = 1;
    this.market_info.fantom_ratio = 1;

    this.limit_date = new Date(2020, 2, 1, 16, 0, 0, 0);
    this.limit_date_string = this.limit_date.toLocaleString();
    var now_date = new Date();
    this.limit_hour = Math.floor((this.limit_date.getTime() - now_date.getTime()) / 36000) / 100;
  }

  btnGetAddress = async function () {
    this.total_asset_info.coin_address = await getAddress();
    let balance : any = await getBalance();

    console.log(balance);
    for(var data of balance.data){
      console.log(data.asset);
      console.log(data.asset_longname);

      if(data.asset_longname == null){
        this.assets.push(data.asset);
      }
      else{
        this.assets.push(data.asset_longname);
      }
      console.log(this.assets);
    }

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Access-Control-Allow-Origin', '*');
    const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');

    this.http.get('https://card.mona.jp/api/card_list', {headers, withCredentials: false}).subscribe(
      (res : Response) => {
        let response : any = res;
  
        console.log("POST Request is successful (user) ", response);

        for(var asset of response.list){
          this.assets_list.push(asset);
        }
        this.assets_list.sort();

        
        this.initialized = true;

        this.onDownload();

        setInterval(()=>{
          this.timerLoop();
        },5000);
      }
      ,
      err => {
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);
      }
    )
  };

  btnMonaCardSelect = function (selected : number) {
    this.selected_card = selected;
    this.selected_cards_image = this.stored_cards_image[this.selected_card - 1];
    this.selected_cards_info = this.stored_cards_info[this.selected_card - 1];
  };

  btnMonaCardLucky = function (selected : number) {
    console.log("feeling lucky");
    this.stored_cards_lucky[(selected - 1)/2] -= 1000;
    this.stored_cards_lucky[(selected - 1)/2] = this.stored_cards_lucky[(selected - 1)/2] / 2;

    this.total_asset_info.fire += Math.floor(200 * this.calculateFire(this.stored_cards_info[selected - 1].fire, this.stored_cards_info[selected])*(0.2 + this.stored_cards_nerf[(selected - 1)/2]));
    this.total_asset_info.water += Math.floor(200 * this.calculateWater(this.stored_cards_info[selected - 1].water, this.stored_cards_info[selected])*(0.2 + this.stored_cards_nerf[(selected - 1)/2]));
    this.total_asset_info.light += Math.floor(200 * this.calculateLight(this.stored_cards_info[selected - 1].light, this.stored_cards_info[selected])*(0.2 + this.stored_cards_nerf[(selected - 1)/2]));
    this.total_asset_info.dark += Math.floor(200 * this.calculateDark(this.stored_cards_info[selected - 1].dark, this.stored_cards_info[selected])*(0.2 + this.stored_cards_nerf[(selected - 1)/2]));
    this.total_asset_info.gold += Math.floor(200 * this.calculateGold(this.stored_cards_info[selected - 1].gold, this.stored_cards_info[selected])*(0.2 + this.stored_cards_nerf[(selected - 1)/2]));
    this.total_asset_info.fantom += Math.floor(200 * this.calculateFantom(this.stored_cards_info[selected - 1].fantom, this.stored_cards_info[selected ])*(0.2 + this.stored_cards_nerf[(selected - 1)/2]));

    this.total_asset_info.total = 0;
    this.total_asset_info.total += this.total_asset_info.fire;
    this.total_asset_info.total += this.total_asset_info.water;
    this.total_asset_info.total += this.total_asset_info.light;
    this.total_asset_info.total += this.total_asset_info.dark;
    this.total_asset_info.total += this.total_asset_info.gold;
    this.total_asset_info.total += this.total_asset_info.fantom;

    this.total_asset_info.total_value = 0;
    this.total_asset_info.total_value += (this.total_asset_info.fire * this.market_info.fire_ratio);
    this.total_asset_info.total_value += (this.total_asset_info.water * this.market_info.water_ratio);
    this.total_asset_info.total_value += (this.total_asset_info.light * this.market_info.light_ratio);
    this.total_asset_info.total_value += (this.total_asset_info.dark * this.market_info.dark_ratio);
    this.total_asset_info.total_value += (this.total_asset_info.gold * this.market_info.gold_ratio);
    this.total_asset_info.total_value += (this.total_asset_info.fantom * this.market_info.fantom_ratio);
    this.total_asset_info.total_value = Math.floor(this.total_asset_info.total_value);

    console.log(this.total_asset_info);

  };

  btnMonaCardStore = function () {
    console.log(this.selected_card);
    console.log(this.country);

    this.cardStore(this.selected_card, this.country);
  };

  cardStore = function (selected_card, load_asset) {

    if(selected_card >= 1 && undefined != load_asset){
      this.stored_cards[selected_card - 1] = load_asset;

      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
      headers.append('Access-Control-Allow-Origin', '*');
      const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');

      this.http.get('https://card.mona.jp/api/card_detail?assets=' + load_asset, {headers, withCredentials: false}).subscribe(
        (res : Response) => {
          let response : any = res;
    
          console.log("POST Request is successful (user) ", response);
          console.log("POST Request is successful (user) ", response.details[0].imgur_url);

          this.stored_cards_image[selected_card - 1] = response.details[0].imgur_url;
          this.selected_cards_image = this.stored_cards_image[selected_card - 1];

          console.log("POST Request is successful (user) ", response.details[0].regist_time);

          
          this.stored_cards_info[selected_card - 1].card_identifer = load_asset;
          this.stored_cards_info[selected_card - 1].fire = Math.floor(1 + getRandomValue(Number(response.details[0].regist_time),1) * 16);
          this.stored_cards_info[selected_card - 1].water = Math.floor(1 + getRandomValue(Number(response.details[0].regist_time),2) * 16);
          this.stored_cards_info[selected_card - 1].light = Math.floor(1 + getRandomValue(Number(response.details[0].regist_time),3) * 16); 
          this.stored_cards_info[selected_card - 1].dark = Math.floor(1 + getRandomValue(Number(response.details[0].regist_time),4) * 16); 
          this.stored_cards_info[selected_card - 1].gold = Math.floor(1 + getRandomValue(Number(response.details[0].regist_time),5) * 16);
          this.stored_cards_info[selected_card - 1].fantom = Math.floor(1 + getRandomValue(Number(response.details[0].regist_time),6) * 16);
          this.selected_cards_info = this.stored_cards_info[selected_card - 1];

          console.log("POST Request is successful (user) ", this.stored_cards_info[selected_card - 1]);

          for(var i = 1; i <= 3;i++){
            if(undefined != this.stored_cards_info[(i - 1)*2]){
              if(undefined != this.nurf_info[this.stored_cards_info[(i - 1)*2]]){
                if(undefined != this.stored_cards_info[(i - 1)*2].card_identifer){
                  if(undefined != this.nurf_info[this.stored_cards_info[(i - 1)*2].card_identifer]){
                    this.stored_cards_nerf[i - 1] = Math.floor((10000 - this.nurf_info[this.stored_cards_info[(i - 1)*2].card_identifer])/100) / 100;
                  }
                }
              }
            }
          }
        }
        ,
        err => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
        }
      )
    }
  };

  calculateFire = function (value : number, catalyst : CardInfo) {
    var result_value : number;

    result_value = 0;

    if(value > 0) result_value += value;
    if(catalyst.fire > 0) result_value = result_value * (catalyst.fire / 4);
    if(catalyst.water > 0) result_value = result_value / ((2 + catalyst.water) / 4);
    if(catalyst.light > 0) result_value = result_value + (catalyst.light * 2);
    if(catalyst.dark > 0) result_value = result_value - (catalyst.dark * 2);
    //if(catalyst.gold > 0) result_value = result_value + (catalyst.gold * 2);
    //if(catalyst.fantom > 0) result_value = result_value - (catalyst.fantom * 2);

    result_value = Math.floor(result_value);
    if(result_value < 0) result_value = 0;

    return result_value;
  }

  calculateWater = function (value : number, catalyst : CardInfo) {
    var result_value : number;

    result_value = 0;

    if(value > 0) result_value += value;
    if(catalyst.fire > 0) result_value = result_value / ((2 + catalyst.fire) / 4);
    if(catalyst.water > 0) result_value = result_value * (catalyst.water / 4);
    if(catalyst.light > 0) result_value = result_value - (catalyst.light * 2);
    if(catalyst.dark > 0) result_value = result_value + (catalyst.dark * 2);
    //if(catalyst.gold > 0) result_value = result_value + (catalyst.gold * 2);
    //if(catalyst.fantom > 0) result_value = result_value - (catalyst.fantom * 2);

    result_value = Math.floor(result_value);
    if(result_value < 0) result_value = 0;

    return result_value;
  }

  calculateLight = function (value : number, catalyst : CardInfo) {
    var result_value : number;

    result_value = 0;

    if(value > 0) result_value += value;
    if(catalyst.fire > 0) result_value = result_value + (catalyst.fire * 2);
    if(catalyst.water > 0) result_value = result_value + (catalyst.water * 2);
    if(catalyst.light > 0) result_value = result_value + (catalyst.light * 2);
    if(catalyst.dark > 0) result_value = result_value - (catalyst.dark * 8);
    if(catalyst.gold > 0) result_value = result_value + (catalyst.gold * 2);
    //if(catalyst.fantom > 0) result_value = result_value - (catalyst.fantom * 2);

    result_value = Math.floor(result_value);
    if(result_value < 0) result_value = 0;

    return result_value;
  }

  calculateDark = function (value : number, catalyst : CardInfo) {
    var result_value : number;

    result_value = 0;

    if(value > 0) result_value += value;
    if(catalyst.fire > 0) result_value = result_value - (catalyst.fire * 2);
    if(catalyst.water > 0) result_value = result_value - (catalyst.water * 2);
    if(catalyst.light > 0) result_value = result_value - (catalyst.light * 2);
    if(catalyst.dark > 0) result_value = result_value + (catalyst.dark * 8);
    if(catalyst.gold > 0) result_value = result_value - (catalyst.gold * 2);
    //if(catalyst.fantom > 0) result_value = result_value - (catalyst.fantom * 2);

    result_value = Math.floor(result_value);
    if(result_value < 0) result_value = 0;

    return result_value;
  }

  calculateGold = function (value : number, catalyst : CardInfo) {
    var result_value : number;

    result_value = 0;

    if(value > 0) result_value += value;
    if(catalyst.fire > 0) result_value = result_value * (catalyst.fire / 8);
    if(catalyst.water > 0) result_value = result_value * (catalyst.water / 8);
    if(catalyst.light > 0) result_value = result_value * (catalyst.light / 8);
    if(catalyst.dark > 0) result_value = result_value * (catalyst.dark / 8);
    if(catalyst.gold > 0) result_value = result_value * (catalyst.gold / 4);
    if(catalyst.fantom > 0) result_value = result_value / catalyst.fantom;

    result_value = Math.floor(result_value);
    if(result_value < 0) result_value = 0;

    return result_value;
  }

  calculateFantom = function (value : number, catalyst : CardInfo) {
    var result_value : number;

    result_value = 0;

    if(value > 0) result_value += value;
    if(catalyst.fire > 0) result_value = result_value * (catalyst.fire / 8);
    if(catalyst.water > 0) result_value = result_value * (catalyst.water / 8);
    if(catalyst.light > 0) result_value = result_value * (catalyst.light / 8);
    if(catalyst.dark > 0) result_value = result_value * (catalyst.dark / 8);
    if(catalyst.fantom > 0) result_value = result_value * (catalyst.fantom / 4);
    if(catalyst.gold > 0) result_value = result_value / catalyst.gold;

    result_value = Math.floor(result_value);
    if(result_value < 0) result_value = 0;

    return result_value;
  }

  calculateLuckey = function (value : number, catalyst : CardInfo) {
    var result_value : number;

    result_value = 0;

    if(catalyst.light > 0) result_value = result_value + catalyst.light;
    if(catalyst.gold > 0) {
      if(getRandomValue(this.total_asset_info.total,0) > 9.999){
        result_value = result_value + 10000;
      }
    }
    if(catalyst.gold > 4) {
      if(getRandomValue(this.total_asset_info.total,0) > 9.999){
        result_value = result_value + 10000;
      }
    }
    if(catalyst.gold > 8) {
      if(getRandomValue(this.total_asset_info.total,0) > 9.999){
        result_value = result_value + 10000;
      }
    }
    if(catalyst.gold > 12) {
      if(getRandomValue(this.total_asset_info.total,0) > 9.999){
        result_value = result_value + 10000;
      }
    }
    if(catalyst.gold > 14) {
      if(getRandomValue(this.total_asset_info.total,0) > 9.9999){
        result_value = result_value + 250000;
      }
    }
    if(catalyst.gold > 15) {
      if(getRandomValue(this.total_asset_info.total,0) > 9.9999){
        result_value = result_value + 1000000;
      }
    }

    result_value = Math.floor(result_value);
    if(result_value < 0) result_value = 0;

    return result_value;
  }

  timerLoop = function () {
    console.log("timeout");

    for(var i = 0; i < 3; i++){
      this.total_asset_info.fire += Math.floor(this.calculateFire(this.stored_cards_info[i*2].fire, this.stored_cards_info[i*2 + 1])*this.stored_cards_nerf[i]);
      this.total_asset_info.water += Math.floor(this.calculateWater(this.stored_cards_info[i*2].water, this.stored_cards_info[i*2 + 1])*this.stored_cards_nerf[i]);
      this.total_asset_info.light += Math.floor(this.calculateLight(this.stored_cards_info[i*2].light, this.stored_cards_info[i*2 + 1])*this.stored_cards_nerf[i]);
      this.total_asset_info.dark += Math.floor(this.calculateDark(this.stored_cards_info[i*2].dark, this.stored_cards_info[i*2 + 1])*this.stored_cards_nerf[i]);
      this.total_asset_info.gold += Math.floor(this.calculateGold(this.stored_cards_info[i*2].gold, this.stored_cards_info[i*2 + 1])*this.stored_cards_nerf[i]);
      this.total_asset_info.fantom += Math.floor(this.calculateFantom(this.stored_cards_info[i*2].fantom, this.stored_cards_info[i*2 + 1])*this.stored_cards_nerf[i]);
      this.stored_cards_lucky[i] += Math.floor(this.calculateLuckey(this.stored_cards_lucky[i], this.stored_cards_info[i*2 + 1])*this.stored_cards_nerf[i]);
    }
    console.log(this.stored_cards_lucky);

    this.total_asset_info.total_delta = this.total_asset_info.total;
    this.total_asset_info.total = 0;
    this.total_asset_info.total += this.total_asset_info.fire;
    this.total_asset_info.total += this.total_asset_info.water;
    this.total_asset_info.total += this.total_asset_info.light;
    this.total_asset_info.total += this.total_asset_info.dark;
    this.total_asset_info.total += this.total_asset_info.gold;
    this.total_asset_info.total += this.total_asset_info.fantom;
    this.total_asset_info.total_delta = this.total_asset_info.total - this.total_asset_info.total_delta;

    this.total_asset_info.total_value = 0;
    this.total_asset_info.total_value += (this.total_asset_info.fire * this.market_info.fire_ratio);
    this.total_asset_info.total_value += (this.total_asset_info.water * this.market_info.water_ratio);
    this.total_asset_info.total_value += (this.total_asset_info.light * this.market_info.light_ratio);
    this.total_asset_info.total_value += (this.total_asset_info.dark * this.market_info.dark_ratio);
    this.total_asset_info.total_value += (this.total_asset_info.gold * this.market_info.gold_ratio);
    this.total_asset_info.total_value += (this.total_asset_info.fantom * this.market_info.fantom_ratio);
    this.total_asset_info.total_value = Math.floor(this.total_asset_info.total_value);


    console.log(this.total_asset_info);

    this.onUpload();

    this.tickcount++;

    if(0 == this.tickcount % 12){
      this.onUpdate();
    }

    var now_date = new Date();
    this.limit_hour = Math.floor((this.limit_date.getTime() - now_date.getTime()) / 36000) / 100;
  };

  onUpload = function () {
    console.log('onUpload');
  
    const uploadData = new FormData();
    // AlbumData begin.
    uploadData.append('coin_address', this.total_asset_info.coin_address);
    uploadData.append('nick_name', this.total_asset_info.nick_name);
    uploadData.append('asset_total', this.total_asset_info.total);
    uploadData.append('asset_total_value', (this.total_asset_info.total_value) + "");
    uploadData.append('asset_fire', this.total_asset_info.fire);
    uploadData.append('asset_water', this.total_asset_info.water);
    uploadData.append('asset_light', this.total_asset_info.light);
    uploadData.append('asset_dark', this.total_asset_info.dark);
    uploadData.append('asset_gold', this.total_asset_info.gold);
    uploadData.append('asset_fantom', this.total_asset_info.fantom);

    uploadData.append('card_info_1', this.stored_cards_info[0].card_identifer);
    uploadData.append('card_info_2', this.stored_cards_info[1].card_identifer);
    uploadData.append('card_info_3', this.stored_cards_info[2].card_identifer);
    uploadData.append('card_info_4', this.stored_cards_info[3].card_identifer);
    uploadData.append('card_info_5', this.stored_cards_info[4].card_identifer);
    uploadData.append('card_info_6', this.stored_cards_info[5].card_identifer);
    
    uploadData.append('nurf_card_1', this.nurf_card_1);
    uploadData.append('nurf_card_2', this.nurf_card_2);
    uploadData.append('nurf_card_3', this.nurf_card_3);

    var curse_val : number = 0;
    for(var i = 0; i < 3; i++){
      if(this.stored_cards_info[i*2+1].dark) curse_val += this.stored_cards_info[i*2+1].dark;
      if(this.stored_cards_info[i*2+1].fantom) curse_val += this.stored_cards_info[i*2+1].fantom * 4;
    }

    uploadData.append('curse_val', '' + curse_val);

    class CardInfo {
      card_identifer: string;
      fire: number;
      water: number;
      light: number;
      dark: number;
      gold: number;
      fantom: number;
    }
    const headers = new HttpHeaders();
    // headers.append('Content-Type', 'text/plain');
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Content-Type', 'multipart/form-data');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'multipart/form-data');
    this.http.post('https://back.monafunding.com/monaclick/playerdata', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response:any = res;
          console.log("POST Request is successful ", res);
        },
        err => {
          console.log("POST Request has a error ");
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);

        }
    )
  }

  onDownload = function () {
    console.log('onDownload');
  
    const uploadData = new FormData();
    // AlbumData begin.
    uploadData.append('coin_address', this.total_asset_info.coin_address);

    const headers = new HttpHeaders();
    // headers.append('Content-Type', 'text/plain');
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');`
    headers.append('Content-Type', 'multipart/form-data');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'multipart/form-data');
    this.http.post('https://back.monafunding.com/monaclick/gamedata', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response:any = res;

          this.cardStore(1, response.card_info_1);
          this.cardStore(2, response.card_info_2);
          this.cardStore(3, response.card_info_3);
          this.cardStore(4, response.card_info_4);
          this.cardStore(5, response.card_info_5);
          this.cardStore(6, response.card_info_6);

          this.total_asset_info.coin_address = response.coin_address;
          this.total_asset_info.nick_name = response.nick_name;
          this.total_asset_info.total = Number(response.asset_total);
          this.total_asset_info.total_value = Number(response.asset_total_value);
          this.total_asset_info.fire = Number(response.asset_fire);
          this.total_asset_info.water = Number(response.asset_water);
          this.total_asset_info.light = Number(response.asset_light);
          this.total_asset_info.dark = Number(response.asset_dark);
          this.total_asset_info.gold = Number(response.asset_gold);
          this.total_asset_info.fantom = Number(response.asset_fantom);
      
          this.nurf_card_1 = response.nurf_card_1;
          this.nurf_card_2 = response.nurf_card_2;
          this.nurf_card_3 = response.nurf_card_3;

          console.log("POST Request is successful ", response);
          console.log("POST Request is successful ", response.card_info_1);
          console.log("POST Request is successful ", response.card_info_2);

          this.onUpdate();
        },
        err => {
          console.log("POST Request has a error ");
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);

        }
    )

  }

  onUpdate = function () {
    console.log('onUpdate');

    this.onUpdateMarket();
    this.onUpdateNerf();
    this.onUpdateRank();
    this.onUpdateLimit();
  }

  onUpdateMarket = function () {
    console.log('onUpdateMarket');
  
    const uploadData = new FormData();
    // AlbumData begin.
    uploadData.append('coin_address', this.total_asset_info.coin_address);

    const headers = new HttpHeaders();
    // headers.append('Content-Type', 'text/plain');
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');`
    headers.append('Content-Type', 'multipart/form-data');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'multipart/form-data');
    this.http.post('https://back.monafunding.com/monaclick/marketdata', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response:any = res;

          console.log("POST Request is successful ", response);

          this.market_info.fire_ratio = Math.floor(Number(response.ratio_fire)*1000) / 1000;
          this.market_info.water_ratio = Math.floor(Number(response.ratio_water)*1000) / 1000;
          this.market_info.light_ratio = Math.floor(Number(response.ratio_light)*1000) / 1000;
          this.market_info.dark_ratio = Math.floor(Number(response.ratio_dark)*1000) / 1000;
          this.market_info.gold_ratio = Math.floor(Number(response.ratio_gold)*1000) / 1000;
          this.market_info.fantom_ratio = Math.floor(Number(response.ratio_fantom)*1000) / 1000;
        },
        err => {
          console.log("POST Request has a error ");
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);

        }
    )
  }

  onUpdateNerf = function () {
    console.log('onUpdateNerf');
  
    const uploadData = new FormData();
    // AlbumData begin.
    uploadData.append('coin_address', this.total_asset_info.coin_address);

    const headers = new HttpHeaders();
    // headers.append('Content-Type', 'text/plain');
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');`
    headers.append('Content-Type', 'multipart/form-data');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'multipart/form-data');
    this.http.post('https://back.monafunding.com/monaclick/nerfdata', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          this.nurf_info = res;

          console.log("POST Request is successful ", this.nurf_info);
          console.log("POST Request is successful ", this.nurf_info['DENDROMONA.MPTYPE']);
          console.log("POST Request is successful ", this.nurf_info['XXXXXXXXXXXXX']);

          for(var i = 1; i <=3;i++){
            console.log("NERF ", this.stored_cards_info[(i - 1)*2].card_identifer);
            console.log("NERF ", this.nurf_info[this.stored_cards_info[(i - 1)*2].card_identifer]);
            if(undefined != this.stored_cards_info[(i - 1)*2].card_identifer){
              if(undefined != this.nurf_info[this.stored_cards_info[(i - 1)*2].card_identifer])
              this.stored_cards_nerf[i - 1] = Math.floor((10000 - this.nurf_info[this.stored_cards_info[(i - 1)*2].card_identifer])/100) / 100;
            }
          }
        },
        err => {
          console.log("POST Request has a error ");
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);

        }
    )
  }

  onUpdateRank = function () {
    console.log('onUpdateRank');
  
    const uploadData = new FormData();
    // AlbumData begin.
    uploadData.append('coin_address', this.total_asset_info.coin_address);
    uploadData.append('asset_total_value', '' + (Number(this.total_asset_info.total_value)));

    const headers = new HttpHeaders();
    // headers.append('Content-Type', 'text/plain');
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');`
    headers.append('Content-Type', 'multipart/form-data');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'multipart/form-data');
    this.http.post('https://back.monafunding.com/monaclick/checkrank', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response : any = res;
          this.total_asset_info.total_rank = Number(response);
          console.log('Rank', this.total_asset_info.total_rank);
        },
        err => {
          console.log("POST Request has a error ");
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);

        }
    )
  }

  onUpdateLimit = function () {
    console.log('onUpdateLimit');
  
    const uploadData = new FormData();
    // AlbumData begin.
    uploadData.append('coin_address', this.total_asset_info.coin_address);
    uploadData.append('asset_total_value', '' + (Number(this.total_asset_info.total_value)));

    const headers = new HttpHeaders();
    // headers.append('Content-Type', 'text/plain');
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');`
    headers.append('Content-Type', 'multipart/form-data');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'multipart/form-data');
    this.http.post('https://back.monafunding.com/monaclick/checklimit', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response : any = res;
          console.log('Limit Date', response);
          this.limit_date = new Date(response);
          this.limit_date_string = this.limit_date.toLocaleString();
          var now_date = new Date();
          this.limit_hour = Math.floor((this.limit_date.getTime() - now_date.getTime()) / 36000) / 100;
        },
        err => {
          console.log("POST Request has a error ");
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);

        }
    )
  }

  onOptionsSelected(value:string){
    console.log("the selected value is " + value);
  }
}

