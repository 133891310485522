<div class="container toptitl">

<hr class="featurette-divider">
<h3 translate>あなたのとっておきのアートワークを登録しましょう</h3>
<hr class="featurette-divider">
    

<div class="row">
<div class="col-md-1">
</div>
<div class="col-md-10">
<form name="album_form" #album_form_="ngForm">
    <h3 translate>必須情報</h3>
    <div class="form-group" name="formgroup"> <!-- Album Name -->
    <div class="input-group-prepend">
      <span translate class="input-group-text" id="basic-textarea">アートワーク名称</span>
      <input required minlength="4" maxlength="30" type="text" class="form-control" id="full_name_id" name="album_name" [(ngModel)]="album_name" #album_name_="ngModel" placeholder="Your Art Work Name">
    </div>
    <span translate *ngIf="album_name_.errors?.required" style="color:red;">名前は必須です</span>
    <span translate *ngIf="album_name_.errors?.minlength" style="color:red;">名前は4文字以上で入力してください</span>
    <span translate *ngIf="album_name_.errors?.maxlength" style="color:red;">名前は30文字以内で入力してください</span>
    </div>
  
    <div class="form-group"> <!-- Monacoin Address -->
      <div class="input-group-prepend">
      <span translate class="input-group-text" id="basic-textarea">支援用モナコインアドレス</span>
      <input maxlength="34" minlength="34" type="text" class="form-control" id="city_id" name="mona_adder" [(ngModel)]="mona_adder" #mona_adder_="ngModel" placeholder="Monacoin Address for DONATION">
      </div>
      <span translate *ngIf="mona_adder_.errors?.required" style="color:red;">モナコインアドレスは必須です</span>
      <span translate *ngIf="mona_adder_.errors?.minlength" style="color:red;">モナコインアドレスは34文字です</span>
      <h5 translate>※必ず未使用の専用のモナコインアドレスを生成して割り当ててください</h5>
      <h5 translate>
      <a [routerLink]="['/monafunding/help-electrum']">
      Electrum-mona
      </a>
      で複数のアドレスをまとめて生成できます。
      </h5>
      <div class="form-check">
        <input (change)="onChangeChecks($event)" class="form-check-input" type="checkbox" value="" name="extention_donate_to_web_master" id="defaultCheck1" [(ngModel)]="extention_donate_to_web_master">
        <label translate class="form-check-label" for="defaultCheck1">
        受け取らずに運営者に寄付(事前に運営者から報酬を受け取っているときなど)
        </label>
      </div>									
    </div>									
                
    <div class="form-group"> <!-- Type Button -->
      <div class="input-group-prepend">
      <span translate class="input-group-text" id="basic-textarea">支援タイプ(寄付方式 / ファンディング方式)</span>
      <select required class="form-control" id="state_id" name="support_type" [(ngModel)]="support_type" #support_type_="ngModel">
        <option translate id="寄付方式" value="寄付方式">寄付方式</option>
        <option translate id="ファンディング方式" value="ファンディング方式">ファンディング方式</option>
        <option translate id="段階的ファンディング方式" value="段階的ファンディング方式">段階的ファンディング方式</option>
      </select>
      </div>
      <span translate *ngIf="support_type_.errors?.required">必須です。</span>					
      <h5 translate>寄付方式： アートワーク（画像）を公開して、寄付を募る方式</h5>
      <h5 translate>ファンディング方式： 目標額達成で公開される方式、公開されるまではブラー(ぼかし)が最大で表示されます</h5>
      <h5 translate>段階的ファンディング方式： 目標額に近づくたびにブラー(ぼかし)が解除されていく方式</h5>
    </div>

    <div class="form-group"> <!-- Monacoin for Supporter -->
      <div class="input-group-prepend">
      <span translate class="input-group-text" id="basic-textarea">支援者と認定されるために必要なMONA</span>
      <input required maxlength="8" type="text" class="form-control" id="city_id" name="mona_for_supporter" [(ngModel)]="mona_for_supporter" #mona_for_supporter_="ngModel" placeholder="10">
      </div>
      <span translate *ngIf="mona_for_supporter_.errors?.required">必須項目となっております</span>
      <h5 translate>※支援者のみを対象とした特典などを付与する場合の基準額になります</h5>
    </div>									

    <div class="form-group"> <!-- Monacoin for 1Step -->
      <div class="input-group-prepend">
      <span translate class="input-group-text" id="basic-textarea">公開段階を１上げるために必要なMONA</span>
      <input required maxlength="8" type="text" class="form-control" id="city_id" name="mona_for_1step" [(ngModel)]="mona_for_1step" #mona_for_1step_="ngModel" placeholder="10">
      </div>
      <span translate *ngIf="mona_for_1step_.errors?.required">必須項目となっております。</span>
      <h5><span translate>目標達成時の獲得MONA</span>: {{1 * mona_for_1step * 12}}</h5>
      <h5 translate> ※公開段階が１つあがると、ブラー（ぼかし）が一段階解除されます、全１２段階</h5>
      <h5 translate> ※ファンディング方式/段階的ファンディング方式の場合に使用されます</h5>
    </div>									
                
    <div class="form-group"> <!-- State Button -->
      <div class="input-group-prepend">
      <span translate class="input-group-text" id="basic-textarea">他サイト/メディアでの公開可能性</span>
      <select class="form-control" id="state_id" name="media_publicity" [(ngModel)]="media_publicity">
        <option translate id="他サイトの転載、または同時公開" value="immediately">他サイトの転載、または同時公開</option>
        <option translate id="2日～3日後に公開する可能性あり" value="a-few-days">2日～3日後に公開する可能性あり</option>
        <option translate id="1週間後に公開する可能性あり" value="1-week">1週間後に公開する可能性あり</option>
        <option translate id="2週間後に公開する可能性あり" value="2-week">2週間後に公開する可能性あり</option>
        <option translate id="1ヶ月後に公開する可能性あり" value="1-month">1ヶ月後に公開する可能性あり</option>
        <option translate id="2ヶ月後に公開する可能性あり" value="2-month">2ヶ月後に公開する可能性あり</option>
        <option translate id="3ヶ月後に公開する可能性あり" value="3-month">3ヶ月後に公開する可能性あり</option>
        <option translate id="6ヶ月後に公開する可能性あり" value="6-month">6ヶ月後に公開する可能性あり</option>
        <option translate id="1年後に公開する可能性あり" value="12-month">1年後に公開する可能性あり</option>
        <option translate id="2年後に公開する可能性あり" value="24-month">2年後に公開する可能性あり</option>
        <option translate id="3年後に公開する可能性あり" value="36-month">3年後に公開する可能性あり</option>
        <option translate id="無料公開しないことを保証" value="never-free" selected="selected">無料公開しないことを保証</option>
        <option translate id="一切の公開しないことを保証" value="never">一切の公開しないことを保証</option>
      </select>					
      </div>
    <h5 translate>※コンテンツが投稿されてからの経過時間</h5>
    </div>

    <div class="form-group"> <!-- State Button -->
      <div class="input-group-prepend">
      <span translate class="input-group-text" id="basic-textarea">画像分類（レーティング)</span>
      <select class="form-control" id="image_rating_id" name="image_rating" [(ngModel)]="image_rating">
        <option translate value="art" selected="selected">一般アート作品 (完全に一般)</option>
        <option translate value="moe">美少女を主題とした作品</option>
        <option translate value="moe-ero">美少女を主題とした作品 (修正の必要がない程度の裸体表現あり)</option>
        <option translate value="moe-r18">美少女を主題とした作品 (R-18)</option>
        <option translate value="pic">女性が主題の写真</option>
        <option translate value="pic-ero">女性が主題の写真（着エロ程度まで）</option>
        <option translate value="pic-r18">女性が主題の写真 (R-18) </option>
      </select>					
      </div>
    <h5 translate>※コンテンツが投稿されてからの経過時間</h5>
    </div>

    <div class="form-group"> <!-- State Button -->
      <div class="input-group-prepend">
      <span translate class="input-group-text" id="basic-textarea">モナコイン保有者のみに公開</span>
      <select class="form-control" id="image_rating_id" name="coiner_only" [(ngModel)]="coiner_only">
        <option translate value="r0"> R0 (全員に公開)</option>
        <option translate value="r1"> R1 (0.01 MONA 以上保有)</option>
        <option translate value="r2"> R2 (0.1 MONA 以上保有)</option>
        <option translate value="r3"> R3 (1 MONA 以上保有)</option>
        <option translate value="r4"> R4 (10 MONA 以上保有)</option>
        <option translate value="r5"> R5 (100 MONA 以上保有)</option>
        <option translate value="r6"> R6 (1000 MONA 以上保有)</option>
        <option translate value="r7"> R7 (10000 MONA 以上保有)</option>
        <option translate value="r8"> R8 (100000 MONA 以上保有)</option>
        <option translate value="r9"> R9 (1000000 MONA 以上保有)</option>
      </select>					
      </div>
    <h5 translate>※コンテンツが投稿されてからの経過時間</h5>
    </div>

    <hr class="featurette-divider">
    <h3 translate>画像登録</h3>

    <div class="form-group"> <!-- Full Name -->
      <label for="first_image" class="control-label"><span translate>アップロードする画像を選択してください</span> ({{selectedFile.name}}) →→→</label>
      <input 
      id="first_image"
      type="file" (change)="onFileChanged($event)"
      style="display:none"
      #fileInput>
      <button (click)="fileInput.click()" type="button" class = "btn-primary">Select File</button>
    </div>		

    <div class="form-group"> <!-- Submit Button -->
      <button (click)="onUpload()" [disabled]="album_form_.invalid" type="button" class = "btn-primary">Upload!!</button>
      <h5>{{validation_result}}</h5>
    </div>     

    <hr class="featurette-divider">
    <hr class="featurette-divider">
    <h3 translate>以下は、オプションです。お好みでどうぞ</h3>
    <hr class="featurette-divider">
    <hr class="featurette-divider">
    <h3 translate>コメント</h3>
    <div class="form-group"> <!-- Comment 1 -->
      <label translate for="street1_id" class="control-label">コメント</label>
      <input maxlength="30" type="text" class="form-control" id="street1_id" name="comment" [(ngModel)]="comment" placeholder="Any Comment">
    </div>					
                
    <div class="form-group"> <!-- Comment 2 -->
      <label translate for="street2_id" class="control-label">コメント(詳細)</label>
      <textarea class="form-control" maxlength="100" id="street2_id" rows="3" name="sub_comment" [(ngModel)]="sub_comment"></textarea>
    </div>	
　
    <hr class="featurette-divider">
    <h3 translate>追加ライセンス付与</h3>
    <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" name="permission_personal_copy" id="defaultCheck1" [(ngModel)]="permission_personal_copy">
        <label translate class="form-check-label" for="defaultCheck1">
          支援者に私的利用や非商業の個人活動のために物理コピー（ポスター印刷や、グッズ作成）作成（および物理コピー作成業者への委託）を許可する.
        </label>
    </div>
    <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" name="permission_supporter" id="defaultCheck1" [(ngModel)]="permission_supporter">
        <label translate class="form-check-label" for="defaultCheck1">
          支援者が対象画像をそのまま（解像度を落とさず)引用して、支援者であることを電子媒体/物理媒体で表明することを許可する）.
        </label>
    </div>
    <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" name="permission_comercial_use" id="defaultCheck1" [(ngModel)]="permission_comercial_use">
        <label translate class="form-check-label" for="defaultCheck1">
          支援者は個人使用および商業利用を制限なしで可能. 
        </label>
    </div>

    <hr class="featurette-divider">
    <h3 translate>禁止事項</h3>
    <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
        <label translate class="form-check-label" for="defaultCheck1">
          準備中. 
        </label>
    </div>
    <h3 translate>追加オプション</h3>
    
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" name="extention_keep_out" id="defaultCheck1" [(ngModel)]="extention_keep_out">
      <label translate class="form-check-label" for="defaultCheck1">
      画像をぼかす(ブラーする）のではなくKEEP_OUT画像で段階的に隠す.
      </label>
    </div>

  </form>
  <hr class="featurette-divider">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/monafunding']">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Register</li>
    </ol>
  </nav>     
  <footer>
      <p>&copy; 2018 Monacity Project.</p>
  </footer>
  </div>
  <div class="col-md-1">
  </div>
  </div>
  </div>
