import { Component, OnInit } from '@angular/core';

import { v4 as uuid } from 'uuid';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core'

import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';
import { Title }     from '@angular/platform-browser';

class Tweetinfo {
  tweet_id: string;
  screen_name: string;
  media_url: string;
  date: string;
}

@Component({
  selector: 'app-greatpainters',
  templateUrl: './greatpainters.component.html',
  styleUrls: ['./greatpainters.component.css']
})

export class GreatpaintersComponent implements OnInit {
  tweetinfos : Array<Tweetinfo>;
  
  constructor(private translate: TranslateService, private cookieService : CookieService, private http: HttpClient, private router: Router, private title: Title) {
  }

  ngOnInit() {
  }

  btnAuthenticateTwitter = function () {
    this.uu_session_id = uuid();
    console.log(this.cookieService.get('uu_session_id'));
    console.log(this.uu_session_id);
    this.cookieService.set('uu_session_id', this.uu_session_id, 1, '/');
    console.log(this.cookieService.get('uu_session_id'));
  
    const uploadData = new FormData();
    uploadData.append('uu_session_id', this.uu_session_id);
  
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');
  
    this.authenticate_error = "";
    this.http.post('https://back.monafunding.com/formd/uusessionid', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
           console.log("POST Request is successful ");
           location.href = 'https://back.monafunding.com/auth/twitter?uu_session_id=' + this.uu_session_id;
          },
        err => {
          this.authenticate_error = "Authenticate Server is busy... Would you please try after a few minutes";
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);
        }
    )
  };

  btnQueryTwitter = function () {
    this.uu_session_id = this.cookieService.get('uu_session_id');
    console.log(this.uu_session_id);

    const uploadData = new FormData();
    uploadData.append('uu_session_id', this.uu_session_id);
  
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');
  
    this.authenticate_error = "";
    this.http.post('https://back.monafunding.com/formd/twitter_api', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
           console.log(res);
           this.tweetinfos = res;
           console.log("POST Request is successful ");
          },
        err => {
          this.authenticate_error = "Authenticate Server is busy... Would you please try after a few minutes";
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);
        }
    )
  };

}

