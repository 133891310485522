<div class="container toptitl">

  <hr class="featurette-divider">
  <h3 translate>モナコインの広がりへ旅をしよう。</h3>
  <h4 translate>(2018年 11月 Updated. Japanease Only. Now Translating)</h4>
  <hr class="featurette-divider">
      
  
  <div class="row">
  <div class="col-md-1">
  </div>
  <div class="col-md-10">
  <form name="album_form" #album_form_="ngForm">
      <h3 translate>モナオーシャン</h3>
      <pre>
      <span translate>モナコインの大海原へ旅をしよう</span>
      <span translate>モナコイン界隈は大きな広がりを持っていますが、少し奥に入ろうとすると迷ってしまいます。</span>
      <span translate>(色々な情報がたくさんヒットするのですが、仮想通貨としては歴史が長いので古い情報も色々出てきてしまいます)</span>
      <span translate>そんなモナコイナー達への１つの指針になれたらと考えます。</span>
      <span translate>さぁ、モナコインの大海原へ旅立とう！！！</span>
      </pre>
                  
      <h3 translate>モナコイン基礎</h3>
      <pre>
      <span translate>モナコインはビットコインなどに代表される仮想通貨（暗号通貨）の１つです。</span>
      <span translate>2ちゃんねるでWatanabe氏により開発されました。</span>
      <span translate>ただし、仮想通貨なので管理者はおらず、誰でもMonacoinネットワークに参加することができます</span>

      <span translate>国産仮想通貨として日本人開発者が関与でき、日本人の数多くのモナコイン応援者（モナコイナー）が活動中です</span>
      <span translate>(モナコイン盗難対策のジャッジノード機能追加や、盗難アドレスのブロック機能など、問題発生から対策までが非常に速い印象です)</span>
      <span translate>(仮想通貨なのでマイナー次第ではありますが、母体が国際的な活動をしている場合は、なかなか日本の事情を優先してもらえないと思います)</span>

      <span translate>モナコインは独自コインですが、ビットコイン(BTC) >> ライトコイン(LTC) >> モナコイン(MONA)のコアプログラムを引き継いでおり、</span>
      <span translate>API設計が共通化されているため、ビットコインの進歩とともに成長していけるのも強みです</span>

      <span translate>(他の独自コインだと、価値の低下により開発者が離れてしまうと、コアプログアムおよび関連サービスを維持できない可能性がある)</span>
      <span translate>(モナファンディングを開発して感じましたが、基本的にbitcoinの説明サイトの内容をほぼそのまま使えるのはありがたいです)</span>

      </pre>
      <h5>基本情報サイト</h5>
      <div class="album py-5 bg-light">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border?https://ja.wikipedia.org/wiki/Monacoin" alt="WikiPedia(Monacoin)">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://ja.wikipedia.org/wiki/Monacoin">WikiPedia(Monacoin)</a></p>
                  <p translate class="card-text">Monacoinのウィキペディアです、ウィキペディアのため正確性、客観性をある程度監視されているので、確かな情報源として利用できます</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border?https://monacoin.org/" alt="モナコインプロジェクト公式">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://monacoin.org/">モナコインプロジェクト公式</a></p>
                  <p translate class="card-text">モナコインプロジェクトの公式ページです。公式のモナコインウォレット(Monacoin core)が配布されていますが、一般の方には通常は不要です。"もにゃ"や"electrum-mona"が推奨です</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border?https://mona-coin.com/" alt="Monacoin Charts">
                <div class="card-body">
                    <p class="card-text"><a translate href="https://mona-coin.com/">Monacoin Charts</a></p>
                    <p translate class="card-text">現在のモナコイン価格および取引量が確認できます</p>
                  </div>
              </div>
            </div>
         </div>
        </div>
      </div>

      
      <hr class="featurette-divider">
      <h3 translate>ウォレットについて</h3>
      <pre>
      <span translate>ウォレットはWebページでモナコインを預かってもらうオンラインウォレットと、</span>
      <span translate>PCやスマートフォンで自己管理するウォレットアプリケーションがあります</span>

　    <span translate>すぐに使う資産はオンラインウォレットに入れておいて、</span>
      <span translate>貯蓄する資産はウォレットアプリケーションで管理するのが一般的です</span>

      <span translate>モナコインおよびビットコインの口座の核となるのは"秘密鍵"と呼ばれるマスターキーのような文字列です</span>
      <span translate>この文字列を忘れてしまったり、他の人に見られたり（盗まれたり）しない限りは、ブロックチェーン上であなたの資産は安全に守られます</span>
      <span translate>仮想通貨の仕組みは複雑に見えますが、基本的な部分は、秘密鍵さえ覚えておけばブロックチェーン上で安全に資産が守られるという簡単なしくみです</span>

      <span translate>(モナコインアドレスや公開鍵はなくしてしまっても、異なるサービス間であっても秘密鍵から再構成可能です)</span>
      <span translate>(ただし、秘密鍵からウォレット作成をサポートしているアプリを使う必要があります)</span>
      <span translate>(ウォレットアプリでは秘密鍵はユーザーに見せないようになっている場合もあります)</span>
      <span translate>(上記の場合はアプリに端末などが壊れた場合の復旧方法がありますので、必ず確認してください)</span>
      </pre>

      <div class="album py-5 bg-light">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="/assets/bloom-blossom-close-up-center.jpg" alt="もにゃ">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://monya-wallet.github.io/">もにゃ</a></p>
                  <p translate class="card-text">一般ユーザーに必要な機能がすべて便利にまとめられています。通常のウォレット管理としておすすめです。</p>
                </div>
              </div>
            </div>  
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border?https://electrum-mona.org/" alt="Electrum Mona">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://electrum-mona.org/">Electrum Mona</a></p>
                  <p translate class="card-text">１つのアカウントに複数のMonaコインアドレスを紐づけるなど、モナコインの機能をフルに利用できます。充実した機能が扱いやすくまとまっています、サイト管理者やコミッションオーナー向けです</p>
                </div>
              </div>
            </div>
    
         </div>
        </div>
      </div>

      <hr class="featurette-divider">
      <h3>モナコインの入手方法</h3>
      <pre>
      [通常の手順]
      現在、モナコインの取引はほとんどBitbankで行われています。
      
      [どうしても口座開設はしたくない]
      どうしても本人確認を待てない場合は、AvacusでBTCを手に入れて、
      CriptoBridgeでMONAに交換可能です。
      
      AvacusはAmazonの商品を買ってあげる代わりに、BTCを貰うサイトなので、
      商品によっては到着までのラグはあります。
      
      CriptoBridgeは問題なく使えますが、普通の取引所に比べても、
      使い方が難しいですので根気が必要です。
      
      Avacus (JPY => BTC)
      
      CriptoBridge (BTC -> MONA)
      </pre>

      <div class="album py-5 bg-light">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border?https://bitbank.cc/" alt="ビットバンク">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://bitbank.cc/">ビットバンク</a></p>
                  <p translate class="card-text">モナコインを扱う最大の取引所です。コールドウォレットおよびマルチシグなどセキュリティは強化されています</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="/assets/bloom-blossom-close-up-center.jpg" alt="アバカス">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://avacus.io/">アバカス</a></p>
                  <p translate class="card-text">モナコイン対応サービスではありませんが、報酬としてビットコインを受け取ることができる希少なサービスですので掲載します</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=5?https://crypto-bridge.org/" alt="クリプトブリッジ">
                <div class="card-body">
                    <p class="card-text"><a translate href="https://crypto-bridge.org/">クリプトブリッジ</a></p>
                    <p translate class="card-text">ビットコインからモナコインに交換できる分散取引所(DEX)です</p>
                    <p translate class="card-text">分散取引所のためユーザー同士の交換扱いになり、本人確認不要です</p>
                    <p translate class="card-text">ただし、サイトの作りがPCの操作に慣れている方でないと少し難しいと思います</p>
                  </div>
              </div>
            </div>
         </div>
        </div>
      </div>
    
      <hr class="featurette-divider">
      <h3>モナコインの使い道</h3>
      <pre>
      モナコインはコミュニティー内で流通させるのがおすすめです(他の物は日本円や他のコインでも買えるので)
      一般的な通貨の使い方としてはビットコインモールで買い物をするなどができます。
      (もちろん取引所でビットコインや日本円に交換もできます)
      </pre>

      <h5>投げ銭</h5>
      <div class="album py-5 bg-light">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=5?https://twitter.com/tipmona" alt="チップモナ">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://twitter.com/tipmona">チップモナ</a></p>
                  <p translate class="card-text">twitterの好きな相手にチップを送れる、twitterボットです</p>
                  <p translate class="card-text">相手がモナコインアドレスを持っていなくても、チップモナが預かってくれます</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=5?https://twitcasting.tv/" alt="モナコインチップサービス(tipmona)連携">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://twitcasting.tv/">ツイキャス</a></p>
                  <p translate class="card-text">お気に入りの放送主にモナコインを投げ銭することができます</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=5?https://askmona.org/" alt="AskMona">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://askmona.org/">AskMona</a></p>
                  <p translate class="card-text">モナコインを投げ銭しあえる掲示板サイトです</p>
                  <p translate class="card-text">モナコイン関連の重要なプロジェクトがはじまったりと、見逃せないサイトです</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h5>モナコインでしか買えないもの(モナコイン名物)</h5>
      <div class="album py-5 bg-light">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=5?https://twitter.com/mona_intheworld" alt="モナ米">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://twitter.com/mona_intheworld">モナ米</a></p>
                  <p translate class="card-text">モナコインで買えるお米のモナ米です。現在monappy閉鎖中のためtwitterにリンクしています</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="/assets/bloom-blossom-close-up-center.jpg" alt="モナコインチップサービス(tipmona)連携">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://tomoeyasyoji.com/monabungu-woo/product-tag/stafy/">モナ茶</a></p>
                  <p translate class="card-text">現在はモナ文具さんでモナ茶の販売をされています</p>
                </div>
              </div>
            </div>
    
          </div>
        </div>
      </div>
      
      <h5>一般的なショッピングサイト</h5>
      <div class="album py-5 bg-light">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=5?https://bitcoinmall.jp/" alt="ビットコインモール">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://bitcoinmall.jp/">ビットコインモール</a></p>
                  <p translate class="card-text">モナコインが使える通販サイトです。スマートフォンやパソコン関連(GPU、ディスプレイなど)が充実しています</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=5?https://www.yutapro.net/" alt="BTOパソコン・PCパーツ通販のお店、ゆたぷろ さん">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://www.yutapro.net/">BTOパソコン・PCパーツ通販のお店、ゆたぷろ さん</a></p>
                  <p translate class="card-text">モナコインが使えるPCパーツのお店です</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=5?https://www.ark-pc.co.jp/" alt="パソコンショップ Ark">
                <div class="card-body">
                    <p class="card-text"><a translate href="https://www.ark-pc.co.jp/">パソコンショップ Ark</a></p>
                    <p translate class="card-text">モナコインが使えるパソコンショップArkさんです。元々パソコンショップとして大手なので安心です</p>
                  </div>
              </div>
            </div>

            <div class="col-md-4">
                <div class="card mb-4 box-shadow">
                  <img class="card-img-top" src="/assets/bloom-blossom-close-up-center.jpg" alt="モナ文具">
                  <div class="card-body">
                      <p class="card-text"><a translate href="https://tomoeyasyoji.com/monabungu-woo/">モナ文具</a></p>
                      <p translate class="card-text">登録したら商品の出品も可能なモナコイン用のオンラインモールです</p>
                      <p translate class="card-text">出品できるタイプのお店は現状貴重ですので、出品を考えている方にはおすすめです</p>
                      <p translate class="card-text">どこまで許可されるのはわかりませんが、文具以外も取り扱っておられます</p>
                    </div>
                </div>
            </div>
  
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=5?https://bitdemoney.jp/" alt="Bitでマネー">
                <div class="card-body">
                    <p class="card-text"><a translate href="https://bitdemoney.jp/">Bitでマネー</a></p>
                    <p translate class="card-text">モナコインでamazonギフト券や各種電子マネーが買えます</p>
                  </div>
              </div>
            </div>
            
         </div>
        </div>
      </div>

      <hr class="featurette-divider">
      <h3>モナコインコミュニティー</h3>
      <pre>
      モナコインコミュニティーはmonappyが中心になってくださっていたのですが。モナコイン盗難があり現在は見直し中です。
      ただ、他にも活動の場はありますので復活を待ちましょう。

      コミュニティーを散策していると、モナカードもたまに配られているので、モナコインアドレスを準備しておくとよいです。
      アドレス自体は通常のモナコインアドレスでよいですが、
      受け取ったカードを見るには対応しているウォレット("もにゃ"や"モナカード公式"など)が必要です。
      </pre>

      <h5>コミュニティーハブ</h5>
      <div class="album py-5 bg-light">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="/assets/bloom-blossom-close-up-center.jpg" alt="モナッピー">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://monappy.jp/">Monappy(休止中)</a></p>
                  <p translate class="card-text">モナコインのウォレットおよびコミュニケーションサイトです</p>
                  <p translate class="card-text">投稿画像への投げ銭が非常に活発で、フリマ機能でも色々な商品が取引されています</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=5?https://monazon.jp/" alt="モナゾン">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://monazon.jp/">モナゾン</a></p>
                  <p translate class="card-text">モナコイン関連サービスのディレクトリサービスです。Webサービスだけでなく実店舗も登録されています</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="/assets/bloom-blossom-close-up-center.jpg" alt="twitterでもたくさんの先輩モナコインナー様たちが活動中です">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://twitter.com/search?q=%23monacoin%20%23%E3%83%A2%E3%83%8A%E3%82%B3%E3%82%A4%E3%83%B3&src=typd">Twitter(#monacoin/#モナコイン)</a></p>
                  <p translate class="card-text">twitterでもたくさんの先輩モナコインナー様たちが活動中です</p>
                  <p translate class="card-text">モナコイン関連のハッシュタグは"#モナコイン"か"#monacoin"です</p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
                <div class="card mb-4 box-shadow">
                  <img class="card-img-top" src="/assets/bloom-blossom-close-up-center.jpg" alt="モナカード">
                  <div class="card-body">
                    <p class="card-text"><a translate href="https://card.mona.jp/">モナカード</a></p>
                    <p translate class="card-text">モナパーティーを利用したモナカードがトレードができるサイトです</p>
                  </div>
                </div>
              </div>
            </div>
      
        </div>
      </div>

      <h5>コミュニティー活動</h5>
      <div class="album py-5 bg-light">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=5?https://n-ista.org/" alt="にすた財団">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://n-ista.org/">にすた財団</a></p>
                  <p translate class="card-text">モナコイン関連でtwitterやブログで精力的に活動されています。</p>
                  <p translate class="card-text">広範囲の記事、独自のWebサービス、モナカードの配布など盛りだくさんな感じが楽しいです</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="/assets/bloom-blossom-close-up-center.jpg" alt="クリプトカレンシーガールズ">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://crypto-currency-girls.com/mona-coin/">クリプトカレンシーガールズ</a></p>
                  <p translate class="card-text">みんな大好きモナコインちゃんがみれます</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=5?https://www.youtube.com/channel/UCZKWkTd86kW0v0T1Si5QvfA/videos" alt="モナコインのVTuber億里モナちゃんです">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://www.youtube.com/channel/UCZKWkTd86kW0v0T1Si5QvfA/videos">億里モナ</a></p>
                  <p translate class="card-text">モナコインのVTuber億里モナちゃんです</p>
                  <p translate class="card-text">仮想通貨暴落からはじまったり、かなり不遇なのですが頑張ってほしいです</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h5>コミュニティー活動(リアル店舗あり)</h5>
      <div class="album py-5 bg-light">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=3?https://monabar.tokyo/" alt="モナバー(MONA BAR TOKYO)">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://monabar.tokyo/">モナバー(MONA BAR TOKYO)</a></p>
                  <p translate class="card-text">モナコインが使えるモナバーさんです。</p>
                  <p translate class="card-text">かなりの数のモナコイナーさんたちも訪れており、活気があります。</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=3?https://twitter.com/ch0meji" alt="移動販売のお弁当屋さんでモナコインが使える">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://twitter.com/ch0meji">移動販売のお弁当屋さんでモナコインが使える</a></p>
                  <p translate class="card-text">移動販売のお弁当屋さんでモナコインが使えます、わからなかったのですが正式な店舗名称はあるのかな</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=3?https://www.facebook.com/bitcafe2017/" alt="ビットカフェ">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://www.facebook.com/bitcafe2017/">ビットカフェ</a></p>
                  <p translate class="card-text">モナコインが使えるビットカフェさんです</p>
                  <p translate class="card-text">大阪にありますので東京は遠いという方はぜひ</p>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>

      <h5>コミュニティー活動(VR world))</h5>
      <div class="album py-5 bg-light">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=3?https://www.vrcworldlist.net/worlds/24212" alt="LoungeClub Mona">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://www.vrcworldlist.net/worlds/24212">LoungeClub Mona</a></p>
                  <p translate class="card-text">モナコイナーのためのラウンジです</p>
                  <p translate class="card-text">twitchの放送やモナコインのチャートがみれたのですが、WebUIが規制されてしまったのでできることは少なくなってしまいました</p>
                  <p translate class="card-text">ですがモナコインナーのVR Chat上の会合などでご利用ください。私は海が見たい時に来ます。</p>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src="https://capture.heartrails.com/400x325/border/delay=3?https://www.vrcworldlist.net/worlds/17952" alt="Cottage Mona">
                <div class="card-body">
                  <p class="card-text"><a translate href="https://www.vrcworldlist.net/worlds/17952">Cottage Mona</a></p>
                  <p translate class="card-text">こちらもモナコイン関係のワールドです。モナコインをばらまくモナーのアバターがいます</p>
                </div>
              </div>
            </div>
    
          </div>
        </div>  
      </div>

      <hr class="featurette-divider">
    </form>
    <hr class="featurette-divider">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Mona Ocean</li>
      </ol>
    </nav>
    <footer>
        <p>※Webサイトの画像は引用のため自動取得/掲載しております</p>
        <p>&copy; 2018 Monacity Project.</p>
    </footer>   
    </div>
    <div class="col-md-1">
    </div>
    </div>
    </div>
  