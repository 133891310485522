<header>
  <div class="collapse bg-dark" id="navbarHeader">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 col-md-7 py-4">
          <h4 class="text-white" translate>モナクリッカー(あんまりクリックしない、αテスト中)</h4>
          <p class="text-white" translate>何もないよ</p>
        </div>
        <div class="col-sm-4 offset-md-1 py-4">
          <h4 class="text-white">Contact</h4>
          <ul class="list-unstyled">
            <li><a href="https://twitter.com/RCloverforest" class="text-white">Follow on Twitter</a></li>
            <li translate>(DMなど特に制限はありません)</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="navbar navbar-dark bg-dark box-shadow">
    <div class="container d-flex justify-content-between">
      <a href="#" class="navbar-brand d-flex align-items-center">
        <strong translate>モナクリッカー(あんまりクリックしない、αテスト中) 要 : PC + Chrome + Mpurse</strong>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </div>
</header>

<main role="main">
  <div class="album py-5 bg-light">
        <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="border" style="padding:10px;">
            <div class="row">
              <div class="col-md-3">
              <div class="border" style="padding:10px;">
              <button type="button" class="btn btn-primary">
                評価額 <span class="badge">{{total_asset_info.total_value}}</span>
              </button>
              <a href="https://monafunding.com/wordpress/モナクリッカー%ef%bc%88クリックはほぼしない、αテス/" target="_blank" rel="noopener noreferrer">　　→ 説明書 ←</a>
              </div>
              </div>

              <div class="col-md-3">
              <div class="border" style="padding:10px;">
              <button type="button" class="btn btn-primary">
                順位 <span class="badge">{{total_asset_info.total_rank}}</span>
              </button>
              <a href="https://monafunding.com/monaclickerranking" target="_blank" rel="noopener noreferrer">　　→→ ランキング ←←</a>
              </div>
              </div>

              <div class="col-md-3">
              <div class="border" style="padding:10px;">
              <button type="button" class="btn btn-primary">
                総採掘量 <span class="badge">{{total_asset_info.total}} ( +{{total_asset_info.total_delta}} )</span>
              </button>
              </div>
              </div>
              <div class="col-md-3">
              <div class="row">
              <div class="col-md-12">
              <input type="nickname" class="form-control" id="exampleFormControlInput1" placeholder="ニックネーム" [(ngModel)]="total_asset_info.nick_name">
              </div>
              <div class="col-md-12">
              集計&リセット(予定時刻)
              </div>
              <div class="col-md-12">
              {{limit_date_string}} ({{limit_hour}} H)
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="border" style="padding:10px;">
            <div class="row">
              <div class="col-md-2">
              <div class="border" style="padding:10px;">
              <button type="button" class="btn btn-primary">
                火 <span class="badge">{{total_asset_info.fire}}(x {{market_info.fire_ratio}})</span>
              </button>
              </div>
              </div>

              <div class="col-md-2">
              <div class="border" style="padding:10px;">
              <button type="button" class="btn btn-primary">
                水 <span class="badge">{{total_asset_info.water}}(x {{market_info.water_ratio}})</span>
              </button>
              </div>
              </div>

              <div class="col-md-2">
              <div class="border" style="padding:10px;">
              <button type="button" class="btn btn-primary">
                光 <span class="badge">{{total_asset_info.light}}(x {{market_info.light_ratio}})</span>
              </button>
              </div>
              </div>


              <div class="col-md-2">
              <div class="border" style="padding:10px;">
              <button type="button" class="btn btn-primary">
                闇 <span class="badge">{{total_asset_info.dark}}(x {{market_info.dark_ratio}})</span>
              </button>
              </div>
              </div>

              <div class="col-md-2">
              <div class="border" style="padding:10px;">
              <button type="button" class="btn btn-primary">
                金 <span class="badge">{{total_asset_info.gold}}(x {{market_info.gold_ratio}})</span>
              </button>
              </div>
              </div>

              <div class="col-md-2">
              <div class="border" style="padding:10px;">
              <button type="button" class="btn btn-primary">
                幻 <span class="badge">{{total_asset_info.fantom}}(x {{market_info.fantom_ratio}})</span>
              </button>
              </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-9">
          <div class="border" style="padding:10px;">
          <div class="row">
            <div class="col-md-4">
            <div class="border" style="padding:10px;">
              <div class="col-md-12">
                <div class="border-bottom" style="padding:10px;">
                <button type="button" class="btn btn-primary">
                  普通の釜　🏺 
                </button>
                </div>
              </div>
              <div class="col-md-12">
                <ng-container *ngIf="selected_card == 1"> 
                <div class="card mb-4 border-primary box-shadow">
                  <img class="card-img-top" src={{stored_cards_image[0]}} alt="モナコインによるクラウドファンディング">
                  <div class="card-body">
                    <p class="card-text">
                      {{stored_cards_info[0].card_identifer}}
                      <br>
                      (x {{stored_cards_nerf[0]}})
                    </p>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="btn-group">
                        <button (click)="btnMonaCardSelect(1)" type="button" class="btn btn-sm btn-outline-secondary">選択</button>
                      </div>
                      <ng-container *ngIf="stored_cards_lucky[0] > 1000"> 
                      <div class="btn-group">
                        <button (click)="btnMonaCardLucky(1)" type="button" class="btn btn-sm btn-outline-secondary">!Lucky!</button>
                      </div>
                      </ng-container> 
                      <small class="text-muted">素材</small>
                    </div>
                  </div>
                </div>
                </ng-container> 
                <ng-container *ngIf="selected_card != 1"> 
                <div class="card mb-4 box-shadow">
                  <img class="card-img-top" src={{stored_cards_image[0]}} alt="モナコインによるクラウドファンディング">
                  <div class="card-body">
                    <p class="card-text">
                      {{stored_cards_info[0].card_identifer}}
                      <br>
                      (x {{stored_cards_nerf[0]}})
                    </p>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="btn-group">
                        <button (click)="btnMonaCardSelect(1)" type="button" class="btn btn-sm btn-outline-secondary">選択</button>
                      </div>
                      <ng-container *ngIf="stored_cards_lucky[0] > 1000"> 
                      <div class="btn-group">
                        <button (click)="btnMonaCardLucky(1)" type="button" class="btn btn-sm btn-outline-secondary">!Lucky!</button>
                      </div>
                      </ng-container> 
                      <small class="text-muted">素材</small>
                    </div>
                  </div>
                </div>
                </ng-container> 
              </div>

              <div class="col-md-12">
                <ng-container *ngIf="selected_card == 2"> 
                <div class="card mb-4 border-primary box-shadow">
                  <img class="card-img-top" src={{stored_cards_image[1]}} alt="モナコインによるクラウドファンディング">
                  <div class="card-body">
                    <p class="card-text">{{stored_cards_info[1].card_identifer}}</p>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="btn-group">
                        <button (click)="btnMonaCardSelect(2)" type="button" class="btn btn-sm btn-outline-secondary">選択</button>
                      </div>
                      <small class="text-muted">触媒</small>
                    </div>
                  </div>
                </div>
                </ng-container> 
                <ng-container *ngIf="selected_card != 2"> 
                <div class="card mb-4 box-shadow">
                  <img class="card-img-top" src={{stored_cards_image[1]}} alt="モナコインによるクラウドファンディング">
                  <div class="card-body">
                    <p class="card-text">{{stored_cards_info[1].card_identifer}}</p>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="btn-group">
                        <button (click)="btnMonaCardSelect(2)" type="button" class="btn btn-sm btn-outline-secondary">選択</button>
                      </div>
                      <small class="text-muted">触媒</small>
                    </div>
                  </div>
                </div>
                </ng-container> 
              </div>

            </div>
            </div>

            <div class="col-md-4">
            <div class="border" style="padding:10px;">
              <div class="col-md-12">
                <div class="border-bottom" style="padding:10px;">
                <button type="button" class="btn btn-primary">
                  普通の釜　🏺 
                </button>
                </div>
              </div>

              <div class="col-md-12">

                <ng-container *ngIf="selected_card == 3"> 
                <div class="card mb-4 border-primary box-shadow">
                  <img class="card-img-top" src={{stored_cards_image[2]}} alt="モナコインによるクラウドファンディング">
                  <div class="card-body">
                    <p class="card-text">
                      {{stored_cards_info[2].card_identifer}}
                      <br>
                      (x {{stored_cards_nerf[1]}})
                    </p>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="btn-group">
                        <button (click)="btnMonaCardSelect(3)" type="button" class="btn btn-sm btn-outline-secondary">選択</button>
                      </div>
                      <ng-container *ngIf="stored_cards_lucky[1] > 1000"> 
                      <div class="btn-group">
                        <button (click)="btnMonaCardLucky(3)" type="button" class="btn btn-sm btn-outline-secondary">!Lucky!</button>
                      </div>
                      </ng-container> 
                      <small class="text-muted">素材</small>
                    </div>
                  </div>
                </div>
                </ng-container> 
                <ng-container *ngIf="selected_card != 3"> 
                <div class="card mb-4 box-shadow">
                  <img class="card-img-top" src={{stored_cards_image[2]}} alt="モナコインによるクラウドファンディング">
                  <div class="card-body">
                    <p class="card-text">
                      {{stored_cards_info[2].card_identifer}}
                      <br>
                      (x {{stored_cards_nerf[1]}})
                    </p>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="btn-group">
                        <button (click)="btnMonaCardSelect(3)" type="button" class="btn btn-sm btn-outline-secondary">選択</button>
                      </div>
                      <ng-container *ngIf="stored_cards_lucky[1] > 1000"> 
                      <div class="btn-group">
                        <button (click)="btnMonaCardLucky(3)" type="button" class="btn btn-sm btn-outline-secondary">!Lucky!</button>
                      </div>
                      </ng-container> 
                      <small class="text-muted">素材</small>
                    </div>
                  </div>
                </div>
                </ng-container> 

              </div>

                <ng-container *ngIf="selected_card == 4"> 
                <div class="card mb-4 border-primary box-shadow">
                  <img class="card-img-top" src={{stored_cards_image[3]}} alt="モナコインによるクラウドファンディング">
                  <div class="card-body">
                    <p class="card-text">{{stored_cards_info[3].card_identifer}}</p>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="btn-group">
                        <button (click)="btnMonaCardSelect(4)" type="button" class="btn btn-sm btn-outline-secondary">選択</button>
                      </div>
                      <small class="text-muted">触媒</small>
                    </div>
                  </div>
                </div>
                </ng-container> 
                <ng-container *ngIf="selected_card != 4"> 
                <div class="card mb-4 box-shadow">
                  <img class="card-img-top" src={{stored_cards_image[3]}} alt="モナコインによるクラウドファンディング">
                  <div class="card-body">
                    <p class="card-text">{{stored_cards_info[3].card_identifer}}</p>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="btn-group">
                        <button (click)="btnMonaCardSelect(4)" type="button" class="btn btn-sm btn-outline-secondary">選択</button>
                      </div>
                      <small class="text-muted">触媒</small>
                    </div>
                  </div>
                </div>
                </ng-container> 
              
            </div>
            </div>

            <div class="col-md-4">
            <div class="border" style="padding:10px;">
              <div class="col-md-12">
                <div class="border-bottom" style="padding:10px;">
                <button type="button" class="btn btn-primary">
                  普通の釜　🏺 
                </button>
                </div>
              </div>

                <ng-container *ngIf="selected_card == 5"> 
                <div class="card mb-4 border-primary box-shadow">
                  <img class="card-img-top" src={{stored_cards_image[4]}} alt="モナコインによるクラウドファンディング">
                  <div class="card-body">
                    <p class="card-text">
                      {{stored_cards_info[4].card_identifer}}
                      <br>
                      (x {{stored_cards_nerf[2]}})
                    </p>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="btn-group">
                        <button (click)="btnMonaCardSelect(5)" type="button" class="btn btn-sm btn-outline-secondary">選択</button>
                      </div>
                      <ng-container *ngIf="stored_cards_lucky[2] > 1000"> 
                      <div class="btn-group">
                        <button (click)="btnMonaCardLucky(5)" type="button" class="btn btn-sm btn-outline-secondary">!Lucky!</button>
                      </div>
                      </ng-container> 
                      <small class="text-muted">素材</small>
                    </div>
                  </div>
                </div>
                </ng-container> 
                <ng-container *ngIf="selected_card != 5"> 
                <div class="card mb-4 box-shadow">
                  <img class="card-img-top" src={{stored_cards_image[4]}} alt="モナコインによるクラウドファンディング">
                  <div class="card-body">
                    <p class="card-text">
                      {{stored_cards_info[4].card_identifer}}
                      <br>
                      (x {{stored_cards_nerf[2]}})
                    </p>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="btn-group">
                        <button (click)="btnMonaCardSelect(5)" type="button" class="btn btn-sm btn-outline-secondary">選択</button>
                      </div>
                      <ng-container *ngIf="stored_cards_lucky[2] > 1000"> 
                      <div class="btn-group">
                        <button (click)="btnMonaCardLucky(5)" type="button" class="btn btn-sm btn-outline-secondary">!Lucky!</button>
                      </div>
                      </ng-container> 
                      <small class="text-muted">素材</small>
                    </div>
                  </div>
                </div>
                </ng-container> 


                <ng-container *ngIf="selected_card == 6"> 
                <div class="card mb-4 border-primary box-shadow">
                  <img class="card-img-top" src={{stored_cards_image[5]}} alt="モナコインによるクラウドファンディング">
                  <div class="card-body">
                    <p class="card-text">
                      {{stored_cards_info[5].card_identifer}}
                    </p>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="btn-group">
                        <button (click)="btnMonaCardSelect(6)" type="button" class="btn btn-sm btn-outline-secondary">選択</button>
                      </div>
                      <small class="text-muted">触媒</small>
                    </div>
                  </div>
                </div>
                </ng-container> 
                <ng-container *ngIf="selected_card != 6"> 
                <div class="card mb-4 box-shadow">
                  <img class="card-img-top" src={{stored_cards_image[5]}} alt="モナコインによるクラウドファンディング">
                  <div class="card-body">
                    <p class="card-text">
                      {{stored_cards_info[5].card_identifer}}
                    </p>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="btn-group">
                        <button (click)="btnMonaCardSelect(6)" type="button" class="btn btn-sm btn-outline-secondary">選択</button>
                      </div>
                      <small class="text-muted">触媒</small>
                    </div>
                  </div>
                </div>
                </ng-container> 

                
            </div>
            </div>


          </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12">
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" src={{selected_cards_image}} alt="モナコインによるクラウドファンディング">
                <div class="card-body">
                  <p class="card-text">カード名称: {{selected_cards_info.card_identifer}}</p>
                  <p class="card-text">火　{{selected_cards_info.fire}}</p>
                  <p class="card-text">水　{{selected_cards_info.water}}</p>
                  <p class="card-text">光　{{selected_cards_info.light}}</p>
                  <p class="card-text">闇　{{selected_cards_info.dark}}</p>
                  <p class="card-text">金　{{selected_cards_info.gold}}</p>
                  <p class="card-text">幻　{{selected_cards_info.fantom}}</p>
                  <p class="card-text">カード設定</p>
                  <ng-container *ngIf="initialized == false">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <button (click)="btnGetAddress()" type="button" class="btn btn-sm btn-success">ここから開始</button>
                    </div>
                  </div>
                  </ng-container>
                  <ng-container *ngIf="initialized == true">
                  <select id="inputState" class="form-control" #mySelect
                  (change)='onOptionsSelected(mySelect.value)'[(ngModel)]="country">
                      <option selected>選択...</option>
                      <ng-container *ngFor="let asset of assets">
                      <option [ngValue]=asset>{{asset}}</option>
                      </ng-container>
                  </select>
                  </ng-container>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <button (click)="btnMonaCardStore()" type="button" class="btn btn-sm btn-outline-secondary">決定</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="card mb-4 box-shadow">
                <div class="card-body">
                  <p class="card-text">あやしい神殿</p>
                  <p class="card-text">(弱体化投票)</p>
                  <select id="inputState" class="form-control" [(ngModel)]="nurf_card_1">
                      <option selected>選択...</option>
                      <ng-container *ngFor="let asset of assets_list">
                      <option [ngValue]=asset>{{asset}}</option>
                      </ng-container>
                  </select>
                  <select id="inputState" class="form-control" [(ngModel)]="nurf_card_2">
                      <option selected>選択...</option>
                      <ng-container *ngFor="let asset of assets_list">
                      <option [ngValue]=asset>{{asset}}</option>
                      </ng-container>
                  </select>
                  <select id="inputState" class="form-control" [(ngModel)]="nurf_card_3">
                      <option selected>選択...</option>
                      <ng-container *ngFor="let asset of assets_list">
                      <option [ngValue]=asset>{{asset}}</option>
                      </ng-container>
                  </select>
                  <p class="card-text">サーバー内で効果が弱体化していきます</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</main>
