import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class MonafundingAlbumInfo {
  album_name : string;
  comment : string;
  sub_comment : string;
  monacoin_address : string;
}

export class MonafundingService {
  public uu_session_id : string;

  constructor() { }
}

