import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core'

import {MonafundingAlbumInfo} from '../monafunding.service';

import { Router } from '@angular/router';
import { Title }     from '@angular/platform-browser'; 

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormMessageModalComponent } from '../form-message-modal/form-message-modal.component';
@Component({
  selector: 'app-monafunding-request',
  templateUrl: './monafunding-request.component.html',
  styleUrls: ['./monafunding-request.component.css']
})

export class MonafundingRequestComponent implements OnInit {
  selectedFile: File;
  albumInfo: MonafundingAlbumInfo;
  
  album_name : string;
  
  // RequestData begin.
  comment : string;
  subject : string;
  format : string;
  due_date_register : string;
  reward : string;
  due_date_reward : string;
  other_comment : string;
  // RequestData end.

  validation_result : string;

  constructor(private modalService: NgbModal, private http: HttpClient, private translate: TranslateService, private cookieService : CookieService, private router: Router, private title: Title) {
    this.title.setTitle('モナファンディング モナコインで(イラスト)クラウドファンディング'); 
    
    console.log('InitLang in Album 1');
    console.log(this.cookieService.get('select_lang'));
    this.translate.use(this.cookieService.get('select_lang'));
    this.translate.setDefaultLang(this.cookieService.get('select_lang'));
   }

  ngOnInit() {
    console.log('InitLang in Album 2');
    console.log(this.cookieService.get('select_lang'));
    this.translate.use(this.cookieService.get('select_lang'));
    this.translate.setDefaultLang(this.cookieService.get('select_lang'));
  }

  onFileChanged(event) {
    console.log("onFileChanged");
    this.selectedFile = event.target.files[0]
    console.log(this.selectedFile.name);
  }

  onChangeChecks(event) {
    console.log("onChangeChecks");
  }

  onUpload() {
    console.log('onUpload');
  
    const uploadData = new FormData();
    // AlbumData begin.
    uploadData.append('subject', this.subject);
    uploadData.append('format', this.format);
    uploadData.append('due_date_register', this.due_date_register);
    uploadData.append('reward', this.reward);
    uploadData.append('due_date_reward', this.due_date_reward);
    uploadData.append('other_comment', this.other_comment);

    // AlbumData end.
    var uu_session_id = this.cookieService.get('uu_session_id');
    console.log(uu_session_id);
    uploadData.append('uu_session_id', uu_session_id);
    uploadData.append('select_lang', this.cookieService.get('select_lang'));

    const headers = new HttpHeaders();
    // headers.append('Content-Type', 'text/plain');
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Content-Type', 'multipart/form-data');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'multipart/form-data');
    this.http.post('https://back.monafunding.com/formd/request', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response:any = res;
          console.log("POST Request is successful ", res);
          this.router.navigate(["/monafunding"]);
        },
        err => {
          console.log("POST Request has a error ");
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);

          const modalRef = this.modalService.open(FormMessageModalComponent);
          modalRef.componentInstance.message = err.error;

          modalRef.result.then((result) => {
            console.log(result);
          }).catch((error) => {
            console.log(error);
          });
        }
    )
  }
}
