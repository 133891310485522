import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-form-message-modal',
  templateUrl: './form-message-modal.component.html',
  styleUrls: ['./form-message-modal.component.css']
})
export class FormMessageModalComponent implements OnInit {
  @Input()id: number;

  public message;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }
}
