import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core'

import { Lightbox } from 'ngx-lightbox';

import { Meta, Title }     from '@angular/platform-browser'; 
import { ActivatedRoute, Router } from '@angular/router';

class User {
  id: string;
  name: string;
  uu_session_id: string;
  profile_message: string;
}

class Users{
  users : Array<User>;
}

class Album {
  album_name: string;
  filename: string;
  id: string;
  src: string;
  thumb : string;
}

class Albums {
  albums : Array<Album>;
}

class AlbumPage {
  albums : Array<Album>;
}

class Portfolio {
  user : User;
  albums : Array<Album>;
  album_pages : Array<AlbumPage>;
  album_page_num : number;
}

@Component({
  selector: 'app-monafunding-top',
  templateUrl: './monafunding-top.component.html',
  styleUrls: ['./monafunding-top.component.css'],
  providers: [ Title ]  // ［1］Titleサービスを登録 
})
export class MonafundingTopComponent implements OnInit {  
  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient, private translate: TranslateService, private cookieService : CookieService, private lightbox: Lightbox, private meta: Meta, private title: Title) 
  { 
    this.portfolios = Array<Portfolio>();

    this.rating_art = true;
    this.rating_moe = true;
    this.rating_moe_ero = false;
    this.rating_moe_r18 = false;
    this.rating_pic = false;
    this.rating_pic_ero = false;
    this.rating_pic_r18 = false;
  }

  select_lang = 'ja';

  rating_art : boolean;
  rating_moe : boolean;
  rating_moe_ero : boolean;
  rating_moe_r18 : boolean;
  rating_pic : boolean;
  rating_pic_ero : boolean;
  rating_pic_r18 : boolean;

  users : Array<User>;
  albums : Array<Album>;
  timeline_albums : Array<Album>
  portfolios : Array<Portfolio>;
  timeline_album_pages : Array<AlbumPage>;
  timeline_album_page_num : number;
  uu_session_id: string;

  onClick(album : Album) {
    console.log(album.filename);

    var index : number;
    for(let index in this.albums) {
       if(album.filename == this.albums[index].filename){
        console.log("Album FOUND", index);
        this.lightbox.open(this.albums, Number(index));
        break;
       }
    }
  }

  onClickTimeline(album : Album) {
    console.log(album.filename);

    var index : number;
    for(let index in this.timeline_albums) {
       if(album.filename == this.timeline_albums[index].filename){
        console.log("Album FOUND", index);
        this.lightbox.open(this.timeline_albums, Number(index));
        break;
       }
    }
  }

  checkFilter() {
    console.log('rating_art' + String(this.rating_art));
    console.log('rating_moe' + String(this.rating_moe));
    console.log('rating_moe_ero' + String(this.rating_moe_ero));
    console.log('rating_moe_r18' + String(this.rating_moe_r18));
    console.log('rating_pic' + String(this.rating_pic));
    console.log('rating_pic_ero' + String(this.rating_pic_ero));
    console.log('rating_pic_r18' + String(this.rating_pic_r18));
  }


  ngOnInit() {
    console.log('Actual URL');
    var paths = this.router.url.split('/');
    console.log(paths);
  
    if(paths.length > 2){
      console.log(paths[2]);
      this.cookieService.set('select_lang', paths[2], 1, '/');
    }
    
    console.log('InitLang ');
    console.log(this.cookieService.get('select_lang'));
    this.select_lang = this.cookieService.get('select_lang');
    this.translate.use(this.cookieService.get('select_lang'));
    
    if('ja' == this.select_lang){
      this.title.setTitle('モナファンディング モナコインによるイラストクラウドファンディング');

      var description = 'モナシティ モナコイン経済圏樹立のためのWebサービス。';
      description += 'ルリエルが運営するモナコイン関連のWebサービスポータルです';
      description += 'モナファンディングではモナコインを利用して、イラストファンディングができます';
      description += 'イラストファンディングとは投稿者が登録した作品（アート/イラスト）に対してモナコインを寄付/支援することにより、アート/イラストが開示されていくWebサービス';
      description += '寄付方式：アート/イラストを公開してモナコインを寄付してもらう方式';
      description += '段階的ファンディング：不明瞭な状態でアート/イラストを投稿して、モナコインを受け取るごとに画像が鮮明になっていく方式';
      description += '通常のファンディング：不明瞭な状態でアート/イラストを投稿して、モナコインが一定の目標額に達すると、アート/イラストが公開される方式';
      description += '他にも短い漫画や小説などでしたら、少しずつ限定公開条件で登録していけると思います';
      description += 'ルリエルと一緒にモナコイン界隈を盛り上げていきましょう';
  
      this.meta.addTag({name: 'description', content: description}, true); 
    }
    else{
      this.title.setTitle('Monacoin Funding. Cloud funding (commission) for art works via Monacoin');     

      var description = 'Monacity Monacoin Web service for establishing the economic zone.';
      description += 'Monacity Monacoin Web service for establishing the economic zone.';
      description += 'Illustration Funding is a web service in which art / illustration is disclosed by donating / supporting monacoin against works (art / illustration) registered by a contributor';
      description += 'Donation method: A method of having art / illustration released and donating Monacoin';
      description += 'Step-by-step funding: A method in which art / illustration is submitted in an unclear state and the image becomes clearer as each monacoin is received';
      description += 'Normal Funding: Posting art / illustration in an unclear state, when artist / illustration is disclosed when monacoin reaches a certain target amount';
      description += 'If you have other short comic books or novels, I think that you can register it on a limited basis on a gradual basis';
  
      this.meta.addTag({name: 'description', content: description}, true); 
    }

    const uploadData = new FormData();

    var uu_session_id = this.cookieService.get('uu_session_id');
    this.uu_session_id = uu_session_id;
    console.log(uu_session_id);
    uploadData.append('uu_session_id', uu_session_id);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append("Authorization", "Basic " + btoa("rurueru:rurueru"));
    const params = new HttpParams().set('Content-Type', 'application/x-www-form-urlencoded');

    this.http.get('https://back.monafunding.com/dbd/userlist', {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response : any = res;
          this.users = response;
    
          console.log("POST Request is successful (user) ", this.users);

          this.updatePortfolio();
        },
        err => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
        }
    )

    uploadData.append('rating_art', String(this.rating_art));
    uploadData.append('rating_moe', String(this.rating_moe));
    uploadData.append('rating_moe_ero', String(this.rating_moe_ero));
    uploadData.append('rating_moe_r18', String(this.rating_moe_r18));
    uploadData.append('rating_pic', String(this.rating_pic));
    uploadData.append('rating_pic_ero', String(this.rating_pic_ero));
    uploadData.append('rating_pic_r18', String(this.rating_pic_r18));

    this.http.post('https://back.monafunding.com/dbd/album', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response : any = res;
          this.albums = response;
    
          console.log("POST Request is successful (album) ", this.albums);

          this.updatePortfolio();
        },
        err => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
        }
    )
    this.http.post('https://back.monafunding.com/dbd/album_timeline', uploadData, {headers, withCredentials: true}).subscribe(
        (res : Response) => {
          let response : any = res;
          this.timeline_albums = response;
    
          console.log("POST Request is successful (album) ", this.timeline_albums);

          this.updatePortfolio();
        },
        err => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
        }
    )
    

  }

  
  updatePortfolio() {
    console.log("process");
    console.log("process2");
    console.log(this.users);

    if(this.users != undefined && this.albums != undefined && this.timeline_albums != undefined){
      this.portfolios = Array<Portfolio>();
      
      for(let user of this.users){
        var portfolio : Portfolio = new Portfolio();
        portfolio.albums = new Array<Album>();
        portfolio.user = user;
        portfolio.album_pages = new Array<AlbumPage>();
        portfolio.album_page_num = 0;

        for(let album of this.albums) {
          album.src = "https://back.monafunding.com/imaged?filename=" + album.filename + "&uu_session_id=" + this.uu_session_id;
          album.thumb = "https://back.monafunding.com/imaged?filename=" + album.filename + "-thumbnail" + "&uu_session_id=" + this.uu_session_id;
          
          if(user.id == album.id){
            portfolio.albums.push(album);

            if((portfolio.albums.length % 5) == 1){
              portfolio.album_page_num += 1;
              var albumPage : AlbumPage = new AlbumPage();
              albumPage.albums = new Array<Album>();
              albumPage.albums.push(album);
              portfolio.album_pages.push(albumPage);

            }
            else{
              portfolio.album_pages[portfolio.album_page_num - 1].albums.push(album);
            }
          }
        }
        // アルバムが1ページ以上ある場合のみ.
        if(portfolio.albums.length > 0){
          this.portfolios.push(portfolio);
        }
      }

      this.timeline_album_pages = new Array<AlbumPage>();
      this.timeline_album_page_num = 0;

      for(let index in this.timeline_albums) {
        var album = this.timeline_albums[index];
        album.src = "https://back.monafunding.com/imaged?filename=" + album.filename + "&uu_session_id=" + this.uu_session_id;
        album.thumb = "https://back.monafunding.com/imaged?filename=" + album.filename + "-thumbnail" + "&uu_session_id=" + this.uu_session_id;

        var index_number : number = Number(index);
        if(0 == (index_number % 3)){
          this.timeline_album_page_num += 1;
          var albumPage : AlbumPage = new AlbumPage();
          albumPage.albums = new Array<Album>();
          albumPage.albums.push(album);
          this.timeline_album_pages.push(albumPage);
        }
        else{
          this.timeline_album_pages[this.timeline_album_page_num - 1].albums.push(album);
        }
        console.log(this.timeline_album_pages);
        console.log(this.timeline_album_page_num);
      }
    }
  }
}

