<div class="container toptitl">

    <hr class="featurette-divider">
    <h3 translate>あなただけのキャンペーンを開始しましょう</h3>
    <hr class="featurette-divider">
        
    
    <div class="row">
    <div class="col-md-1">
    </div>
    <div class="col-md-10">
    <form name="album_form" #album_form_="ngForm">
        <h3 translate>必須情報</h3>
        <div class="form-group" name="formgroup"> <!-- Album Name -->
        <div class="input-group-prepend">
          <span translate class="input-group-text" id="basic-textarea">キャンペーン名称</span>
          <input required minlength="4" maxlength="30" type="text" class="form-control" id="full_name_id" name="album_name" [(ngModel)]="album_name" #album_name_="ngModel" placeholder="Your Art Work Name">
        </div>
        <span translate *ngIf="album_name_.errors?.required" style="color:red;">名前は必須です</span>
        <span translate *ngIf="album_name_.errors?.minlength" style="color:red;">名前は4文字以上で入力してください</span>
        <span translate *ngIf="album_name_.errors?.maxlength" style="color:red;">名前は30文字以内で入力してください</span>
        </div>
      
        <h3 translate>リクエスト</h3>                   
        <div class="form-group"> <!-- Comment 2 -->
          <label translate for="street2_id" class="control-label">コメント</label>
          <textarea class="form-control" maxlength="100" id="street2_id" rows="3" name="comment" [(ngModel)]="comment"></textarea>
        </div>	
        <div class="form-group"> <!-- Comment 2 -->
          <label translate for="street2_id" class="control-label">テーマ</label>
          <textarea class="form-control" maxlength="100" id="street2_id" rows="3" name="subject" [(ngModel)]="subject"></textarea>
        </div>	
        <div class="form-group"> <!-- Comment 2 -->
          <label translate for="street2_id" class="control-label">フォーマット</label>
          <textarea class="form-control" maxlength="100" id="street2_id" rows="3" name="format" [(ngModel)]="format"></textarea>
        </div>	
        <div class="form-group"> <!-- Comment 2 -->
          <label translate for="street2_id" class="control-label">投稿期限</label>
          <textarea class="form-control" maxlength="100" id="street2_id" rows="3" name="due_date_register" [(ngModel)]="due_date_register"></textarea>
        </div>	
        <div class="form-group"> <!-- Comment 2 -->
          <label translate for="street2_id" class="control-label">報酬</label>
          <textarea class="form-control" maxlength="100" id="street2_id" rows="3" name="reward" [(ngModel)]="reward"></textarea>
        </div>	
        <div class="form-group"> <!-- Comment 2 -->
          <label translate for="street2_id" class="control-label">報酬支払期限</label>
          <textarea class="form-control" maxlength="100" id="street2_id" rows="3" name="due_date_reward" [(ngModel)]="due_date_reward"></textarea>
        </div>	
        <div class="form-group"> <!-- Comment 2 -->
          <label translate for="street2_id" class="control-label">その他</label>
          <textarea class="form-control" maxlength="100" id="street2_id" rows="3" name="other_comment" [(ngModel)]="other_comment"></textarea>
        </div>	
        <div class="form-group"> <!-- Submit Button -->
          <button (click)="onUpload()" type="button" class = "btn-primary">Submit!!</button>
        </div>     
      </form>
      <hr class="featurette-divider">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/monafunding']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Register</li>
        </ol>
      </nav>     
      <footer>
          <p>&copy; 2018 Monacity Project.</p>
      </footer>
      </div>
      <div class="col-md-1">
      </div>
      </div>
      </div>
    