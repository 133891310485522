
<div class="container toptitl">
  <hr class="featurette-divider">
  <h3><span translate>アルバム名称</span> : {{current_album.album_name}} </h3>
  <h3><span translate>支援(寄付)方式</span> : <span translate>{{current_album.support_type}}</span></h3>
  <h3>
  <span translate>寄付先アドレス</span> : {{current_album.mona_adder}} 
  <a translate href="https://twitter.com/intent/tweet?ref_src=twsrc%5Etfw&text=%40tipmona%20withdraw {{current_album.mona_for_1step}} {{current_album.mona_adder}}" class="btn btn-md btn-primary" data-related="" data-show-count="false">@tipmonaで寄付</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  </h3>
  <ng-container *ngIf="current_album.extention_donate_to_web_master == true">
  <h6><span translate>※本アドレスは運営者のアドレスです（事前に運営者から投稿者に依頼料が支払われている場合などに該当）</span> </h6>
  </ng-container>
  <h6><span translate>※寄付は安全のため外部ウォレットから行ってください</span> </h6>
  <h6><span translate>※受信モナ数を計算しているので、途中で引き出されても問題ありません</span> </h6>
  <h6><span translate>※↑ただし、おつりも受信モナにカウントされるので引き出す場合は全額引き出してください</span> </h6>
  <h3><span translate>アドレス確認</span> : {{current_album.mona_adder_status}} </h3>
  <h6><span translate>※CONFIRMになっていないアドレスは、正しいMONAコインアドレスでない可能性が高いです</span> </h6>
  <h3><span translate>受付MONA</span> : {{current_album.mona_adder_amount}} MONA</h3>
  <h3><span translate>進行段階</span> : {{progress}} / 12 (<span translate>１段階の解放単価</span> : {{current_album.mona_for_1step}} MONA) </h3>
  <h3>
  <span translate>運営者へ寄付アドレス</span> : {{current_album.mona_adder_for_fee}}
  <a translate href="https://twitter.com/intent/tweet?ref_src=twsrc%5Etfw&text=%40tipmona%20withdraw 1 {{current_album.mona_adder_for_fee}}" class="btn btn-md btn-primary" data-related="" data-show-count="false">@tipmonaで寄付</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  </h3>
  <h3><span translate>進行段階の上限</span> : {{progress_cap}} / ({{current_album.mona_adder_for_fee_amount}} MONA <span translate>受付</span>)</h3>
  <h6><span translate>※段階を上げるには、運営者アドレスへの寄付も必要です</span></h6>
  <h6><span translate>※現在1段階につき、投稿者への必要寄付額の</span>{{current_album.mona_adder_for_fee_rate}}%</h6>
  <hr class="featurette-divider">

  <div class="row">
      <div class="col-md-9">
      <ng-container *ngIf="extention_demo_anime != true">              
      <img src="https://back.monafunding.com/imaged?filename={{album_filename}}&uu_session_id={{uu_session_id}}"  class="rounded img-fluid" alt="Cinque Terre">
      </ng-container>
      <ng-container *ngIf="extention_demo_anime == true">              
      <img src="https://back.monafunding.com/imaged?filename={{album_filename}}&uu_session_id={{uu_session_id}}&extention_release_limit={{extention_release_limit}}"  class="rounded img-fluid" alt="Cinque Terre">
      </ng-container>
      </div>
      <div class="col-md-3">
        <div class="card mb-4 box-shadow">
            <img class="card-img-top" src="https://back.monafunding.com/imaged?filename=USER{{album_id}}&uu_session_id={{uu_session_id}}" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title">{{current_user.name}}</h5>
              <ng-container *ngIf="select_lang == 'ja'">
              <p class="card-text">{{current_user.profile_message}}</p>
              </ng-container>
              <ng-container *ngIf="select_lang == 'en'">
              <p class="card-text">{{current_user.profile_message_en}}</p>
              </ng-container>
              <div class="d-flex justify-content-end align-items-right">
                <!-- <div class="btn-group"> -->
                <!--   <button type="button" class="btn btn-sm btn-outline-secondary">Jump</button> -->
                <!-- </div> -->
                <small class="text-muted">9 mins</small>
              </div>
            </div>
          </div>
      </div>
  </div>

  <hr class="featurette-divider">
  <div class="row">
      <div class="col-md-2">
        <h3 translate>メッセージ</h3>
      </div>
      <div class="col-md-10">
      <ul class="list-unstyled">
      <li class="media">
      <img class="mr-3 img-fluid" width=100 height=110 src="https://back.monafunding.com/imaged?filename=USER{{current_album.id}}&uu_session_id={{uu_session_id}}" alt="Generic placeholder image">
      <div class="media-body">
        <ng-container *ngIf="select_lang == 'ja'">
        <h5 class="mt-0">{{current_album.comment}}</h5>
        {{current_album.sub_comment}}
        </ng-container>
        <ng-container *ngIf="select_lang == 'en'">
        <h5 class="mt-0">{{current_album.comment_en}}</h5>
        {{current_album.sub_comment_en}}
        </ng-container>
      </div>
      </li>
      </ul>
    </div>
    </div>

  <hr class="featurette-divider">
  <div class="row">
    <div class="col-md-2">
      <h3 translate>コメント</h3>
    </div>
    <div class="col-md-10">
    <ul class="list-unstyled">
    <ng-container *ngFor="let message of messages">
    <li class="media">
    <img class="mr-3 img-fluid" width=100 height=110 src="https://back.monafunding.com/imaged?filename=USER{{message.id}}&uu_session_id={{uu_session_id}}" alt="Generic placeholder image">
    <div class="media-body">
      <h5 class="mt-0">{{message.name}}
      <ng-container *ngIf="message.monacoin_adder_rank && message.monacoin_adder_rank >= 0.1">
      <span class="badge badge-pill badge-primary">R{{message.monacoin_adder_rank}}</span>
      </ng-container>
      </h5>
      <ng-container *ngIf="select_lang == 'ja'">
      <h5 class="mt-0">{{message.title}}</h5>
      {{message.message}}
      </ng-container>
      <ng-container *ngIf="select_lang == 'en'">
      <h5 class="mt-0">{{message.title_en}}</h5>
      {{message.message_en}}
      </ng-container>
    </div>
    </li>
    </ng-container>
    </ul>
  </div>
  </div>

  <form>
    <div class="form-group"> <!-- Album Name -->
      <label translate for="full_name_id" class="control-label">件名</label>
      <input type="text" class="form-control" id="full_name_id" name="full_name" [(ngModel)]="title" placeholder="Title">
    </div>
    <div class="form-group">
      <label translate for="exampleFormControlTextarea1">メッセージ</label>
      <textarea class="form-control" maxlength="200" id="exampleFormControlTextarea1" rows="3" name="message" [(ngModel)]="message"></textarea>
    </div>
    <div class="form-group"> <!-- Submit Button -->
      <button (click)="onSubmit()" type="button" class = "btn-primary">Submit !</button>
    </div>	
  </form>	

  <hr class="featurette-divider">
  <h5><span translate>公開範囲(モナ保有数)</span> : {{current_album.coiner_only}} </h5>
  <h5><span translate>レーティング(分類)</span> : {{current_album.image_rating}} </h5>
  <h5><span translate>他メディア（他サイト)への公開予定</span> : {{current_album.media_publicity}} </h5>
  <h3><span translate>支援者認定される単価</span> : {{current_album.mona_for_supporter}} MONA</h3>
  <h5><span translate>[支援者への返礼として]</span> ※{{current_album.mona_for_supporter}} MONA <span translate>以上の支援者</span></h5>

  <ng-container *ngIf="true == current_album.permission_personal_copy">
  <h5><span translate>支援者に私的利用や非商業の個人活動のために物理コピー（ポスター印刷や、グッズ作成）作成（および物理コピー作成業者への委託）を許可する</span></h5>
  </ng-container>
  <ng-container *ngIf="true == current_album.permission_supporter">
  <h5><span translate>支援者が対象画像をそのまま（解像度を落とさず)引用して、支援者であることを電子媒体/物理媒体で表明することを許可する）</span></h5>
  </ng-container>
  <ng-container *ngIf="true == current_album.permission_comercial_use">
  <h5><span translate>支援者は個人使用および商業利用を制限なしで可能.</span></h5>
  </ng-container>
  <hr class="featurette-divider">
  <hr class="featurette-divider">
  <h5><span translate>削除したい場合は、投稿者から [削除のリクエスト] という件名で投稿ください。通常、完全に削除されることはなく、直接ページジャンプは可能なままになります</span></h5>
  <h5><span translate>盗作など違反報告は、[違反報告] という件名で投稿ください。違反報告に反対する場合は[異議あり]という件名で投稿ください </span></h5>
  <h5><span translate>外部サイトで寄付モナコインアドレスを検索すると本当にブロックチェーンに書き込まれているか確認できます</span></h5>
  <h5>https://mona.insight.monaco-ex.org/insight/</h5>

  <hr class="featurette-divider">
  <div class="row">
    <div class="col-md-2">
      <h3 translate>支援履歴</h3>
    </div>
    <div class="col-md-10">
    <ul class="list-unstyled">
    <ng-container *ngFor="let user_donation of users_donation">
    <li class="media">
      <!-- 
    <img class="mr-3 img-fluid" width=100 height=110 src="https://back.monafunding.com/imaged?filename=USER{{user_donation.id}}&uu_session_id={{uu_session_id}}" alt="Generic placeholder image">
    -->
    <div class="media-body">
      <h5 class="mt-0">{{user_donation.name}}
      <ng-container *ngIf="user_donation.monacoin_adder_rank && user_donation.monacoin_adder_rank >= 0.1">
      <span class="badge badge-pill badge-primary">R{{user_donation.monacoin_adder_rank}}</span>
      </ng-container>
      {{user_donation.donation_amount}} MONA
      </h5>
    </div>
    </li>
    </ng-container>
    </ul>
  </div>
  </div>

  <hr class="featurette-divider">
  <div class="form-check">
    <input class="form-check-input" type="checkbox" value="" name="extention_demo_anime" id="defaultCheck1" [(ngModel)]="extention_demo_anime"    (change)="onDemoCheckChanged($event)">
    <label translate class="form-check-label" for="defaultCheck1">
    デモンストレーション用動作させる.
    </label>
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/monafunding']">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Register</li>
    </ol>
  </nav>      
  <footer>
      <p>&copy; 2018 Monacity Project.</p>
  </footer>
  